import { useState } from 'react';
import { HelpPopup } from './HelpPopup';
import { useNavigate } from 'react-router-dom';

const ReportBug = () => {

  const [showCreateAppModal, setShowCreateAppModal] = useState(true);
  const navigate = useNavigate();

  return (
    <div>
      <HelpPopup
        show={showCreateAppModal}
        handleClose={() => {
          setShowCreateAppModal(false);
          navigate(-1);
        }}
      />
    </div>
  );
}

export default ReportBug;
