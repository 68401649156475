import { useEffect, useState } from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToastMessageSuccess, showToastMessageError, showToastMessageWarning } from '../../modules/Toast-Message/TosatMessage';
import CurrencyFormat from 'react-currency-format';
import { CSVLink } from "react-csv";
// import DataTable from 'react-data-table-component';
import Detail from './Detail';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import "./commitmentstyle.css";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Calendar } from 'primereact/calendar';
import "./filterbutton.css"


const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
  const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  return JSON.parse(data);
}

const MyCommitments = () => {

  const [commitData, setCommitData] = useState([]);
  const [editId, setEditId] = useState(null);
  const [updatedQty, setUpdatedQty] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchCommitID, setSearchCommitID] = useState("");
  const [searchDealID, setSearchDealID] = useState("");
  const [searchItemName, setSearchItemName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [searchQuery,setSearchQuery] = useState("");
  const [searchSelect,setSearchSelect] = useState("ITEM_NAME");

  const [showDetailModal, setShowDetailModal] = useState(false);
  const [modalDetailContent, setModalDetailContent] = useState({});

  const API_URL = process.env.REACT_APP_API_URL;
  const GET_COMMIT_URL = `${API_URL}/my-commitments`;
  const UPDATE_COMMIT_URL = `${API_URL}/commit-update`;
  const apiToken = getLocalData().api_token;

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
    const paginatorRight = <Button type="button" icon="pi pi-download" text />;

  const data = commitData;

  const [isOpen, setIsOpen] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [toggleFilter, setToggleFilter] = useState("NONE");
  const [filterOpen, setFilterOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if(isOpen){
      setToggleFilter("NONE");
    }
  };

  const handleButtonClick = () => {
    console.log("floating button clicked");
  };

  const openFilterBox = ()=>{
    setFilterOpen(true);
  }

  const closeFilterBox = ()=>{
    setFilterOpen(false);
  }

  const getCommitments = async () => {
    setLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${apiToken}` }
    };
    await axios.get(GET_COMMIT_URL, config, {
      params: {
        searchCommitID: searchCommitID,
        searchDealID: searchDealID,
        searchItemName: searchItemName,
        startDate: startDate,
        endDate: endDate,
      }
    })
      .then((response) => {
        setCommitData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const updateCommitment = async () => {
    if (editId) {
      if (!updatedQty) {
        setErrorMsg("enter commit quantity");
      } else {
        await axios.put(`${UPDATE_COMMIT_URL}/${editId}`, {
          commited_qty: updatedQty,
        })
          .then((response) => {
            setEditId(null);
            getCommitments();
            showToastMessageSuccess(response.data.message);
            setErrorMsg("");
          })
          .catch((error) => {
            console.error(error);
            if (error.response && error.response.data) {
              console.log(error.response.data);
              const errorResponse = error.response.data;
              if (errorResponse.data) {
                const fieldErrors = errorResponse.message;
                showToastMessageError(fieldErrors);
              }
            } else {
              showToastMessageError('An error occurred.');
            }
            setErrorMsg("");
          });
      }
    }
  };

  const startDateObject = new Date(startDate);
  const endDateObject = new Date(endDate);

  const handleSingleSearch = (value)=>{
    setSearchQuery(value)
    setSearchCommitID(value)
    setSearchDealID(value)
    setSearchItemName(value)
  }

  const handleFilter = () => {
    if (startDate !== "" && endDate !== "") {
      const filteredItems = data.filter(
        (item) =>
          item.commitment_no.toLowerCase().includes(searchCommitID.toLowerCase()) &&
          item.deal_no.toLowerCase().includes(searchDealID.toLowerCase()) &&
          item.deal_name.toLowerCase().includes(searchItemName.toLowerCase()) &&
          new Date(item.date) >= startDateObject && new Date(item.date) <= endDateObject
      );
      return filteredItems;
    } else {
      const filteredItems = data.filter(
        (item) =>
          item.commitment_no?.toLowerCase().includes(searchCommitID.toLowerCase()) ||
          item.deal_no?.toLowerCase().includes(searchDealID.toLowerCase()) ||
          item.deal_name?.toLowerCase().includes(searchItemName.toLowerCase())
      );
      return filteredItems;
    }
  };

  const cancleSearch = () => {
    setSearchCommitID("");
    setSearchDealID("");
    setSearchItemName("");
    setStartDate("");
    setEndDate("");
    setSearchQuery("")
  };

  useEffect(() => {
    getCommitments();
  }, []);


  const DealDetail = ({dealname,dealno,dealimage,dealsku,dealprice,dealstatus,fulfilled}) =>{
    return(
      <>
        <a href="#" >{dealname}</a>
        {showDetailModal && <Detail content={modalDetailContent} onClose={closeModal} />}
      </>
    )
  }

  const openModal = (item) => {
    const content = {
      deal_no:item.deal_no,
      deal_image:item.deal_image,
      deal_name:item.deal_name,
      deal_sku:item.sku,
      deal_price:item.price,
      deal_status:item.status,
      fulfilled:item.fullfill_qty
    }
    setModalDetailContent(content);
    setShowDetailModal(true);
  }

  const closeModal = () => {
    setShowDetailModal(false);
  }

  const statusCondition = (val) => {
    var statusText = "";
    if (val == 0) {
      statusText = "Received";
    } else if (val == 1) {
      statusText = "Pending";
    } else if (val == 2) {
      statusText = "Active";
    } else if (val == 3) {
      statusText = "Expired";
    } else if (val == 4) {
      statusText = "In prpgress";
    } else if (val == 5) {
      statusText = "Return Pending";
    } else if (val == 6) {
      statusText = "Return Active";
    } else if (val == 7) {
      statusText = "Return Completed";
    }
    return statusText;
  };

  

  const csvData = [
    ["Commitment ID", "Deal ID", "Item Description", "Status", "Count",  "FulFilled", "Price", "Commission", "Total", "Created"],
    ...handleFilter().map(({ commitment_no, deal_no,deal_name, status, commited_qty, fullfill_qty, price, commission, total_commision, date }) => [
      commitment_no, 
      deal_no,
      deal_name,
      statusCondition(status),
      commited_qty,
      // on_track,
      fullfill_qty,
      price,
      commission,
      total_commision,
      date
    ]),
  ];


  const truncateString = (str) =>{
    const maxLength = 30;
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + '...';
    } else {
      return str;
    }
  }

  const tableCustomStyles = {
  headCells: {
    style: {
      backgroundColor: '#cae4f9',
    },
  },
}



const tableHeader = () =>{
  if(toggleFilter == "SEARCH"){
    return(
      <div className="pb-2 pt-5">
      <div className='d-flex mb-5'>
          <div className='mx-2 search-filter-wrap'>
            <input
              type='text'
              className='form-control'
              placeholder='Search here...'
              value={searchQuery}
              onChange={(e) => handleSingleSearch(e.target.value)}
            />
          </div>
          {/* <div className='mx-2'>
          <select name="search-select" className='form-control' id="" onChange={(e) => setSearchSelect(e.target.value)}>
            <option value="ITEM_NAME" {...searchSelect == "COMMIT_ID" ? 'selected' : '' }>Item Name</option>
            <option value="COMMIT_ID" {...searchSelect == "COMMIT_ID" ? 'selected' : '' }>Commit ID</option>
            <option value="DEAL_ID" {...searchSelect == "COMMIT_ID" ? 'selected' : '' }>Deal ID</option>
          </select>
          </div> */}
          <div className='mx-2'>
          <button
            onClick={() => cancleSearch()}
            className='btn btn-info'
            disabled={searchCommitID === "" && searchDealID === "" && searchItemName === "" && startDate === "" && endDate === ""}
          >
            <i className="fa-solid fa-xmark fs-3"></i>
          </button>
        </div>
      </div>
      </div>

    )
  }
  if(toggleFilter == "DATE"){
    return(
      <div className="pb-2 pt-5">
      <div className='d-flex mb-5'>
      <div className='mx-2  calendar-filter-wrap'>
           <label className='form-label position-absolute text-gray-600' style={{ marginTop: "-18px" }}>Start Date</label>
          <input
            type='date'
            className='form-control'
            placeholder='DD-MM-YYYY'
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className='mx-2 calendar-filter-wrap'>
           <label className='form-label position-absolute text-gray-600' style={{ marginTop: "-18px" }}>End Date</label>
          <input
            type='date'
            className='form-control'
            placeholder='DD-MM-YYYY'
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
          
        </div>
        <div className='mx-2'>
          <button
            onClick={() => cancleSearch()}
            className='btn btn-infor'
            disabled={searchCommitID === "" && searchDealID === "" && searchItemName === "" && startDate === "" && endDate === ""}
          >
            <i className="fa-solid fa-xmark fs-3"></i>
          </button>
        </div>
      </div>
      </div>
    )
  }
  if(toggleFilter == "EXPORT"){
    return(
    <div className="pb-2 pt-5">
          <div className='d-flex align-items-center mb-5'>
            <h5>Export CSV for Commitments</h5>
          <div className='mx-2'>
          <button className='btn btn-success text-dark'>
            <CSVLink className="downloadbtn" filename="my-commitments.csv" data={csvData} style={{ color: "#fff" }}>
              Export
            </CSVLink>
          </button>
        </div>
        
          </div>
          </div>
    )
  }
  if(toggleFilter == "NONE"){
    return false;
  }
  if(toggleFilter = "ALL"){
  return(
    <div className="pb-2 pt-5">
          <div className='d-flex mb-5'>
              <div className='mx-2'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Search by Commit ID'
                  value={searchCommitID}
                  onChange={(e) => setSearchCommitID(e.target.value)}
                />
              </div>
        <div className='mx-2'>
          <input
            type='text'
            className='form-control'
            placeholder='Search by Deal ID'
            value={searchDealID}
            onChange={(e) => setSearchDealID(e.target.value)}
          />
        </div>
        <div className='mx-2'>
          <input
            type='text'
            className='form-control'
            placeholder='Search by Item name'
            value={searchItemName}
            onChange={(e) => setSearchItemName(e.target.value)}
          />
        </div>
        <div className='mx-2'>
          <label className='form-label position-absolute text-gray-600' style={{ marginTop: "-18px" }}>Start Date</label>
          <input
            type='date'
            className='form-control'
            placeholder='DD-MM-YYYY'
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className='mx-2'>
          <label className='form-label position-absolute text-gray-600' style={{ marginTop: "-18px" }}>End Date</label>
          <input
            type='date'
            className='form-control'
            placeholder='DD-MM-YYYY'
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div className='mx-2'>
          <button
            onClick={() => cancleSearch()}
            className='btn btn-info'
            disabled={searchCommitID === "" && searchDealID === "" && searchItemName === "" && startDate === "" && endDate === ""}
          >
            <i className="fa-solid fa-xmark fs-3"></i>
          </button>
        </div>
        <div>
          <button className='btn btn-success text-dark'>
            <CSVLink className="downloadbtn" filename="my-commitments.csv" data={csvData} style={{ color: "#fff" }}>
              Export
            </CSVLink>
          </button>
        </div>
      </div>
      </div>
  )
}
}


const dealBody = (item) =>{
  return(
    // <DealDetail dealno={item.deal_no} dealname={item.deal_name} 
    //                     dealimage={item.deal_image} dealsku={item.sku} 
    //                     dealstatus={item.status} fulfilled={item.fullfill_qty}
    //   dealprice={<CurrencyFormat
    //     decimalScale={2}
    //     value={parseFloat(item.price).toFixed(2)}
    //     displayType={'text'}
    //     thousandSeparator={true}
    //     prefix={'$'}
    //   />} />
    <>
    <div>
    <OverlayTrigger
      key="top"
      placement="top"
      overlay={<Tooltip id={`tooltip-top`} style={{ maxWidth: '400px' }}>{item.deal_name}</Tooltip>}
    >
      <span className='fs-5 fw-bold'>{truncateString(item.deal_name)}</span>
    </OverlayTrigger>
    
    </div>
    <div className="">
    <span className="fs-7 text-gray-500 me-2">Price :&nbsp;&nbsp; 
    <CurrencyFormat
        decimalScale={2}
        value={parseFloat(item.price).toFixed(2)}
        displayType={'text'}
        thousandSeparator={true}
        prefix={'$'}
      />
      </span>
    <span className="fs-7 text-gray-500 me-2">Comsn :&nbsp;&nbsp;
    <CurrencyFormat
      decimalScale={2}
      value={parseFloat(item.commission).toFixed(2)}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'$'}
    />
    </span>
    <span className="fs-7 text-gray-500">Total :&nbsp;&nbsp;
    <CurrencyFormat
        decimalScale={2}
        value={parseFloat(item.total_commision || item.price + item.commission).toFixed(2)}
        displayType={'text'}
        thousandSeparator={true}
        prefix={'$'}
      />
      </span>
    </div>
    </>

  )
}


const imageBody = (item) => {
  return(
      <div className='card shadow card-rounded py-1 px-3'>
        {/* <img src={'https://erp.makemoneyormiles.com/storage/deals/549418.jpg'} width={75} height={75} className='mt-3 mb-3'  /> */}
        <img src={item.deal_image} width={40} height={40} className='mt-3 mb-3' />
      </div>
  )
};

const actionBody = (item) =>{
  return(
    <>
      <button className='btn btn-secondary btn-sm' onClick={() => openModal(item)}>View Detail</button>
      {showDetailModal && <Detail content={modalDetailContent} onClose={closeModal} />}
    </>
  )
}

const statusBody = (item) =>{

if(item.commited_qty == 0){
  statusText = <span className='badge badge-light-dark fs-8 p-3 fw-bold'>Void</span>
}
else{
if(item.status == "DEAL_EXPIRED"){
    statusText = <span className='badge badge-light-danger fs-8 p-3 fw-bold'>Deal Expired</span>
  }
  else{
    var statusText = "";
    if (item.status == 0) {
      statusText = <span className='badge badge-light-light-success fs-8 p-3 fw-bold success_active'>Received</span>
    } else if (item.status == 1) {
      statusText = <span className='badge badge-light-warning fs-8 p-3 fw-bold'>Pending</span>
    } else if (item.status == 2) {
      statusText = <span className='badge badge-light-info fs-8 p-3 fw-bold'>Active</span>
    } else if (item.status == 3) {
      statusText = <span className='badge badge-light-danger fs-8 p-3 fw-bold'>Expired</span>
    } else if (item.status == 4) {
      statusText = <span className='badge badge-light-warning fs-8 p-3 fw-bold'>In prpgress</span>
    } else if (item.status == 5) {
      statusText = <span className='badge badge-light-warning fs-8 p-3 fw-bold'>Return Pending</span>
    } else if (item.status == 6) {
      statusText = <span className='badge badge-light-info fs-8 p-3 fw-bold'>Return Active</span>
    } else if (item.status == 7) {
      statusText = <span className='badge badge-light-success fs-8 p-3 fw-bold'>Return Completed</span>
    }
  }
}
  return statusText;
}

const priceBody = (item) => {
  return(
    <CurrencyFormat
        decimalScale={2}
        value={parseFloat(item.price).toFixed(2)}
        displayType={'text'}
        thousandSeparator={true}
        prefix={'$'}
      />
  )
};
const commissionBody = (item) => {
  return(
    <CurrencyFormat
      decimalScale={2}
      value={parseFloat(item.commission).toFixed(2)}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'$'}
    />
  )
};
const totalBody = (item) => {
  return(
    <CurrencyFormat
        decimalScale={2}
        value={parseFloat(item.total_commision || item.price + item.commission).toFixed(2)}
        displayType={'text'}
        thousandSeparator={true}
        prefix={'$'}
      />
  )
};

const countBody = (item) =>{
  if(item.status == "DEAL_EXPIRED"){
    return(
      <span>{item.commited_qty}</span>
    )
  }
  else{
    return (
      <>
        {editId !== item.commit_code && (
          <span>
            {item.commited_qty}
            {item.status == 1 && item.editable == 1 &&
              <span
                className='btn btn-icon btn-bg-light text-hover-primary btn-active-color-primary btn-sm me-1'
              >
                <button
                  onClick={() => {
                    setEditId(item.commit_code);
                    setUpdatedQty(item.commited_qty);
                  }}
                  className='btn'
                >
                  <KTIcon iconName='pencil' className='fs-3' />
                </button>
              </span>
            }
            
          </span>
        )}
        {editId == item.commit_code && (
          <div className='mt-3'>
            {errorMsg && <p className='position-absolute text-danger fs-8 fw-semibold' style={{ marginTop: "-12px" }}>{errorMsg}</p>}
            <input
              type='text'
              className='form-control custom-form-control'
              value={updatedQty}
              onChange={(e) => setUpdatedQty(e.target.value.replace(/[^0-9]/g, ''))}
              placeholder='Enter Commit Qty'
            />
            <button
              onClick={updateCommitment}
              className='btn btn-success btn-sm custom-update-btn'
              style={{ marginRight: "10px" }}
            >
              <i className="fa-solid fa-check"></i>
            </button>
            <button
              className='btn btn-danger btn-sm custom-cancle-btn'
              onClick={() => {
                setEditId(null);
                setUpdatedQty(null);
                setErrorMsg("");
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
        )}
      </>
    )
  }
  
}


const columnStyle = {
  paddingLeft:10,
  paddingRight:10,
  paddingTop:10,
  paddingBottom:10,
  marginLeft:10,
  marginRight:10,
  width:'200px'
}
const columnStyleDescription = {
  paddingLeft:10,
  paddingRight:10,
  paddingTop:10,
  paddingBottom:10,
  marginLeft:10,
  marginRight:10,
  width:'400px'
}
const columnStyleImage = {
  paddingLeft:10,
  paddingRight:10,
  paddingTop:10,
  paddingBottom:10,
  marginLeft:10,
  marginRight:10,
  width:'50px'
}

const columnStyleDate = {
  paddingLeft:10,
  paddingRight:10,
  paddingTop:10,
  paddingBottom:10,
  marginLeft:10,
  marginRight:10,
  width:'275px'
}

const filterButton = ()=>{
  return(
    <div className="floating-button-container">
      {isOpen && (
        <div className={`menu-buttons ${isOpen ? 'show' : ''}`}>
          <button className="sub-button" onClick={()=> setToggleFilter("EXPORT")}><i className="fas fa-download"></i></button>
          <button className="sub-button" onClick={()=> setToggleFilter("DATE")}><i className="fas fa-calendar-alt"></i></button>
          <button className="sub-button" onClick={()=> setToggleFilter("SEARCH")}><i className="fas fa-search"></i></button>
        </div>
      )}
      <button
        className="main-button"
        onClick={toggleMenu}
      >
        {isOpen ?
        <i className="fas fa-close"></i>
        :
        <i className="fas fa-sliders-h"></i>
        }
        
        
      </button>
    </div>
  )
}

// const searchFilter = ()=>{
//   return(
// <div className="pb-2 pt-5">
//     <div className='d-flex mb-5'>
//         <div className='mx-2'>
//           <input
//             type='text'
//             className='form-control'
//             placeholder='Search Here...'
//             value={searchCommitID}
//             onChange={(e) => setSearchCommitID(e.target.value)}
//           />
//         </div>
        
  
//         <div className='mx-2'>
//           <button
//             onClick={() => cancleSearch()}
//             className='btn btn-danger'
//             disabled={searchCommitID === "" && searchDealID === "" && searchItemName === "" && startDate === "" && endDate === ""}
//           >
//             <i className="fa-solid fa-xmark fs-3"></i>
//           </button>
//         </div>

//       </div>
//       </div>
//   )
// }

// const dateFilter = () =>{
//   return(
// <div className="pb-2 pt-5">
//     <div className='d-flex mb-5'>
      
//         <div className='mx-2'>
//           <label className='form-label position-absolute text-gray-600' style={{ marginTop: "-18px" }}>Start Date</label>
//           <input
//             type='date'
//             className='form-control'
//             placeholder='DD-MM-YYYY'
//             value={startDate}
//             onChange={(e) => setStartDate(e.target.value)}
//           />
//         </div>
//         <div className='mx-2'>
//           <label className='form-label position-absolute text-gray-600' style={{ marginTop: "-18px" }}>End Date</label>
//           <input
//             type='date'
//             className='form-control'
//             placeholder='DD-MM-YYYY'
//             value={endDate}
//             onChange={(e) => setEndDate(e.target.value)}
//           />
//         </div>
//         <div className='mx-2'>
//           <button
//             onClick={() => cancleSearch()}
//             className='btn btn-infor'
//             disabled={searchCommitID === "" && searchDealID === "" && searchItemName === "" && startDate === "" && endDate === ""}
//           >
//             <i className="fa-solid fa-xmark fs-3"></i>
//           </button>
//         </div>
        
//       </div>
//       </div>
//   )
// }

// const exportFilter = ()=>{
//   return(
//     <div className="pb-2 pt-5">
//     <div className='d-flex mb-5'>
//     <div>
//         <button className='btn btn-success text-dark'>
//           <CSVLink className="downloadbtn" filename="my-commitments.csv" data={csvData} style={{ color: "#fff" }}>
//             Export
//           </CSVLink>
//         </button>
//       </div>
//       </div>
//       </div>
//   )
// }

  return (
    <div className=''>
      <ToastContainer />
      <h4 style={{ marginTop: "-10px" }}>My Commitments</h4>
      
      <div className="card shadow top-rounded">
        {/* <DataTable
          fixedHeader={true}
          fixedHeaderScrollHeight={"750px"}
          customStyles={tableCustomStyles}
          columns={columns}
          data={handleFilter()}
          pagination
        /> */}
        <div className="commitments-table">
        <DataTable value={handleFilter()} removableSort size="large"
              header={isOpen ? tableHeader : ''}
              
                emptyMessage="You have no commitments.." paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} 
                tableStyle={{ minWidth: '60rem' ,alignItems:'center',marginLeft:'auto', fontSize:15}}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        // currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}
                scrollable scrollHeight="90vh" style={{ minWidth: '50rem' }}
                >

            <Column body={imageBody} header="Image" style={columnStyleImage} ></Column>    
            <Column body={dealBody} header="Item Description" style={columnStyleDescription}></Column>

            <Column field="commitment_no" header="Commit ID" sortable style={columnStyle}></Column>
            <Column field="deal_no" header="Deal ID" sortable style={columnStyle} ></Column>
            <Column header="Qty" body={countBody} style={columnStyle} ></Column>
            <Column header="Action" body={actionBody} style={columnStyle} ></Column>
            {/* <Column header="Price" body={priceBody} sortable style={columnStyle} ></Column> */}
            {/* <Column header="Commission" body={commissionBody} sortable style={columnStyle} ></Column> */}
            <Column header="Status" body={statusBody} style={columnStyle} ></Column>
            <Column field="date" header="Created" sortable style={columnStyle} ></Column>
        </DataTable>
        </div>
        
      </div>
      <div style={{zIndex:999}}>
      {filterButton()}
      </div>
    </div>
  );
}

export default MyCommitments;
