/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToastMessageSuccess, showToastMessageError } from '../../../../modules/Toast-Message/TosatMessage';
import axios from 'axios';
import Loader from "react-js-loader";
// import { verify } from 'crypto';

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
    const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    return JSON.parse(data);
};

const Banking = ({ profileData2, getProfileDetails2, loading2 }) => {

    const [editMode, setEditMode] = useState(false);
    const [accountName, setAccountName] = useState("");
    const [bankName, setBankName] = useState("");
    const [accountType, setAccountType] = useState(0);
    const [accountNumber, setAccountNumber] = useState("");
    const [routingNumber, setRoutingNumber] = useState("");
    const [profileData, setProfileData] = useState({});    
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [showOTPInput,setShowOTPInput] = useState(false);
    const [OTPVerified,setOTPVerified] = useState(false);
    const [currentOTP,setCurrentOTP] = useState("");
    const [otp,setOTP] = useState("");
    const [otpErr,setOTPErr] = useState();
    const [errorMessage, setErrorMessage] = useState({
        accountNameErr: '',
        bankNameErr: '',
        accountTypeErr: '',
        accountNumberErr: '',
        routingNumberErr: '',
    });

    const API_URL = process.env.REACT_APP_API_URL;
    const UPDATE_BANKING = `${API_URL}/banking-Detail/update`;
    const SEND_VERIFY_OTP = `${API_URL}/banking-Detail/send-otp`;

    const apiToken = getLocalData().api_token;
    const DETAILS = `${API_URL}/details`;

    const getProfileDetails = async () => {
        const config = {
            headers: { Authorization: `Bearer ${apiToken}` }
        };
        await axios.get(DETAILS, config)
            .then((response) => {
                setProfileData(response.data.data?.bankingDetails);
                setUserData(response.data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };



    const validateForm = async () => {
        setErrorMessage((prev) => ({
            ...prev,
            accountNameErr: '',
            bankNameErr: '',
            accountTypeErr: '',
            accountNumberErr: '',
            routingNumberErr: ''
        }));
        if (!accountName || accountName === "") {
            setErrorMessage((prev) => ({
                ...prev,
                accountNameErr: 'enter account name'
            }));
        } else if (!bankName || bankName === "") {
            setErrorMessage((prev) => ({
                ...prev,
                bankNameErr: 'enter bank name'
            }));
        } else if (!accountType || accountType == 0) {
            setErrorMessage((prev) => ({
                ...prev,
                accountTypeErr: 'select account type'
            }));
        } else if (!accountNumber || accountNumber === "") {
            setErrorMessage((prev) => ({
                ...prev,
                accountNumberErr: 'enter account number'
            }));
        } else if (!routingNumber || routingNumber === "") {
            setErrorMessage((prev) => ({
                ...prev,
                routingNumberErr: 'enter routing number'
            }));
        } else if (routingNumber && routingNumber.length !== 9) {
            setErrorMessage((prev) => ({
                ...prev,
                routingNumberErr: 'enter routing number with 9 digits'
            }));
        } else {
            sendVerficationOTP();
        }
    };

    const sendVerficationOTP = async ()=>{
        setBtnLoading(true)
        const randomCode = Math.floor(100000 + Math.random() * 900000);
        setCurrentOTP(randomCode);
        await axios.post(SEND_VERIFY_OTP,{otp:randomCode})
        .then((response) => {
            setShowOTPInput(true);
            showToastMessageSuccess(response.data.message);
            setBtnLoading(false)
        })
        .catch((error) => {
            console.log(error);
            showToastMessageError(error?.response?.data?.message);
            setBtnLoading(false)
        });
    }

    const VerifyOTPSubmit = () =>{     
        const data = {
            account_name: accountName,
            bank_name: bankName,
            bank_account_type: accountType,
            account_number: accountNumber,
            routing_number: routingNumber
        }

        if(!otp && otp === ""){
            setOTPErr("Please Enter Verfication Code..");
        }
        else{
            if(Number(otp) == currentOTP){
                updateBankingDetails(data);
                console.log("Correct COde");
                setOTPErr("")

            }
            else{
                setOTPErr("Invalid Code..");
            }
        }
    }

    const updateBankingDetails  = async (data)=>{
        setBtnLoading(true);
        await axios.post(UPDATE_BANKING, data)
            .then((response) => {
                setEditMode(false);
                showToastMessageSuccess(response.data.message);
                getProfileDetails();
                setBtnLoading(false);
                setShowOTPInput(false);
                setOTP("");
                setOTPErr("");
            })
            .catch((error) => {
                console.log(error);
                showToastMessageError(error?.response?.data?.message);
                setBtnLoading(false);
            });
    };

    useEffect(() => {
        if (!editMode) {
            setErrorMessage((prev) => ({
                ...prev,
                accountNameErr: '',
                bankNameErr: '',
                accountTypeErr: '',
                accountNumberErr: '',
                routingNumberErr: ''
            }));
            setAccountName(profileData ? profileData.account_name : "");
            setBankName(profileData ? profileData.bank_name : "");
            setAccountType(profileData ? profileData.account_type : 0);
            setAccountNumber(profileData ? profileData.account_number : "");
            setRoutingNumber(profileData ? profileData.routing_number : "");
        }
    }, [editMode]);

    useEffect(() => {
        getProfileDetails();
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    useEffect(() => {
        if (profileData) {
            setAccountName(profileData.account_name);
            setBankName(profileData.bank_name);
            setAccountType(profileData.account_type);
            setAccountNumber(profileData.account_number);
            setRoutingNumber(profileData.routing_number);
        }
    }, [profileData]);

    return (
        <div className="shadow card p-5 m-5">
            <ToastContainer />
            <div className='card-header cursor-pointer'>
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Banking Information</h3>
                </div>
            </div>
            {loading ? (
                <div className="item">
                    <Loader type="spinner-cub" bgColor={"#EA2467"} size={50} />
                </div>
            ) : (
                <div className='card-body pb-0'>

                    {showOTPInput ? (
                        <div>
                            <h4>Verify you Account to Update Banking Details</h4>
                            <span className='text-info mt-5'>Verfication Code is sent to your registered email address</span>
                            <div className="form-group mb-2 mt-4">
                                <label htmlFor="">Enter 6 digit code</label>
                                <input type="text" name="" id="" className="form-control" value={otp} onChange={(e) => setOTP(e.target.value)} />
                                {otpErr && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-4px" }}>{otpErr}</p>}
                            </div>
                        </div>
                    ) : (
                    <>
                    <div className='row mb-7'>
                        <label className='col-3 col-md-3 col-sm-3 fw-bold text-muted'>Account Name</label>
                        <div className='col-lg-9 col-md-6 col-sm-9'>
                            {/*<div className={`${editMode ? "d-none" : "d-block"}`}>
                                <span className='fw-bolder fs-6 text-dark'>{profileData ? profileData.account_name : "---"}</span>
                            </div>*/}
                            <div className="d-block">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Account Name"
                                    value={accountName}
                                    onChange={(e) => setAccountName(e.target.value)}
                                    disabled={!editMode}
                                />
                                {errorMessage.accountNameErr && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-4px" }}>{errorMessage.accountNameErr}</p>}
                            </div>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-3 col-md-3 col-sm-3 fw-bold text-muted'>Bank Name</label>
                        <div className='col-lg-9 col-md-6 col-sm-9'>
                            {/*<div className={`${editMode ? "d-none" : "d-block"}`}>
                                <span className='fw-bolder fs-6 text-dark'>{profileData ? profileData.bank_name : "---"}</span>
                            </div>*/}
                            <div className="d-block">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Bank Name"
                                    value={bankName}
                                    onChange={(e) => setBankName(e.target.value)}
                                    disabled={!editMode}
                                />
                                {errorMessage.bankNameErr && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-4px" }}>{errorMessage.bankNameErr}</p>}
                            </div>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-3 col-md-3 col-sm-3 fw-bold text-muted'>Account Type</label>
                        <div className='col-lg-9 col-md-6 col-sm-9'>
                            {/*<div className={`${editMode ? "d-none" : "d-block"}`}>
                                <span className='fw-bolder fs-6 text-dark'>{profileData ? (profileData.account_type == 1 ? "Personal Checking" : "Person Saving") : "---"}</span>
                            </div>*/}
                            <div className="d-block">
                                <select
                                    className="form-select"
                                    value={accountType}
                                    onChange={(e) => setAccountType(e.target.value)} 
                                    disabled={!editMode}
                                >
                                    <option value={0}>--Select--</option>
                                    <option value={1} selected={(profileData?.account_type === "1") ? "true" : "false"}>{(userData.account_type === 1) ? "Personal" : "Business"} Checking</option>
                                    <option value={2} selected={(profileData?.account_type === "2") ? "true" : "false"}> {(userData.account_type === 1) ? "Personal" : "Business"} Saving</option>
                                </select>
                                {errorMessage.accountTypeErr && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-4px" }}>{errorMessage.accountTypeErr}</p>}
                            </div>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-3 col-md-3 col-sm-3 fw-bold text-muted'>Account Number</label>
                        <div className='col-lg-9 col-md-6 col-sm-9'>
                            {/*<div className={`${editMode ? "d-none" : "d-block"}`}>
                                <span className='fw-bolder fs-6 text-dark'>{profileData ? profileData.account_number : "---"}</span>
                            </div>*/}
                            <div className="d-block">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Account Number"
                                    value={accountNumber}
                                    onChange={(e) => setAccountNumber(e.target.value.replace(/[^0-9]/g, ''))}
                                    disabled={!editMode}
                                />
                                {errorMessage.accountNumberErr && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-4px" }}>{errorMessage.accountNumberErr}</p>}
                            </div>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-3 col-md-3 col-sm-3 fw-bold text-muted'>Routing Number</label>
                        <div className='col-lg-9 col-md-6 col-sm-9'>
                            {/*<div className={`${editMode ? "d-none" : "d-block"}`}>
                                <span className='fw-bolder fs-6 text-dark'>{profileData ? profileData.routing_number : "---"}</span>
                            </div>*/}
                            <div className="d-block">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Routing Number"
                                    value={routingNumber}
                                    onChange={(e) => setRoutingNumber(e.target.value.replace(/[^0-9]/g, ''))}
                                    disabled={!editMode}
                                />
                                {errorMessage.routingNumberErr && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-4px" }}>{errorMessage.routingNumberErr}</p>}
                            </div>
                        </div>
                    </div>
                    </>
                    )}
                    
                </div>
            )}
            <div className='card-footer footer_btn2 d-flex justify-content-start py-6 px-9'>
                {!editMode && (
                    <button
                        type='button'
                        onClick={() => setEditMode(true)}
                    >
                        <i className="fa-solid fa-pen"></i> Edit
                    </button>
                )}
                {editMode && !btnLoading && (
                    <>
                    {showOTPInput ? (
                    <button
                    type='button'
                    onClick={()=> VerifyOTPSubmit()}
                    >
                    <i className="fa-solid fa-send"></i>&nbsp;&nbsp;Verify & Update
                    </button>
                    ) : (
                    <button
                    type='button'
                    onClick={validateForm}
                    >
                    <i className="fa-solid fa-pen"></i>&nbsp;&nbsp;Update
                    </button>
                    )}
                    
                    </>
                )}&nbsp;&nbsp;
                {editMode && btnLoading && (
                    <button
                        type='button'
                    >
                        Wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </button>
                )}&nbsp;&nbsp;
                {editMode && !btnLoading && (
                    <button
                        type='button'
                        onClick={() => {
                            setEditMode(false);
                            setShowOTPInput(false);
                            setOTP("");
                            setOTPErr("");
                            setCurrentOTP("");
                        }}
                    >
                        <i className="fa-solid fa-xmark"></i> Cancel
                    </button>
                )}
            </div>
        </div>
    );
}

export { Banking };
