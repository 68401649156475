import React, { FC, useEffect, useRef, useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Step5 } from '../../wizards/components/steps/Step5';
import { KTIcon } from '../../../../_metronic/helpers';
import { StepperComponent } from '../../../../_metronic/assets/ts/components';
import { Form, Formik, FormikValues, Field } from 'formik';
import { ICreateAccount, inits } from './AccountShema';
import { getUserByToken, register } from '../core/_requests';
import { useAuth } from '../core/Auth';
import axios from 'axios';
import './Registration.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToastMessageSuccess, showToastMessageError } from '../../Toast-Message/TosatMessage';
import { HelpPopup } from './HelpPopup';

type FormValues = {
    accountType: number,
    firstName: string;
    lastName: string;
    email: string;
    cellphone: string;
    einnumber: string;
    dob: string;
    company: string;
    password: string;
    confirmPassword: string;
    address1: string;
    address2: string;
    city: string;
    zipcode: string;
    countryId: number;
    stateId: number;
    nameOnAccount: string;
    bankName: string;
    typeOfAccount: number;
    routingNumber: string;
    accountNumber: string;
    reAccountNumber: string;
    bankAddress: string;
    bankBuildingNumber: string;
    bankCity: string;
    bankStateId: number;
    bankZipcode: string;
    bankPhone: string;
    bankEmail: string;
};

const RegistrationStep: FC = () => {

    const [showCreateAppModal, setShowCreateAppModal] = useState(false);
    const stepperRef = useRef<HTMLDivElement | null>(null);
    const stepper = useRef<StepperComponent | null>(null);
    const firstNameRef = useRef<HTMLInputElement | null>(null);
    const lastNameRef = useRef<HTMLInputElement | null>(null);
    const emailRef = useRef<HTMLInputElement | null>(null);
    const phoneRef = useRef<HTMLInputElement | null>(null);
    const einNumRef = useRef<HTMLInputElement | null>(null);
    const companyRef = useRef<HTMLInputElement | null>(null);
    const dobRef = useRef<HTMLInputElement | null>(null);
    const passwordRef = useRef<HTMLInputElement | null>(null);
    const confPasswordRef = useRef<HTMLInputElement | null>(null);
    const [initValues] = useState<ICreateAccount>(inits);
    const [isSubmitButton, setSubmitButton] = useState(false);
    const [countryData, setCountryData] = useState<{ id: number; name: string; short_name: string; }[]>([]);
    const [stateData, setStateData] = useState<{ id: number; name: string; short_name: string; }[]>([]);
    const [accountType, setAccountType] = useState('personal');
    const [asPermanentCheck, setAsPermanentCheck] = useState(false);
    const [shoHidePass, setShoHidePass] = useState(false);
    const [passInpuType, setPassInpuType] = useState("password");
    const [shoHideConfPass, setShoHideConfPass] = useState(false);
    const [confpassInpuType, setConfPassInpuType] = useState("password");
    const [loading, setLoading] = useState(false);

    const queryParams = new URLSearchParams(window.location.search);
    const URL_Refferal = queryParams.get("ref");
    const refral_no: string = URL_Refferal ?? "";

    const [formValues, setFormValues] = useState<FormValues>({
        accountType: 1,
        firstName: '',
        lastName: '',
        email: '',
        cellphone: '',
        einnumber: '',
        company: '',
        dob: '',
        password: '',
        confirmPassword: '',
        address1: '',
        address2: '',
        city: '',
        zipcode: '',
        countryId: 1,
        stateId: 0,
        nameOnAccount: '',
        bankName: '',
        typeOfAccount: 0,
        routingNumber: '',
        accountNumber: '',
        reAccountNumber: '',
        bankAddress: '',
        bankBuildingNumber: '',
        bankCity: '',
        bankStateId: 0,
        bankZipcode: '',
        bankPhone: '',
        bankEmail: '',
    });

    const [formError, setFormError] = useState({
        firstName: '',
        lastName: '',
        email: '',
        cellphone: '',
        einnumber: '',
        dob: '',
        company: '',
        password: '',
        confirmPassword: '',
        address1: '',
        address2: '',
        city: '',
        zipcode: '',
        countryId: '',
        stateId: '',
        nameOnAccount: '',
        bankName: '',
        typeOfAccount: '',
        routingNumber: '',
        accountNumber: '',
        reAccountNumber: '',
        bankAddress: '',
        bankBuildingNumber: '',
        bankCity: '',
        bankStateId: '',
        bankZipcode: '',
        bankPhone: '',
        bankEmail: '',
    });

    const API_URL = process.env.REACT_APP_API_URL;
    const countryURL = `${API_URL}/country`;
    const stateURL = `${API_URL}/state`;

    const navigate = useNavigate();

    const { saveAuth, setCurrentUser } = useAuth();

    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 21);
    const minDate = currentDate.toISOString().split("T")[0];

    const getCountryList = async () => {
        await axios.get(countryURL)
            .then((response) => {
                setCountryData(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getStateList = async () => {
        await axios.get(stateURL, {
            params: {
                country_id: formValues.countryId
            }
        })
            .then((response) => {
                setStateData(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement);
    };

    const prevStep = () => {
        if (!stepper.current) {
            return;
        }

        stepper.current.goPrev();

        setSubmitButton(stepper.current.currentStepIndex == 5);
    };

    const submitStep = (values: ICreateAccount, actions: FormikValues) => {
        if (!stepper.current) {
            return;
        }
        setFormError((prev) => ({
            ...prev,
            firstName: '',
            lastName: '',
            email: '',
            cellphone: '',
            einnumber: '',
            dob: '',
            company: '',
            password: '',
            confirmPassword: '',
            address1: '',
            address2: '',
            city: '',
            zipcode: '',
            countryId: '',
            stateId: '',
            nameOnAccount: '',
            bankName: '',
            typeOfAccount: '',
            routingNumber: '',
            accountNumber: '',
            reAccountNumber: '',
            bankAddress: '',
            bankBuildingNumber: '',
            bankCity: '',
            bankStateId: '',
            bankZipcode: '',
            bankPhone: '',
            bankEmail: '',
        }));
        if (stepper.current.currentStepIndex === 1) {
            stepper.current.goNext();
        } else if (stepper.current.currentStepIndex === 2) {
            if (formValues.firstName === "") {
                setFormError((prev) => ({
                    ...prev,
                    firstName: 'First Name is required',
                }));
                firstNameRef.current?.focus();
            } else if (formValues.lastName === "") {
                setFormError((prev) => ({
                    ...prev,
                    lastName: 'Last Name is required',
                }));
                lastNameRef.current?.focus();
            } else if (formValues.email === "") {
                setFormError((prev) => ({
                    ...prev,
                    email: 'Email is required',
                }));
                emailRef.current?.focus();
            } else if (formValues.cellphone === '') {
                setFormError((prev) => ({
                    ...prev,
                    cellphone: 'Cell Phone is required',
                }));
                phoneRef.current?.focus();
            } else if (formValues.cellphone.length !== 10) {
                setFormError((prev) => ({
                    ...prev,
                    cellphone: '10 digit required',
                }));
                phoneRef.current?.focus();
            } else if (formValues.einnumber === "" && accountType === "corporate") {
                setFormError((prev) => ({
                    ...prev,
                    einnumber: 'EIN number is required',
                }));
                einNumRef.current?.focus();
            } else if (formValues.einnumber.length !== 9 && accountType === "corporate") {
                setFormError((prev) => ({
                    ...prev,
                    einnumber: 'Length should 9 letters',
                }));
                einNumRef.current?.focus();
            } else if (formValues.company === '' && accountType === "corporate") {
                setFormError((prev) => ({
                    ...prev,
                    company: 'Company is required',
                }));
                companyRef.current?.focus();
            } else if (formValues.dob === '') {
                setFormError((prev) => ({
                    ...prev,
                    dob: 'Date of Birth is required',
                }));
                dobRef.current?.focus();
            } else if (formError.dob) {
                setFormError((prev) => ({
                    ...prev,
                    dob: 'You must be 21 years old or older',
                }));
                dobRef.current?.focus();
            } else if (formValues.password === '') {
                setFormError((prev) => ({
                    ...prev,
                    password: 'Password is required',
                }));
                passwordRef.current?.focus();
            } else if (formValues.confirmPassword === '') {
                setFormError((prev) => ({
                    ...prev,
                    confirmPassword: 'Confirm Password is required',
                }));
                confPasswordRef.current?.focus();
            } else if (formValues.confirmPassword !== formValues.password) {
                setFormError((prev) => ({
                    ...prev,
                    confirmPassword: 'Passwords must be same',
                }));
                confPasswordRef.current?.focus();
            } else {
                stepper.current.goNext();
            }
        } else if (stepper.current.currentStepIndex === 3) {
            if (formValues.address1 === "") {
                setFormError((prev) => ({
                    ...prev,
                    address1: 'Street Address is required',
                }));
            } else if (formValues.city === "") {
                setFormError((prev) => ({
                    ...prev,
                    city: 'City is required',
                }));
            } else if (formValues.zipcode === "") {
                setFormError((prev) => ({
                    ...prev,
                    zipcode: 'Zipcode is required',
                }));
            } else if (formValues.zipcode.length !== 5) {
                setFormError((prev) => ({
                    ...prev,
                    zipcode: 'Enter 5 digit Zipcode',
                }));
            } else if (formValues.countryId === 0) {
                setFormError((prev) => ({
                    ...prev,
                    countryId: 'Country is required',
                }));
            } else if (formValues.stateId === 0) {
                setFormError((prev) => ({
                    ...prev,
                    stateId: 'State is required',
                }));
            } else {
                stepper.current.goNext();
            }
        } else if (stepper.current.currentStepIndex === 4) {
            if (formValues.nameOnAccount === "") {
                setFormError((prev) => ({
                    ...prev,
                    nameOnAccount: 'Name on Account is required',
                }));
            } else if (formValues.bankName === "") {
                setFormError((prev) => ({
                    ...prev,
                    bankName: 'Bank name is required',
                }));
            } else if (formValues.typeOfAccount === 0) {
                setFormError((prev) => ({
                    ...prev,
                    typeOfAccount: 'Account type is required',
                }));
            } else if (formValues.routingNumber === "") {
                setFormError((prev) => ({
                    ...prev,
                    routingNumber: 'Routing number is required',
                }));
            } else if (formValues.routingNumber.length !== 9) {
                setFormError((prev) => ({
                    ...prev,
                    routingNumber: 'Length should be 9 letters',
                }));
            } else if (formValues.accountNumber === "") {
                setFormError((prev) => ({
                    ...prev,
                    accountNumber: 'Account number is required',
                }));
            } else if (formValues.reAccountNumber === "") {
                setFormError((prev) => ({
                    ...prev,
                    reAccountNumber: 'Re Enter Account number',
                }));
            } else if (formValues.reAccountNumber !== formValues.accountNumber) {
                setFormError((prev) => ({
                    ...prev,
                    reAccountNumber: 'Account Number must be match',
                }));
            } else if (formValues.bankAddress === "") {
                setFormError((prev) => ({
                    ...prev,
                    bankAddress: 'Address is required',
                }));
            } else if (formValues.bankCity === "") {
                setFormError((prev) => ({
                    ...prev,
                    bankCity: 'City is required',
                }));
            } else if (formValues.bankStateId === 0) {
                setFormError((prev) => ({
                    ...prev,
                    bankStateId: 'State is required',
                }));
            } else if (formValues.bankZipcode === "") {
                setFormError((prev) => ({
                    ...prev,
                    bankZipcode: 'Zipcode is required',
                }));
            } else if (formValues.bankZipcode.length !== 5) {
                setFormError((prev) => ({
                    ...prev,
                    bankZipcode: 'Enter 5 digit Zipcode',
                }));
            } else if (formValues.bankPhone === "") {
                setFormError((prev) => ({
                    ...prev,
                    bankPhone: 'Phone is required',
                }));
            } else if (formValues.bankPhone.length !== 10) {
                setFormError((prev) => ({
                    ...prev,
                    bankPhone: '10 digit required',
                }));
            } else if (formValues.bankEmail === "") {
                setFormError((prev) => ({
                    ...prev,
                    bankEmail: 'Email is required',
                }));
            } else {
                stepper.current.goNext();
            }
        }
        setSubmitButton(stepper.current.currentStepIndex === 5);
    };

    const onSubmit = async () => {
        setLoading(true);
        if (formValues.accountType == 1) {
            setFormValues((prev) => ({
                ...prev,
                einnumber: "",
                company: "",
            }))
        }
        try {
            const { data: auth } = await register(
                formValues.accountType,
                formValues.firstName,
                formValues.lastName,
                formValues.email,
                formValues.cellphone,
                formValues.einnumber,
                formValues.company,
                formValues.dob,
                formValues.password,
                formValues.confirmPassword,
                formValues.address1,
                formValues.address2,
                formValues.city,
                formValues.zipcode,
                formValues.countryId,
                formValues.stateId,
                formValues.nameOnAccount,
                formValues.bankName,
                formValues.typeOfAccount,
                formValues.routingNumber,
                formValues.accountNumber,
                formValues.bankAddress,
                formValues.bankBuildingNumber,
                formValues.bankCity,
                formValues.bankStateId,
                formValues.bankZipcode,
                formValues.bankPhone,
                formValues.bankEmail,
                refral_no,
            )
            saveAuth(auth);
            navigate("/thankyou");
            // const { data: user } = await getUserByToken(auth.api_token);
            // setCurrentUser(user);
        } catch (error: any) {
            console.error(error);
            console.log((error as any)?.response.data);
            if (error.response && error.response.data) {
                const errorResponse = error.response.data;

                if (errorResponse.data) {
                    const fieldErrors = errorResponse.data;

                    Object.keys(fieldErrors).forEach((field) => {
                        const errorMessages = fieldErrors[field];
                        const errorMessage = errorMessages[0];

                        // setFieldError(field, errorMessage);
                        showToastMessageError(errorMessage);
                    });
                }
            } else {
                // setFieldError('field_name', 'An error occurred.'); // Fallback error message
                showToastMessageError('An error occurred.');
            }
            // setFieldError('field_name', 'message');
            saveAuth(undefined);
            // setStatus(error.response.data.message);
            // setSubmitting(false);
            setLoading(false);
        }
    };

    const goToFirst = () => {
        if (stepper.current) {
            stepper.current.goto(1);
        }
    };
    const goToSecond = () => {
        if (stepper.current) {
            if (stepper.current.currentStepIndex > 2) {
                stepper.current.goto(2);
            }
        }
    };
    const goToThird = () => {
        if (stepper.current) {
            if (stepper.current.currentStepIndex > 3) {
                stepper.current.goto(3);
            }
        }
    };
    const goToFourth = () => {
        if (stepper.current) {
            if (stepper.current.currentStepIndex > 4) {
                stepper.current.goto(4);
            }
        }
    };

    const showHidePassword = () => {
        setShoHidePass(!shoHidePass);
        if (shoHidePass) {
            setPassInpuType("password");
        } else {
            setPassInpuType("text");
        }
    };

    const showHideConfirmPassword = () => {
        setShoHideConfPass(!shoHideConfPass);
        if (shoHideConfPass) {
            setConfPassInpuType("password");
        } else {
            setConfPassInpuType("text");
        }
    };

    const calculateAge = (dob: Date): number => {
        const today = new Date();
        const birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedDate = e.target.value;
        const dateObject = new Date(selectedDate);

        if (isNaN(dateObject.getTime())) {
            setFormError((prev) => ({
                ...prev,
                dob: 'Invalid date',
            }));
            return;
        }

        const age = calculateAge(dateObject);

        if (age < 21) {
            setFormError((prev) => ({
                ...prev,
                dob: 'You must be 21 years old or older',
            }));
        } else {
            setFormError((prev) => ({
                ...prev,
                dob: '',
            }));
        }

        setFormValues((prevValues: FormValues) => ({
            ...prevValues,
            dob: selectedDate,
        }));
    };

    const handleZipcodeChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value.replace(/[^0-9]/g, '');
        setFormValues((prevValues) => ({
            ...prevValues,
            zipcode: newValue
        }));
    };

    useEffect(() => {
        if (accountType === "personal") {
            setFormValues((prev) => ({
                ...prev,
                accountType: 1,
                einnumber: '',
                company: '',
                typeOfAccount: 1,
            }));
        } else {
            setFormValues((prev) => ({
                ...prev,
                accountType: 2,
                typeOfAccount: 2,
            }));
        }
    }, [accountType]);

    useEffect(() => {
        getStateList();
        if (formValues.countryId === 0) {
            setFormValues((prev) => ({
                ...prev,
                stateId: 0,
            }))
        }
    }, [formValues.countryId]);

    useEffect(() => {
        if (asPermanentCheck) {
            setFormValues((prev) => ({
                ...prev,
                bankAddress: formValues.address1,
                bankCity: formValues.city,
                bankStateId: formValues.stateId,
                bankZipcode: formValues.zipcode,
                bankPhone: formValues.cellphone,
                bankEmail: formValues.email,
                bankBuildingNumber: formValues.address2,
            }));
            setFormError((prev) => ({
                ...prev,
                bankZipcode: '',
            }));
        } else {
            setFormValues((prev) => ({
                ...prev,
                bankAddress: "",
                bankCity: "",
                bankStateId: 0,
                bankZipcode: "",
                bankPhone: "",
                bankEmail: "",
                bankBuildingNumber: "",
            }));
        }
    }, [asPermanentCheck]);

    useEffect(() => {
        if (!stepperRef.current) {
            return;
        }
        loadStepper();
    }, [stepperRef]);

    useEffect(() => {
        getCountryList();
    }, []);

    return (
        <div className='card'>
            <ToastContainer />
            <HelpPopup
                show={showCreateAppModal}
                handleClose={() => {
                    setShowCreateAppModal(false);
                }}
            />
            <div className='card-body'>
                <div
                    ref={stepperRef}
                    className='stepper stepper-links d-flex flex-column'
                    id='kt_create_account_stepper'
                >
                    <div className='stepper-nav'>
                        <div className='stepper-item current' data-kt-stepper-element='nav'>
                            <h3 onClick={goToFirst} className='stepper-title'>Account Type</h3>
                        </div>

                        <div className='stepper-item' data-kt-stepper-element='nav'>
                            <h3 onClick={goToSecond} className='stepper-title'>Account Info</h3>
                        </div>

                        <div className='stepper-item' data-kt-stepper-element='nav'>
                            <h3 onClick={goToThird} className='stepper-title'>{(accountType === "personal") ? "Address" : "Business"} Info</h3>
                        </div>

                        <div className='stepper-item' data-kt-stepper-element='nav'>
                            <h3 onClick={goToFourth} className='stepper-title'>Banking Details</h3>
                        </div>

                        <div className='stepper-item' data-kt-stepper-element='nav'>
                            <h3 className='stepper-title'>Completed</h3>
                        </div>
                    </div>

                    <Formik initialValues={initValues} onSubmit={submitStep}>
                        {() => (
                            <Form className='mx-auto mw-600px w-100 pt-10' id='kt_create_account_form'>

                                <div className='current' data-kt-stepper-element='content'>
                                    {/* First Step :Start */}
                                    <div className='w-100'>
                                        <div className='pb-10 pb-lg-15'>
                                            <h2 className='fw-bolder d-flex align-items-center text-dark'>
                                                Choose Account Type
                                                <i
                                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                                    data-bs-toggle='tooltip'
                                                    title='Billing is issued based on your selected account type'
                                                ></i>
                                            </h2>

                                            <div className='text-gray-400 fw-bold fs-6'>
                                                If you need more info, please check out
                                                <span onClick={() => setShowCreateAppModal(true)} className='cursor-pointer link-primary fw-bolder'>
                                                    {' '}
                                                    Help Page
                                                </span>
                                                .
                                            </div>
                                        </div>

                                        <div className='fv-row'>
                                            <div className='row' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAccountType(e.target.value)}>
                                                {/* Personal Account */}
                                                <div className='col-lg-6'>
                                                    <Field
                                                        type='radio'
                                                        className='btn-check'
                                                        name='accountType'
                                                        value='personal'
                                                        id='kt_create_account_form_account_type_personal'
                                                    />
                                                    <label
                                                        className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
                                                        htmlFor='kt_create_account_form_account_type_personal'
                                                    >
                                                        <KTIcon iconName='address-book' className='fs-3x me-5' />
                                                        <span className='d-block fw-bold text-start'>
                                                            <span className='text-dark fw-bolder d-block fs-4 mb-2'>Personal Account</span>
                                                            <span className='text-gray-400 fw-bold fs-6'>
                                                            Create Personal account
                                                            </span>
                                                        </span>
                                                    </label>
                                                </div>

                                                {/* Corporate Account */}
                                                <div className='col-lg-6'>
                                                    <Field
                                                        type='radio'
                                                        className='btn-check'
                                                        name='accountType'
                                                        value='corporate'
                                                        id='kt_create_account_form_account_type_corporate'
                                                    />
                                                    <label
                                                        className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center'
                                                        htmlFor='kt_create_account_form_account_type_corporate'
                                                    >
                                                        <KTIcon iconName='briefcase' className='fs-3x me-5' />
                                                        <span className='d-block fw-bold text-start'>
                                                            <span className='text-dark fw-bolder d-block fs-4 mb-2'>Business Account</span>
                                                            <span className='text-gray-400 fw-bold fs-6'>
                                                                Create Business account
                                                            </span>
                                                        </span>
                                                    </label>
                                                </div>

                                                <div className='text-danger mt-2'>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* First Step :End */}
                                </div>

                                <div data-kt-stepper-element='content'>
                                    {/* Second Step: Start */}
                                    <div className='w-100'>
                                        <div className='pb-10 pb-lg-15'>
                                            <h2 className='fw-bolder text-dark'>Account Info</h2>
                                            <div className='text-gray-400 fw-bold fs-6'>
                                                If you need more info, please check out
                                                <span onClick={() => setShowCreateAppModal(true)} className='cursor-pointer link-primary fw-bolder'>
                                                    {' '}
                                                    Help Page
                                                </span>
                                                .
                                            </div>
                                        </div>

                                        <div className="row">

                                            {/* First Name */}
                                            <div className="col-sm-12 col-md-6">
                                                <div className='mb-12 fv-row'>
                                                    <label className='form-label mb-1 required'>First Name</label>
                                                    <Field
                                                        type='text'
                                                        innerRef={firstNameRef}
                                                        className='form-control form-control-lg form-control-solid'
                                                        name='firstName'
                                                        value={formValues.firstName}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                            setFormValues((prevValues) => ({
                                                                ...prevValues,
                                                                firstName: e.target.value,
                                                            }))
                                                        }
                                                        placeholder="Enter First Name"
                                                        style={{ textTransform: 'capitalize' }}
                                                    />
                                                    <div className='text-danger' style={{ position: "absolute" }}>
                                                        {formError.firstName && <p>{formError.firstName}</p>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className='mb-12 fv-row'>
                                                    <label className='form-label mb-1 required'>Last Name</label>
                                                    <Field
                                                        type='text'
                                                        innerRef={lastNameRef}
                                                        className='form-control form-control-lg form-control-solid'
                                                        name='lastName'
                                                        value={formValues.lastName}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                            setFormValues((prevValues) => ({
                                                                ...prevValues,
                                                                lastName: e.target.value,
                                                            }))
                                                        }
                                                        placeholder="Enter Last Name"
                                                        style={{ textTransform: 'capitalize' }}
                                                    />
                                                    <div className='text-danger' style={{ position: "absolute" }}>
                                                        {formError.lastName && <p>{formError.lastName}</p>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className='mb-12 fv-row'>
                                                    <label className='form-label mb-1 required'>Email</label>
                                                    <Field
                                                        type='email'
                                                        innerRef={emailRef}
                                                        className='form-control form-control-lg form-control-solid'
                                                        name='email'
                                                        value={formValues.email}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                            setFormValues((prevValues) => ({
                                                                ...prevValues,
                                                                email: e.target.value,
                                                            }))
                                                        }
                                                        placeholder="Enter Email"
                                                    />
                                                    <div className='text-danger' style={{ position: "absolute" }}>
                                                        {formError.email && <p>{formError.email}</p>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className='mb-12 fv-row'>
                                                    <label className='form-label mb-1 required'>Cell Phone</label>
                                                    <Field
                                                        type='text'
                                                        innerRef={phoneRef}
                                                        className='form-control form-control-lg form-control-solid'
                                                        name='cellphone'
                                                        value={formValues.cellphone}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                            setFormValues((prevValues) => ({
                                                                ...prevValues,
                                                                cellphone: e.target.value.replace(/[^0-9]/g, '')
                                                            }));
                                                        }}
                                                        placeholder="Enter Cell Phone"
                                                    />
                                                    <div className='text-danger' style={{ position: "absolute" }}>
                                                        {formError.cellphone && <p>{formError.cellphone}</p>}
                                                    </div>
                                                </div>
                                            </div>

                                            {accountType === "corporate" && (
                                                <div className="col-sm-12 col-md-6">
                                                    <div className='mb-12 fv-row'>
                                                        <label className='form-label mb-1 required'>EIN Number</label>
                                                        <Field
                                                            type='text'
                                                            innerRef={einNumRef}
                                                            className='form-control form-control-lg form-control-solid'
                                                            name='einnumber'
                                                            value={formValues.einnumber}
                                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                                setFormValues((prevValues) => ({
                                                                    ...prevValues,
                                                                    einnumber: e.target.value.replace(/[^0-9]/g, '')
                                                                }));
                                                            }}
                                                            placeholder="Enter EIN Number"
                                                        />
                                                        <div className='text-danger' style={{ position: "absolute" }}>
                                                            {formError.einnumber && <p>{formError.einnumber}</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {accountType === "corporate" && (
                                                <div className="col-sm-12 col-md-6">
                                                    <div className='mb-12 fv-row'>
                                                        <label className='form-label mb-1 required'>Company</label>
                                                        <Field
                                                            type='text'
                                                            innerRef={companyRef}
                                                            className='form-control form-control-lg form-control-solid'
                                                            name='company'
                                                            value={formValues.company}
                                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                                setFormValues((prevValues) => ({
                                                                    ...prevValues,
                                                                    company: e.target.value,
                                                                }))
                                                            }
                                                            placeholder="Enter Company"
                                                        />
                                                        <div className='text-danger' style={{ position: "absolute" }}>
                                                            {formError.company && <p>{formError.company}</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <div className="col-sm-12 col-md-6">
                                                <div className='mb-12 fv-row'>
                                                    <label className='form-label mb-1 required'>DOB</label>
                                                    <Field
                                                        type='date'
                                                        innerRef={dobRef}
                                                        className='form-control form-control-lg form-control-solid'
                                                        name='dob'
                                                        value={formValues.dob}
                                                        onChange={handleDateChange}
                                                        max={minDate}
                                                    />
                                                    <div className='text-danger' style={{ position: "absolute" }}>
                                                        {formError.dob && <p>{formError.dob}</p>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className='mb-12 fv-row'>
                                                    <label className='form-label mb-1 required'>Password</label>
                                                    <div className="d-flex">
                                                        <Field
                                                            type={passInpuType}
                                                            innerRef={passwordRef}
                                                            className='form-control form-control-lg form-control-solid'
                                                            style={{borderRadius:'0.625rem 0 0 0.625rem'}}
                                                            name='password'
                                                            value={formValues.password}
                                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                                setFormValues((prevValues) => ({
                                                                    ...prevValues,
                                                                    password: e.target.value,
                                                                }))
                                                            }
                                                            placeholder='Enter Password'
                                                        />
                                                        <div onClick={showHidePassword} className='d-flex align-items-center bg-gray-100 pe-3' style={{borderRadius:'0 0.625rem 0.625rem 0'}}>
                                                            {shoHidePass ?
                                                                <i className="fa-solid fa-eye text-primary"></i> :
                                                                <i className="fa-solid fa-eye-slash"></i>}
                                                        </div>
                                                    </div>
                                                    <div className='text-danger' style={{ position: "absolute" }}>
                                                        {formError.password && <p>{formError.password}</p>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className='mb-12 fv-row'>
                                                    <label className='form-label mb-1 required'>Confirm Password</label>
                                                    <div className="d-flex">
                                                        <Field
                                                            type={confpassInpuType}
                                                            innerRef={confPasswordRef}
                                                            className='form-control form-control-lg form-control-solid'
                                                            style={{borderRadius:'0.625rem 0 0 0.625rem'}}
                                                            name='confirmPassword'
                                                            value={formValues.confirmPassword}
                                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                                setFormValues((prevValues) => ({
                                                                    ...prevValues,
                                                                    confirmPassword: e.target.value,
                                                                }))
                                                            }
                                                            placeholder="Enter Confirm Password"
                                                        />
                                                        <div onClick={showHideConfirmPassword} className='d-flex align-items-center bg-gray-100 pe-3' style={{borderRadius:'0 0.625rem 0.625rem 0'}}>
                                                            {shoHideConfPass ?
                                                                <i className="fa-solid fa-eye text-primary"></i> :
                                                                <i className="fa-solid fa-eye-slash"></i>}
                                                        </div>
                                                    </div>
                                                    <div className='text-danger' style={{ position: "absolute" }}>
                                                        {formError.confirmPassword && <p>{formError.confirmPassword}</p>}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    {/* Second Step: End */}
                                </div>

                                <div data-kt-stepper-element='content'>
                                    <div>
                                        {/* Third Step: Start */}
                                        <div className='w-100'>
                                            <div className='pb-10 pb-lg-12'>
                                                <h2 className='fw-bolder text-dark'>Address Details</h2>
                                                <div className='text-gray-400 fw-bold fs-6'>
                                                    If you need more info, please check out
                                                    <span onClick={() => setShowCreateAppModal(true)} className='cursor-pointer link-primary fw-bolder'>
                                                        {' '}
                                                        Help Page
                                                    </span>
                                                    .
                                                </div>
                                            </div>

                                            <div className="row">

                                                <div className="col-sm-12 col-md-6">
                                                    <div className='fv-row mb-12'>
                                                        <label className='form-label required'>Street Address</label>
                                                        <Field
                                                            type="text"
                                                            name='address'
                                                            placeholder="Enter Street Address"
                                                            value={formValues.address1}
                                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                                setFormValues((prevValues) => ({
                                                                    ...prevValues,
                                                                    address1: e.target.value,
                                                                }))
                                                            }
                                                            className='form-control form-control-lg form-control-solid'
                                                        />
                                                        <div className='text-danger' style={{ position: "absolute" }}>
                                                            {formError.address1 && <p>{formError.address1}</p>}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-6">
                                                    <div className='fv-row mb-12'>
                                                        <label className='form-label'>Floor or Building Number</label>
                                                        <Field
                                                            type="text"
                                                            name='address2'
                                                            className='form-control form-control-lg form-control-solid'
                                                            value={formValues.address2}
                                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                                setFormValues((prevValues) => ({
                                                                    ...prevValues,
                                                                    address2: e.target.value,
                                                                }))
                                                            }
                                                            placeholder="Enter Floor or Building Number"
                                                        />
                                                        <div className='text-danger' style={{ position: "absolute" }}>
                                                            {formError.address2 && <p>{formError.address2}</p>}
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-6">
                                                    <div className='fv-row mb-12'>
                                                        <label className='form-label required'>City</label>
                                                        <Field
                                                            type="text"
                                                            name='city'
                                                            className='form-control form-control-lg form-control-solid'
                                                            value={formValues.city}
                                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                                setFormValues((prevValues) => ({
                                                                    ...prevValues,
                                                                    city: e.target.value,
                                                                }))
                                                            }
                                                            placeholder="Enter City"
                                                        />
                                                        <div className='text-danger' style={{ position: "absolute" }}>
                                                            {formError.city && <p>{formError.city}</p>}
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-6">
                                                    <div className='fv-row mb-12'>
                                                        <label className='form-label required'>Zipcode</label>
                                                        <Field
                                                            type="text"
                                                            name='zipcode'
                                                            className='form-control form-control-lg form-control-solid'
                                                            value={formValues.zipcode}
                                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                                setFormValues((prevValues) => ({
                                                                    ...prevValues,
                                                                    zipcode: e.target.value.replace(/[^0-9]/g, '')
                                                                }));
                                                            }}
                                                            placeholder="Enter Zipcode"
                                                        />
                                                        <div className='text-danger' style={{ position: "absolute" }}>
                                                            {formError.zipcode && <p>{formError.zipcode}</p>}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-6">
                                                    <div className='fv-row mb-12'>
                                                        <label className='form-label required'>Country</label>
                                                        <Field
                                                            as='select'
                                                            name='country'
                                                            className='form-select form-select-lg form-select-solid'
                                                            value={formValues.countryId}
                                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                                setFormValues((prevValues: FormValues) => ({
                                                                    ...prevValues,
                                                                    countryId: Number(e.target.value),
                                                                }))
                                                            }
                                                        >
                                                            <option value="">--Country--</option>
                                                            {countryData.map((val, index) => {
                                                                return (
                                                                    <option key={index} value={val.id} selected={val.id === 1}>{val.name} ({val.short_name})</option>
                                                                )
                                                            })}
                                                        </Field>
                                                        <div className='text-danger' style={{ position: "absolute" }}>
                                                            {formError.countryId && <p>{formError.countryId}</p>}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-6">
                                                    <div className='fv-row mb-12'>
                                                        <label className='form-label required'>State</label>
                                                        <Field
                                                            as='select'
                                                            name='state'
                                                            className='form-select form-select-lg form-select-solid'
                                                            value={formValues.stateId}
                                                            disabled={formValues.countryId == 0}
                                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                                setFormValues((prevValues: FormValues) => ({
                                                                    ...prevValues,
                                                                    stateId: Number(e.target.value),
                                                                }))
                                                            }
                                                        >
                                                            <option value={0}>--State--</option>
                                                            {stateData.map((val, index) => {
                                                                return (
                                                                    <option key={index} value={val.id}>{val.name} ({val.short_name})</option>
                                                                )
                                                            })}
                                                        </Field>
                                                        <div className='text-danger' style={{ position: "absolute" }}>
                                                            {formError.stateId && <p>{formError.stateId}</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {/* Third Step: End */}
                                    </div>
                                </div>

                                <div data-kt-stepper-element='content'>
                                    {/* Fourth Step: Start */}
                                    <div className='w-100'>
                                        <div className='pb-10 pb-lg-15'>
                                            <h2 className='fw-bolder text-dark'>Banking Details</h2>
                                        </div>

                                        <div className="row">

                                            <div className="col-sm-12 col-md-6">
                                                <div className='d-flex flex-column mb-7 fv-row'>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>Name on the Account</span>
                                                    </label>
                                                    <Field
                                                        type='text'
                                                        className='form-control form-control-solid'
                                                        placeholder='Enter Name on the Account'
                                                        value={formValues.nameOnAccount}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                            setFormValues((prevValues) => ({
                                                                ...prevValues,
                                                                nameOnAccount: e.target.value,
                                                            }))
                                                        }
                                                        name='nameOnAccount'
                                                    />
                                                    <div className='text-danger mt-2'>
                                                        {formError.nameOnAccount && <p style={{ position: "absolute", marginTop: "-12px" }}>{formError.nameOnAccount}</p>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-12">
                                                <div className='d-flex flex-column mb-7 fv-row'>
                                                    <label className='required fs-6 fw-bold form-label mb-2'>Bank Name</label>
                                                    <div className='position-relative'>
                                                        <Field
                                                            type='text'
                                                            className='form-control form-control-solid'
                                                            placeholder='Enter Bank Name'
                                                            value={formValues.bankName}
                                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                                setFormValues((prevValues: FormValues) => ({
                                                                    ...prevValues,
                                                                    bankName: e.target.value,
                                                                }))
                                                            }
                                                            name='bankName'
                                                        />
                                                        <div className='text-danger mt-2'>
                                                            {formError.bankName && <p style={{ position: "absolute", marginTop: "-12px" }}>{formError.bankName}</p>}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-7 col-sm-12">
                                                <label className='required fs-6 fw-bold form-label mb-2'>Type of Account</label>
                                                <Field
                                                    as='select'
                                                    name='typeOfAccount'
                                                    className='form-select form-select-solid'
                                                    value={formValues.typeOfAccount}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                        setFormValues((prevValues: FormValues) => ({
                                                            ...prevValues,
                                                            typeOfAccount: Number(e.target.value),
                                                        }))
                                                    } 
                                                >
                                                    <option value="">Type of Account</option>
                                                    <option value={1}>{(accountType === "personal") ? "Personal" : "Business"} Checking</option>
                                                    <option value={2}>{(accountType === "personal") ? "Personal" : "Business"} Saving</option>
                                                </Field>
                                                <div className='text-danger mt-2'>
                                                    {formError.typeOfAccount && <p style={{ position: "absolute", marginTop: "-12px" }}>{formError.typeOfAccount}</p>}
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-7 col-sm-12">
                                                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                    <span className='required'>ACH Routing Number</span>
                                                </label>
                                                <div className='position-relative'>
                                                    <Field
                                                        type='text'
                                                        className='form-control form-control-solid'
                                                        placeholder='Enter ACH Routing Number'
                                                        name='routingNumber'
                                                        value={formValues.routingNumber}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                            setFormValues((prevValues) => ({
                                                                ...prevValues,
                                                                routingNumber: e.target.value.replace(/[^0-9]/g, '')
                                                            }));
                                                        }}
                                                    />
                                                    <div className='text-danger mt-2'>
                                                        {formError.routingNumber && <p style={{ position: "absolute", marginTop: "-12px" }}>{formError.routingNumber}</p>}
                                                    </div>

                                                    {/* <div className='position-absolute translate-middle-y top-50 end-0 me-3'>
                                                            <KTIcon iconName='credit-cart' className='fs-2hx' />
                                                        </div> */}
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-7 col-sm-12">
                                                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                    <span className='required'>Account Number</span>
                                                </label>
                                                <div className='position-relative'>
                                                    <Field
                                                        type='text'
                                                        className='form-control form-control-solid'
                                                        placeholder='Enter Account Number'
                                                        name='accountNumber'
                                                        value={formValues.accountNumber}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                            setFormValues((prevValues) => ({
                                                                ...prevValues,
                                                                accountNumber: e.target.value.replace(/[^0-9]/g, '')
                                                            }));
                                                        }}
                                                    />
                                                    <div className='text-danger mt-2'>
                                                        {formError.accountNumber && <p style={{ position: "absolute", marginTop: "-12px" }}>{formError.accountNumber}</p>}
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-12">
                                                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                    <span className='required'>Re-Enter Account Number</span>
                                                </label>
                                                <div className='position-relative'>
                                                    <Field
                                                        type='text'
                                                        className='form-control form-control-solid'
                                                        placeholder='Re-Enter Account Number'
                                                        name='reAccountNumber'
                                                        value={formValues.reAccountNumber}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                            setFormValues((prevValues) => ({
                                                                ...prevValues,
                                                                reAccountNumber: e.target.value.replace(/[^0-9]/g, '')
                                                            }));
                                                        }}
                                                    />
                                                    <div className='text-danger mt-2'>
                                                        {formError.reAccountNumber && <p style={{ position: "absolute", marginTop: "-12px" }}>{formError.reAccountNumber}</p>}
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                        <div className='mt-10'>
                                            <h2 className='fw-bolder text-dark'>Address Details</h2>
                                        </div>

                                        <div className="row">

                                            <div className="form-check mt-4">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id="flexCheckDefault"
                                                    checked={asPermanentCheck}
                                                    onChange={(e) => setAsPermanentCheck(e.target.checked)}
                                                />
                                                <label className="form-label" htmlFor="flexCheckDefault">
                                                    As Permanent
                                                </label>
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className='d-flex flex-column mt-7 fv-row'>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>Street Address</span>
                                                    </label>
                                                    <Field
                                                        type='text'
                                                        className='form-control form-control-solid'
                                                        placeholder='Enter Street Address'
                                                        name='StreetAddress'
                                                        value={formValues.bankAddress}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                            setFormValues((prevValues: FormValues) => ({
                                                                ...prevValues,
                                                                bankAddress: e.target.value,
                                                            }))
                                                        }
                                                        disabled={asPermanentCheck}
                                                    />
                                                    <div className='text-danger mt-2'>
                                                        {formError.bankAddress && <p style={{ position: "absolute", marginTop: "-12px" }}>{formError.bankAddress}</p>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className='d-flex flex-column mt-7 fv-row'>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span>Floor or Building Number</span>
                                                    </label>
                                                    <Field
                                                        type='text'
                                                        className='form-control form-control-solid'
                                                        placeholder='Enter Floor or Building Number'
                                                        name='bankBuildingNumber'
                                                        value={formValues.bankBuildingNumber}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                            setFormValues((prevValues: FormValues) => ({
                                                                ...prevValues,
                                                                bankBuildingNumber: e.target.value,
                                                            }))
                                                        }
                                                        disabled={asPermanentCheck}
                                                    />
                                                    <div className='text-danger mt-2'>
                                                        {formError.bankBuildingNumber && <p style={{ position: "absolute", marginTop: "-12px" }}>{formError.bankBuildingNumber}</p>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className='d-flex flex-column mt-7 fv-row'>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>City</span>
                                                    </label>
                                                    <Field
                                                        type='text'
                                                        className='form-control form-control-solid'
                                                        placeholder='Enter City'
                                                        name='bankCity'
                                                        value={formValues.bankCity}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                            setFormValues((prevValues: FormValues) => ({
                                                                ...prevValues,
                                                                bankCity: e.target.value,
                                                            }))
                                                        }
                                                        disabled={asPermanentCheck}
                                                    />
                                                    <div className='text-danger mt-2'>
                                                        {formError.bankCity && <p style={{ position: "absolute", marginTop: "-12px" }}>{formError.bankCity}</p>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className='fv-row mt-7 mb-12'>
                                                    <label className='form-label required'>State</label>
                                                    <Field
                                                        as='select'
                                                        name='state'
                                                        className='form-select form-select-lg form-select-solid'
                                                        value={formValues.bankStateId}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                            setFormValues((prevValues: FormValues) => ({
                                                                ...prevValues,
                                                                bankStateId: Number(e.target.value),
                                                            }))
                                                        }
                                                        disabled={asPermanentCheck}
                                                    >
                                                        <option value="">--State--</option>
                                                        {stateData.map((val, index) => {
                                                            return (
                                                                <option key={index} value={val.id}>{val.name} ({val.short_name})</option>
                                                            )
                                                        })}
                                                    </Field>
                                                    <div className='text-danger' style={{ position: "absolute" }}>
                                                        {formError.bankStateId && <p style={{ position: "absolute", marginTop: "-12px" }}>{formError.bankStateId}</p>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className='d-flex flex-column fv-row'>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>Zipcode</span>
                                                    </label>
                                                    <Field
                                                        type='text'
                                                        className='form-control form-control-solid'
                                                        placeholder='Enter Zipcode'
                                                        name='bankZipcode'
                                                        value={formValues.bankZipcode}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                            setFormValues((prevValues) => ({
                                                                ...prevValues,
                                                                bankZipcode: e.target.value.replace(/[^0-9]/g, '')
                                                            }));
                                                        }}
                                                        disabled={asPermanentCheck}
                                                    />
                                                    <div className='text-danger mt-2'>
                                                        {formError.bankZipcode && <p style={{ position: "absolute", marginTop: "-12px" }}>{formError.bankZipcode}</p>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className='d-flex flex-column fv-row'>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>Phone</span>
                                                    </label>
                                                    <Field
                                                        type='text'
                                                        className='form-control form-control-solid'
                                                        placeholder='Enter Phone'
                                                        name='bankPhone'
                                                        value={formValues.bankPhone}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                            setFormValues((prevValues) => ({
                                                                ...prevValues,
                                                                bankPhone: e.target.value.replace(/[^0-9]/g, '')
                                                            }));
                                                        }}
                                                        disabled={asPermanentCheck}
                                                    />
                                                    <div className='text-danger mt-2'>
                                                        {formError.bankPhone && <p style={{ position: "absolute", marginTop: "-12px" }}>{formError.bankPhone}</p>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className='d-flex flex-column mt-7 fv-row'>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>Email</span>
                                                    </label>
                                                    <Field
                                                        type='email'
                                                        className='form-control form-control-solid'
                                                        placeholder='Enter Email'
                                                        name='bankEmail'
                                                        value={formValues.bankEmail}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                            setFormValues((prevValues: FormValues) => ({
                                                                ...prevValues,
                                                                bankEmail: e.target.value,
                                                            }))
                                                        }
                                                        disabled={asPermanentCheck}
                                                    />
                                                    <div className='text-danger mt-2'>
                                                        {formError.bankEmail && <p style={{ position: "absolute", marginTop: "-12px" }}>{formError.bankEmail}</p>}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    {/* Fourth Step: End */}
                                </div>

                                <div data-kt-stepper-element='content'>
                                    {/* Fifth Step: Start */}
                                    <Step5 />
                                    {/* Fifth Step: End */}
                                </div>

                                <div className='d-flex flex-stack'>
                                    <div className='mr-2'>
                                        <button
                                            onClick={prevStep}
                                            type='button'
                                            className='btn btn-lg btn-light-primary mt-10 me-3'
                                            data-kt-stepper-action='previous'
                                        >
                                            <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                                            Back
                                        </button>
                                    </div>

                                    <div>
                                        {!loading && <button onClick={() => {
                                            if (isSubmitButton) {
                                                onSubmit();
                                            }
                                        }} type='submit' className='btn btn-lg btn-primary mt-10 d-flex align-items-center'>
                                            <span className='indicator-label'>
                                                {!isSubmitButton && 'Continue'}
                                                {isSubmitButton && 'Submit'}
                                            </span>
                                            <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0 mt-1' />
                                        </button>}
                                        {loading && (
                                            <button className='btn btn-lg btn-primary mt-10' disabled>
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            </button>
                                        )}
                                    </div>
                                </div>

                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export { RegistrationStep };
