import React from 'react';

const ACHPayments = () => {
  return (
    <div className='card shadow p-5'>
      <div className=''>
        <h2>Bank Account</h2>
      </div>
      <div>
        <ul>
          <li>
            <p>Be careful to enter the bank account information correctly</p>
          </li>
          <li>
            <p>Do NOT copy and paste. Enter the account number twice, the second time in reverse.</p>
          </li>
          <li>
            <p>Once you apply for approval you can no longer edit.</p>
          </li>
          <li>
            <p>We will then make a small deposit to your account.</p>
          </li>
          <li>
            <p>We will email you once we have completed the test deposit.</p>
          </li>
          <li>
            <p>You will then need to verify the amount deposited.</p>
          </li>
          <li>
            <p>You will then be on the way for on ACH direct payments!</p>
          </li>
        </ul>
      </div>
      <div className="row">
        <div className="col-sm-3">
          <div className='mt-5'>
            <label className="">Routing Number*</label>
            <input
              className='form-control'
              type='text'
              placeholder='Enter Routing Number'
            />
          </div>
          <div className='mt-5'>
            <label className="">Account Number*</label>
            <input
              className='form-control'
              type='text'
              placeholder='Enter Account Number'
            />
          </div>
        </div>
        <div className="col-sm-3">
          <div className='mt-5'>
            <label className="">Bank Name*</label>
            <input
              className='form-control'
              type='text'
              placeholder='Enter Bank Name'
            />
          </div>
          <div className='mt-5'>
            <label className="">Account Number Backwards*</label>
            <input
              className='form-control'
              type='text'
              placeholder='Enter Account Number Backwards'
            />
          </div>
        </div>
        <div className="col-sm-3">
          <div className='mt-5'>
            <label className="">Account Name*</label>
            <input
              className='form-control'
              type='text'
              placeholder='Enter Account Name'
            />
          </div>
        </div>
        <div className="col-sm-3">
          <div className='mt-5 select-wrapper'>
            <label className="">Type*</label>
            <select className='form-select'>
              <option value="">Select Type</option>
              <option value={1}>Personal Checking</option>
              <option value={2}>Person Saving</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ACHPayments;
