/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'react-bootstrap';
import { KTIcon } from '../../../../../../_metronic/helpers';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToastMessageSuccess, showToastMessageError } from '../../../../../modules/Toast-Message/TosatMessage';

const modalsRoot = document.getElementById('root-modals') || document.body;

const StatementReceipt = ({ show, handleClose, statementId, statementCode, userno }) => {
    
    const [fetchCount,setFetchCount] = useState(true);
    const [ReceiptUrl,setReceiptUrl] = useState();
    const [loading,setLoading] = useState(true);

    const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'

    const getLocalData = () => {
    const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    return JSON.parse(data)
    }

    const apiToken = getLocalData().api_token

    const setModalClose = ()=>{
        setFetchCount(true);
        handleClose();
    }

    const API_URL = process.env.REACT_APP_API_URL
  const GENRATE_RECEIPT = `${API_URL}/user/statement-invoice/${statementCode}`

    const generateStatementReceipt = async () => {
        const config = {
          headers: {Authorization: `Bearer ${apiToken}`},
        }
        await axios
          .get(GENRATE_RECEIPT, config)
          .then((response) => {
            setStatementReceiptUrl(response.data)
            console.log(response.data)
            setLoading(false)
          })
          .catch((error) => {
            console.error(error)
            setLoading(false)
          })
      }

    const setStatementReceiptUrl = (userno) =>{
        // const path = `http://localhost:8000/storage/invoices/statement_invoice_${userno}.pdf`;
        const path = `https://erp.makemoneyormiles.com/storage/invoices/statement_invoice_${userno}.pdf`;
        setReceiptUrl(path);
    }
 
    useEffect(() => {
            setReceiptUrl("");
            generateStatementReceipt();
            // setFetchCount(false);
            // setLoading(false);
    }, [statementCode]);

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered modal-xl'
            show={show}
            onHide={setModalClose}
            backdrop={true}
        >
            <div className='modal-header pb-0' style={{backgroundColor:'rgb(50,54,57)'}}>
                <h2 className='text-white'>Receipt : {statementId} {loading==true ? 'Loading..' : ''}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={setModalClose}>
                    {/* <KTIcon className='fs-1 text-white' iconName='cross' /> */}
                    <i className='fas fa-close text-white'></i>
                </div>
            </div>

            <div className='card'>
                <div className='card-body d-flex pt-0 p-0'>
                {/* <div style={{ height: '80vh', overflow: 'auto' }}> */}
                    {loading == true ? (
                    <div className='container text-center' style={{ height: '80vh', width:'100%' }}>
                        <h2>Loading...</h2>
                    </div>
                    ) : (
                    <iframe
                    src={ReceiptUrl}
                    style={{ width: '100%', height: '80vh' }}
                    frameBorder={0}
                    title="PDF Viewer"
                    ></iframe>
                    )}
                    
                {/* </div> */}
                </div>
            </div>
        </Modal>,
        modalsRoot
    );
}

export {StatementReceipt };
