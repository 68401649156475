import React, { useEffect, useState } from 'react';
import '../Style.css';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { AccountHeader } from './newProfile/AccountHeader';
import { Overview } from './newProfile/components/Overview';
import { AddressDetails } from './newProfile/components/AddressDetails';
import DetailsUpdate from './newProfile/components/settings/DetailsUpdate';
import { Banking } from './newProfile/components/Banking';
import { Referrals } from './newProfile/components/Referrals/Referrals';
import ChangePassword from './newProfile/components/settings/ChangePassword';
import Notifications from './newProfile/components/settings/Notifications';
import { Statements } from './newProfile/components/Statements/Statements';
import { PaymentCards } from './newProfile/components/PaymentCards/PaymentCards';
import { Security } from './newProfile/components/Security';

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
  const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  return JSON.parse(data);
}

const Profile = () => {

  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(false);

  const apiToken = getLocalData().api_token;
  const location = useLocation();

  const API_URL = process.env.REACT_APP_API_URL;
  const DETAILS = `${API_URL}/details`;

  const getProfileDetails = async () => {
    setLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${apiToken}` }
    };
    await axios.get(DETAILS, config)
      .then((response) => {
        setProfileData(response.data.data);
        setLoading(false);
        console.log(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const activeTab = params.get('activeTab');
    if (activeTab) {
      const tabLink = document.querySelector(`[href="#${activeTab}"]`);
      if (tabLink) {
        tabLink.click();
      }
    }
  }, [location.search]);

  useEffect(() => {
    getProfileDetails();
  }, []);

  return (
    <div style={{ marginTop: "-20px" }}>
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" role="tabpanel">
          <div className="shadow card p-5 m-5">
            <AccountHeader loading={loading} profileData={profileData} />
            <ul className="nav nav-tabs mb-5 fs-6 profile-main-tabs">
              <li className="nav-item">
                <a className="nav-link active" data-bs-toggle="tab" href="#kt_tab_pane_1">Overview</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_2">Banking</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_3">Referrals</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_4">Address</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_5">Profile</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_6">Statements</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_7">Notification</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_8">Security</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_9">Cards</a>
              </li>
             {/* <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_8">Activity</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_9">Logs</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_10">Password</a>
              </li>*/}
            </ul>
          </div>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="kt_tab_pane_1" role="tabpanel">
              <Overview loading={loading} profileData={profileData} />
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
              <Banking getProfileDetails2={getProfileDetails} loading2={loading} profileData2={profileData} />
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
              <Referrals profileData={profileData} />
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_4" role="tabpanel">
              <AddressDetails loading={loading} profileData={profileData} />
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_5" role="tabpanel">
              <DetailsUpdate getProfileDetails={getProfileDetails} loading={loading} profileData={profileData} />
             
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_6" role="tabpanel">
              <Statements />
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_7" role="tabpanel">
              <Notifications getProfileDetails={getProfileDetails} profileData={profileData} />
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_8" role="tabpanel">
              {/* <Security /> */}
              <ChangePassword />
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_9" role="tabpanel">
              <PaymentCards />
              {/* <ChangePassword /> */}
            </div>
           {/* <div className="tab-pane fade" id="kt_tab_pane_8" role="tabpanel">
              <Activity />
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_9" role="tabpanel">
              <Logs />
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_10" role="tabpanel">
              <ResetPassword />
            </div>*/}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
