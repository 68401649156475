/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { KTIcon } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'


const SidebarMenuMain = () => {
  const BUILD_TYP = process.env.REACT_APP_BUILD_TYPE;
  const intl = useIntl();

  const reportBug = () => {
    console.log("Clicked Report Bug");
  };

  return (
    <>
      {BUILD_TYP == 1 && <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />}
      {BUILD_TYP == 2 && <SidebarMenuItem
        to='/dashboard'
        icon='element-10'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon="bi-app-indicator"
      />}

      <SidebarMenuItem
        to='/deals'
        icon='dollar'
        title='Deals'
        fontIcon='bi-layers'
      />

      <SidebarMenuItem
        to='/mycommitments'
        icon='plus'
        title='My Commitments'
        fontIcon='bi-layers'
      />

      <SidebarMenuItemWithSub
        to='/orders'
        title='Orders'
        fontIcon='bi-archive'
        icon='handcart'
      >
        <SidebarMenuItem
          to='/orders/myorders'
          title='My Orders'
          hasBullet={false}
        />
        <SidebarMenuItem
          to='/orders/ordersdetails'
          title='Orders Details'
          hasBullet={false}
        />
        {/*<SidebarMenuItem
          to='/orders/ordersprice'
          title='Order Price Dispute'
          hasBullet={false}
      />*/}
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to='/tracking'
        icon='geolocation'
        title='Tracking Management'
        fontIcon='bi-layers'
      /><hr />

      {/* <SidebarMenuItem
        to='/shippinglabels'
        icon='tag' //
        title='Shipping Labels'
        fontIcon='bi-layers'
      /><hr />

      <SidebarMenuItemWithSub to='/payments' title='Payments' fontIcon='bi-archive' icon='element-plus'>
        <SidebarMenuItem to='/payments/requestpayments' title='Request Payments' hasBullet={false}
        <SidebarMenuItem to='/payments/achpayments' title='ACH Payments' hasBullet={false}
        <SidebarMenuItem to='/payments/echeckspayments' title='E-checks' hasBullet={false} />
        <SidebarMenuItem to='/payments/paymentdetails' title='Payment Details' hasBullet={false} />
      </SidebarMenuItemWithSub> */}

      <SidebarMenuItem
        to='/returns'
        icon='arrows-loop'
        title='Returns'
        fontIcon='bi-layers'
      />

      <SidebarMenuItem
        to='/support'
        icon='support-24'
        title='Support'
        fontIcon='bi-layers'
      />

      <SidebarMenuItem
        to='/referrals'
        icon='profile-user'
        title='Referrals'
        fontIcon='bi-layers'
      /><hr />

      <SidebarMenuItem
        to='/faqs'
        icon='question-2' //
        title='FAQs'
        fontIcon='bi-layers'
      />

      <SidebarMenuItemWithSub
        to='/help'
        title='Help'
        fontIcon='bi-archive'
        icon='information-2' //
      >
        <SidebarMenuItem
          to='/help/help'
          title='Help'
          hasBullet={false}
        />
        <SidebarMenuItem
          to='/help/reportbug'
          title='Report A Bug'
          hasBullet={false}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to='/termsandconditions'
        icon='burger-menu-4' //
        title='Terms And Conditions'
        fontIcon='bi-layers'
      />
      <hr />

      <SidebarMenuItem
        to='/profile'
        icon='user'
        title='Profile'
        fontIcon='bi-layers'
      />

      {/* <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItemWithSub
        to='/crafted/widgets/tables'
        title='Tables'
        icon='element-7'
        fontIcon='bi-layers'
      > */}
      {/* <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} /> */}
      {/* <SidebarMenuItem icon='element-7' to='/crafted/widgets/tables' title='Tables' /> */}
      {/* <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} /> */}
      {/* </SidebarMenuItemWithSub> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div> */}
      {/* <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItem
        to='/apps/user-management/users'
        icon='abstract-28'
        title='User management'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export { SidebarMenuMain }
