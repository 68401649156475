/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'react-bootstrap';
import { toAbsoluteUrl } from '../../../../../../_metronic_front/helpers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToastMessageSuccess, showToastMessageError } from '../../../../../modules/Toast-Message/TosatMessage';
import axios from 'axios';

const modalsRoot = document.getElementById('root-modals') || document.body;

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
    const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    return JSON.parse(data);
}

const AddCard = ({ show, handleClose, SuccessRefresh }) => {

    const apiToken = getLocalData().api_token;


    // const [cardData, setCardData] = useState([
    //     {
    //         card_provdier : '',
    //         name_on_card : '',
    //         card_number:'',
    //         expiry_month : '',
    //         expiry_year : '',
    //         cvv : '',
    //         primary_status : '0',
    //         status:'1'
    //     }
    // ]);

    const [PaymentCardSaveAPI,setPaymentCardSaveAPI] =useState();
    const [editable,setEditable] =useState(false);

    const [itemId,setItemId] = useState();
    const [nameOnCard,setNameOnCard] = useState();
    const [cardNumber,setCardNumber] = useState();
    const [expMonth,setExpMonth] = useState();
    const [expYear,setExpYear] = useState();
    const [cvv,setCvv] = useState();
    const [primaryStatus,setPrimaryStatus] = useState(0);
    const [status,setStatus] = useState(1);

    const [cardErr, setCardErr] = useState([
        {
            card_provdierErr : '',
            name_on_cardErr : '',
            card_numberErr:'',
            expiry_monthErr : '',
            expiry_yearErr : '',
            cvvErr : '',
            primary_statusErr : ''
        }
    ]);


    const API_URL = process.env.REACT_APP_API_URL;
   
    
    const PAYMENT_CARD_ADD = `${API_URL}/user/payment-card/add`;
    const PAYMENT_CARD_UPDATE = `${API_URL}/user/payment-card/update`;

    const CardNumberValidator = (cardNumber) => {
        cardNumber = cardNumber.replace(/\D/g, '');

        // Check if the card number length is within the specified range
        if (cardNumber.length < 13 || cardNumber.length > 19) {
            return false;
        }

        var firstTwoDigits = cardNumber.substr(0, 2);
        var firstDigit = cardNumber.substr(0, 1);
        console.log(firstTwoDigits)

    // Determine the card provider based on the first two digits and card length
    if (firstTwoDigits === "34" || firstTwoDigits === "37") {
        return true;
    } else if (firstTwoDigits === "51" || firstTwoDigits === "52" || firstTwoDigits === "53" || firstTwoDigits === "54" || firstTwoDigits === "55") {
        
        return true;
    } else if (firstDigit === "4") {
        return true;
    } else if (firstDigit === "6") {
        return true;
    } else {
        return false;
    }

    }


    const handleChangeNameOnCard = (e) =>{
        const { value } = e.target;
        // setCardData((prevCardData) =>({
        //     ...prevCardData,
        //       name_on_card: value,
        // }))
        setNameOnCard(value)
        
    }
    const handleChangeCardNumber = (e) =>{
        const { value } = e.target;
        setCardNumber(value)
    }
    const handleChangeExpiryMonth = (e) =>{
        const { value } = e.target;
        setExpMonth(value)
    }
    const handleChangeExpiryYear = (e) =>{
        const { value } = e.target;
        setExpYear(value)
    }
    const handleChangeStatus = (e) =>{
        const { value } = e.target;
        setStatus(value)
    }
    const handleChangeCvv = (e) =>{
        const { value } = e.target;
        setCvv(value)
    }
    const handleChangePrimaryStatus = (e,value) =>{
        const { checked } = e.target;
        if(checked == true){
        setPrimaryStatus(1)
        }
        else{
            setPrimaryStatus(0)
        }
    }

    const ClearCardStates = () =>{
        setNameOnCard("");
        setCardNumber("");
        setExpMonth("");
        setExpYear("");
        setCvv("");
        setPrimaryStatus(0);
        setStatus(1);
    }

    const AddCardRequest = () =>{
        axios.post(PAYMENT_CARD_ADD, {
            name_on_card: nameOnCard,
            card_number: cardNumber,
            expiry_month: expMonth,
            expiry_year: expYear,
            cvv: cvv,
            primary_status: primaryStatus,
            status: status,
        }).then((response) => {
            showToastMessageSuccess(response.data.message);
            ClearCardStates();
            SuccessRefresh();
            handleClose();
        })
        .catch((error) => {
            console.error(error);
            showToastMessageError(error.message);
        });
    }

    const saveUserCard = async () =>{
        // console.log(cardData);
        let isValidate = true;
        if(nameOnCard === ''){
            isValidate = false;
            setCardErr((prevCardErr) => {
                const updatedCardErr = [...prevCardErr];
                updatedCardErr.name_on_cardErr = 'Name on Card is Required!';
                return updatedCardErr;
            });
        };
        if(cardNumber === ''){
            isValidate = false;
            setCardErr((prevCardErr) => {
                const updatedCardErr = [...prevCardErr];
                updatedCardErr.card_numberErr = 'Card Number is Required!';
                return updatedCardErr;
            });
        }else{
            const cardValidate = CardNumberValidator(cardNumber);
            if (cardValidate == false) {
                isValidate = false;
                setCardErr((prevCardErr) => {
                    const updatedCardErr = [...prevCardErr];
                    updatedCardErr.card_numberErr = 'Invalid Card Number!';
                    return updatedCardErr;
                });
            }
        }
        
        if(expMonth === ''){
            isValidate = false;
            setCardErr((prevCardErr) => {
                const updatedCardErr = [...prevCardErr];
                updatedCardErr.expiry_monthErr = 'Expiry Month is Required!';
                return updatedCardErr;
            });
        };
        if(expYear === ''){
            isValidate = false;
            setCardErr((prevCardErr) => {
                const updatedCardErr = [...prevCardErr];
                updatedCardErr.expiry_yearErr = 'Expiry Year is Required!';
                return updatedCardErr;
            });
        };
        if(cvv === ''){
            isValidate = false;
            setCardErr((prevCardErr) => {
                const updatedCardErr = [...prevCardErr];
                updatedCardErr.cvvErr = 'CVV is Required!';
                return updatedCardErr;
            });
        };
        if(isValidate == true){
        
            setCardErr([{ name_on_cardErr:'',card_numberErr: '', expiry_monthErr: '', expiry_yearErr:'' , cvvErr:'',primary_statusErr:'' }]);
            AddCardRequest();
            
            // console.log("Card Add",cardData)
        }
        
    }


   



    const months = Array.from({ length: 12 }, (_, index) => index + 1); // Generate array [1, 2, ..., 12]

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 10 }, (_, index) => currentYear + index); // Generate array [currentYear, currentYear + 1, ..., currentYear + 9]


    return createPortal(
        <Modal
            id=''
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={show}
            onHide={() => {
                handleClose();
                ClearCardStates();
                setCardErr([{ name_on_cardErr:'',card_numberErr: '', expiry_monthErr: '', expiry_yearErr:'' , cvvErr:'',primary_statusErr:'' }]);
            }}
            backdrop={true}
        >
            <ToastContainer />
            <div className='modal-header'>
                    <h2>Add Payment Card</h2>
                
                <div className='btn btn-sm btn-icon btn-secondary close' onClick={() => {
                    handleClose();
                    ClearCardStates();
                    setCardErr([{ name_on_cardErr:'',card_numberErr: '', expiry_monthErr: '', expiry_yearErr:'' , cvvErr:'',primary_statusErr:'' }]);
                }}>
                    <span aria-hidden="true">&times;</span>
                </div>
            </div>
            <ToastContainer />

            <div className='p-10 pt-0 pb-5'>
            
                <div className='tracking_import'>
                    
                <div >
                    <div className="form-group title_field">
                        <div className="row">
                            <div className="col-12 mb-3">
                                <label className="form-label required">Name on Card</label>
                                <input
                                    value={nameOnCard}
                                    onChange={(e) => handleChangeNameOnCard(e)}
                                    type="text"
                                    name="name_on_card"
                                    className="form-control"
                                    placeholder='Name On Card'
                                />
                                {cardErr && <p className='text-danger position-absolute' style={{ marginTop: "-3px" }}>{cardErr.name_on_cardErr}</p>}
                            </div>
                            <div className="col-12 mb-3">
                                <label className="form-label required">Card Number</label>
                                <input
                                    value={cardNumber}
                                    onChange={(e) => handleChangeCardNumber(e)}
                                    type="text"
                                    name="card_number"
                                    className="form-control"
                                    placeholder='Card Number'
                                />
                                {cardErr && <p className='text-danger position-absolute' style={{ marginTop: "-3px" }}>{cardErr.card_numberErr}</p>}
                            </div>
                        </div>
                        <div className="row  mb-3">
                            <div className="col-8">
                            <label className="form-label required">Expiration Date</label>
                                <div className="row">
                                    <div className="col-6">
                                    <select
                                        value={expMonth}
                                        onChange={(e) => handleChangeExpiryMonth(e)}
                                        className="form-select"
                                        placeholder='Month'
                                    >
                                        <option value="">Month</option>
                                        <option value="01">01</option>
                                        <option value="02">02</option>
                                        <option value="03">03</option>
                                        <option value="04">04</option>
                                        <option value="05">05</option>
                                        <option value="06">06</option>
                                        <option value="07">07</option>
                                        <option value="08">08</option>
                                        <option value="09">09</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        {/* {months.map((month) => (
                                            <option key={month} value={month}>{month}</option>
                                        ))} */}
                                    </select>
                                        {cardErr && <p className='text-danger position-absolute' style={{ marginTop: "-3px" }}>{cardErr.expiry_monthErr}</p>}
                                    </div>

                                    <div className="col-6">
                                    <select
                                        value={expYear}
                                        onChange={(e) => handleChangeExpiryYear(e)}
                                        className="form-select"
                                        placeholder='Year'
                                    >
                                        <option value="">Year</option>
                                        {years.map((year) => (
                                            <option key={year} value={year}>{year}</option>
                                        ))}
                                    </select>
                                        {cardErr && <p className='text-danger position-absolute' style={{ marginTop: "-3px" }}>{cardErr.expiry_yearErr}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <label className="form-label required">Cvv</label>
                                <input
                                    value={cvv}
                                    onChange={(e) => handleChangeCvv(e)}
                                    type="text"
                                    name="cvv"
                                    className="form-control"
                                    placeholder='CVV'
                                />
                                {cardErr && <p className='text-danger position-absolute' style={{ marginTop: "-3px" }}>{cardErr.cvvErr}</p>}
                            </div>
                            <div className="col-12 mt-3 mb-3">
                            <div className='form-check form-check-sm form-check-custom form-check-solid d-flex align-items-center'>
                                <input
                                    value={(primaryStatus == 1) ? true : false}
                                    onChange={(e) => handleChangePrimaryStatus(e,primaryStatus)}
                                    type="checkbox"
                                    name="primary_status"
                                    className="form-check-input"
                                />
                                <label className="form-label ms-2">Primary Card</label>
                            </div>
                            </div>
                            <div className="col-12">
                                <label className="form-label">Status</label>
                                <select
                                        onChange={(e) => handleChangeStatus(e)}
                                        className="form-select"
                                    >
                                        
                                        <option value="1" {...status == 1 ? 'selected' : ''}>Active</option>
                                        <option value="0" {...status == 0 ? 'selected' : ''}>Inactive</option>
                                    </select>
                                {cardErr && <p className='text-danger position-absolute' style={{ marginTop: "-3px" }}>{cardErr.cvvErr}</p>}
                            </div>
                        </div>

                        
                    </div>
                    <br />
                </div>
                           
                </div>

                <div className='d-flex justify-content-end'>



                    <div className=''>
                        <button class="btn btn-secondary me-3" onClick={() => {
                            handleClose();
                            ClearCardStates();
                            setCardErr([{ name_on_cardErr:'',card_numberErr: '', expiry_monthErr: '', expiry_yearErr:'' , cvvErr:'',primary_statusErr:'' }]);
                        }}>Close</button>

                        <button className='btn btn-primary' onClick={saveUserCard}>Save</button>
                    </div>
                </div>
            </div>

        </Modal>,
        modalsRoot
    );
}

export { AddCard };
