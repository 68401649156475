import { useState } from 'react';
import '../Style.css';
import { HelpPopup } from './HelpPopup';
import { toAbsoluteUrl } from '../../../_metronic/helpers';

const Help = () => {

  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  return (
    <>
    <HelpPopup
        show={showCreateAppModal}
        handleClose={() => {
          setShowCreateAppModal(false);
        }}
      />

<div className="row mb-5">
    <div className='col-sm-12 col-md-12 col-lg-12'>
    <div className='card shadow p-5 help-detail-area'>
      <div className="row">
        <div className="col-lg-7">
          <div className="heading mb-5">
            <h3>Thank you for your interest in MAKEMONEYORMILES.</h3>
            <p>Our team is dedicated to helping you with any issues or concerns you may have</p>
          </div>
          <div className="details mb-5">
            <div className="p-2 d-flex flex-row align-items-center" style={{border:'2px solid skyblue',borderRadius:20}}>
              <div className="row">
                <div className="col-3 d-flex justify-content-center align-items-center">
                <i className="fa-solid fa-location-dot" style={{fontSize:50 ,color:'skyblue'}}></i>
                </div>
                <div className="col-9">
                <h2>Our Address</h2>
                <h3 className="" style={{color:'grey'}}>7288 NW Baneberry Pl, Portland OR 97229</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
          <div className='col-sm-12 col-md-6 col-lg-6 mb-2'>
            <div className="card shadow">
            <a href="" className='btn btn-warning' style={{backgroundColor:'skyblue'}}>
              <div className="card-body p-0 border-0 d-flex align-items-center">
                <img src={toAbsoluteUrl('/media/svg/help/brodcast-icon.svg')} className="w-50px me-3"/>
                <h4>Join Our Community Broadcast</h4>
              </div>
              </a>
            </div>
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 mb-2'>
            <div className="card shadow">
            <a href="#"  onClick={() => setShowCreateAppModal(true)} className='btn btn-warning' style={{backgroundColor:'skyblue'}}>
              <div className="card-body p-0 border-0 d-flex align-items-center">
                <img src={toAbsoluteUrl('/media/svg/help/bug-icon.svg')} className="w-50px me-3"/>
                <h4>Report a Bug</h4>
              </div>
              </a>
            </div>
          </div>
            
          </div>
        </div>
        <div className="col-lg-5">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2792.9002499836674!2d-122.8539979!3d45.572444499999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54950613ba5e2b27%3A0x2076bda1f6c4f06b!2sNW%20Baneberry%20Pl%2C%20Bethany%2C%20OR%2097229%2C%20USA!5e0!3m2!1sen!2sin!4v1708759069846!5m2!1sen!2sin" width="100%" height="300" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
    </div> 
  </div> 

  <div className="row">

      <div className='col-sm-12 col-md-6 col-lg-3 mb-2'>
        <div className="card">
          <div className='card-body d-flex flex-column align-items-center'>            
            <img src={toAbsoluteUrl('/media/svg/help/enquiry-icon.svg')} className="w-65px mb-3"/>
            <h3 className='mb-2'>General Inquiry</h3>
            <a href="" className='d-flex  align-items-center'><h4 className='text-warning'><i className="fa-brands fa-whatsapp me-2 text-warning" style={{fontSize:25}}></i>+1 346-350-9010</h4></a>
          </div>
        </div>
      </div>
      <div className='col-sm-12 col-md-6 col-lg-3 mb-2'>
        <div className="card">
          <div className='card-body d-flex flex-column align-items-center'>            
            <img src={toAbsoluteUrl('/media/svg/help/account-icon.svg')} className="w-65px mb-3"/>
            <h3 className='mb-2'>Account Inquiry</h3>
            <a href="" className='d-flex  align-items-center'><h4 className='text-warning'><i className="fa-brands fa-whatsapp me-2 text-warning" style={{fontSize:25}}></i>+1 346-350-9010</h4></a>
          </div>
        </div>
      </div>
      <div className='col-sm-12 col-md-6 col-lg-3 mb-2'>
        <div className="card">
          <div className='card-body d-flex flex-column align-items-center'>            
            <img src={toAbsoluteUrl('/media/svg/help/card-icon.svg')} className="w-65px mb-3"/>
            <h3 className='mb-2'>Payment Inquiry</h3>
            <a href="" className='d-flex  align-items-center'><h4 className='text-warning'><i className="fa-brands fa-whatsapp me-2 text-warning" style={{fontSize:25}}></i>+1 503-919-5272</h4></a>
          </div>
        </div>
      </div>
      <div className='col-sm-12 col-md-6 col-lg-3 mb-2'>
        <div className="card">
          <div className='card-body d-flex flex-column align-items-center'>            
            <img src={toAbsoluteUrl('/media/svg/help/package-icon.svg')} className="w-65px mb-3"/>
            <h3 className='mb-2'>Missing Package Inquiry</h3>
            <a href="" className='d-flex  align-items-center'><h4 className='text-warning'><i className="fa-brands fa-whatsapp me-2 text-warning" style={{fontSize:25}}></i>+1 346-350-9010</h4></a>
          </div>
        </div>
      </div>
      

    </div>
    </>
  );
}

export default Help;
