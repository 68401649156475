import React from 'react';

const Echecks = () => {
  return (
    <div>
      <h1>This is E-Checks Page</h1>
      <div className='card shadow'>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-120px'>ID</th>
                  <th className='min-w-120px'>Print</th>
                  <th className='min-w-120px'>Void</th>
                  <th className='min-w-120px'>Check No</th>
                  <th className='min-w-120px'>Pay To</th>
                  <th className='min-w-120px'>Amount</th>
                  <th className='min-w-120px'>Status</th>
                  <th className='min-w-120px'>Memo</th>
                  <th className='min-w-120px'>Printed Date</th>
                  <th className='min-w-120px'>Processed Date</th>
                  <th className='min-w-120px'>Payment ID</th>
                  <th className='min-w-120px'>Created Date</th>
                  <th className='min-w-120px'>Modified Date</th>
                </tr>
              </thead>
              <tbody>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Echecks;
