import React,{useState} from 'react'
import CurrencyFormat from 'react-currency-format';

const Detail = ({ content, onClose}) => {


  const getStatus = () =>{
    var statusText = "";
    if (content.deal_status == 0) {
      statusText = <span className='badge badge-light-light-success fs-8 p-3 fw-bold success_active'>Received</span>
    } else if (content.deal_status == 1) {
      statusText = <span className='badge badge-light-warning fs-8 p-3 fw-bold'>Pending</span>
    } else if (content.deal_status == 2) {
      statusText = <span className='badge badge-light-info fs-8 p-3 fw-bold'>Active</span>
    } else if (content.deal_status == 3) {
      statusText = <span className='badge badge-light-danger fs-8 p-3 fw-bold'>Expired</span>
    } else if (content.deal_status == 4) {
      statusText = <span className='badge badge-light-warning fs-8 p-3 fw-bold'>In prpgress</span>
    } else if (content.deal_status == 5) {
      statusText = <span className='badge badge-light-warning fs-8 p-3 fw-bold'>Return Pending</span>
    } else if (content.deal_status == 6) {
      statusText = <span className='badge badge-light-info fs-8 p-3 fw-bold'>Return Active</span>
    } else if (content.deal_status == 7) {
      statusText = <span className='badge badge-light-success fs-8 p-3 fw-bold'>Return Completed</span>
    }
    return statusText;
  }

  return (
    <div className="modal modal-lg" tabIndex="-1" role="dialog" style={{ display: 'block'}}>
      <div className="modal-dialog" role="document" style={{ border: '2px solid #ccc',boxShadow: '0 2px 4px #ccc'  }}>
        <div className="modal-content">
          <div className="modal-header d-flex justify-content-between">
          <h4 className='mb-2'>Deal Id : {content.deal_no}</h4>
          <button type="button" className="btn btn-light" onClick={onClose}>
              <span aria-hidden="true">Close &times;</span>
            </button>
          </div>
          <div className="modal-body p-3" style={{ borderRadius:15 }}>
          <div className="row">
            <div className="col-lg-4 col-md-12 p-2 d-flex justify-content-center align-items-center">
            {/* <img src={'https://erp.makemoneyormiles.com/storage/deals/549418.jpg'} className='' width={150} /> */}
            <img src={content.deal_image}  width={150}  />
            </div>
            <div className="col-lg-8 col-md-12 p-2">
            <div style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
            <h4 className='mb-3'>{content.deal_name}</h4>
            </div>
            
            <h5 className='mb-2'>SKU : {content.deal_sku}</h5>
            <h5 className='mb-2'>Price : 
            <CurrencyFormat
              decimalScale={2}
              value={parseFloat(content.deal_price).toFixed(2)}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
            </h5>
            <h5 className='mb-2'>Fullfilled : {content.fulfilled}</h5>
            <h5>{getStatus()}</h5>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Detail