import axios, { AxiosResponse } from 'axios';
import { AuthModel, UserModel } from './_models';

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`;
export const LOGIN_URL = `${API_URL}/login`;
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/send-OTP/update-password`;

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  }).then((d: AxiosResponse) => d.data)
}

// Server should return AuthModel
export function register(
  accountType: number,
  first_name: string,
  last_name: string,
  email: string,
  phone: string,
  einnumber: string,
  company: string,
  dob: string,
  password: string,
  password_confirmation: string,
  address1: string,
  address2: string,
  city: string,
  zipcode: string,
  countryId: number,
  stateId: number,
  nameOnAccount: string,
  bankName: string,
  typeOfAccount: number,
  routingNumber: string,
  accountNumber: string,
  bankAddress: string,
  bankBuildingNumber: string,
  bankCity: string,
  bankStateId: number,
  bankZipcode: string,
  bankPhone: string,
  bankEmail: string,
  refral_no: string,
) {
  return axios.post(REGISTER_URL, {
    account_type: accountType,
    first_name,
    last_name,
    email,
    phone,
    ein_number: einnumber,
    company,
    dob,
    password,
    password_confirmation,
    address1,
    address2,
    city,
    zipcode,
    country_id: countryId,
    state_id: stateId,
    account_name: nameOnAccount,
    bank_name: bankName,
    bank_account_type: typeOfAccount,
    routing_number: routingNumber,
    account_number: accountNumber,
    bank_street_address: bankAddress,
    bank_building_number: bankBuildingNumber,
    bank_city: bankCity,
    bank_state_id: bankStateId,
    bank_zipcode: bankZipcode,
    bank_phone: bankPhone,
    bank_email: bankEmail,
    refral_no,
  })
    .then((d: AxiosResponse) => d.data);
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(
  email: string,
  otp: string,
  password: string,
  password_confirmation: string
) {
  return axios.post<{ message(message: any): unknown; }>(REQUEST_PASSWORD_URL, {
    email,
    otp,
    password,
    password_confirmation
  })
}

export function getUserByToken(token: string) {
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  return axios.post(GET_USER_BY_ACCESSTOKEN_URL, config, {})
  /*return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })*/
}
