import React, { useEffect, useState } from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import axios from 'axios';
import Loader from "react-js-loader";
import CurrencyFormat from 'react-currency-format';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../myCommitments/filterbutton.css'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
  const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  return JSON.parse(data);
}

const MyOrders = () => {

  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState([]);
  
  const [isOpen, setIsOpen] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [toggleFilter, setToggleFilter] = useState("NONE");
  const [filterOpen, setFilterOpen] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [searchQuery,setSearchQuery] = useState("");

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if(isOpen){
      setToggleFilter("NONE");
    }
  };

  const handleButtonClick = () => {
    console.log("floating button clicked");
  };

  const openFilterBox = ()=>{
    setFilterOpen(true);
  }

  const closeFilterBox = ()=>{
    setFilterOpen(false);
  }

  const apiToken = getLocalData().api_token;

  const API_URL = process.env.REACT_APP_API_URL;
  const ORDERS = `${API_URL}/user/orders`;

  const getOrderData = async () => {
    setLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${apiToken}` }
    };
    await axios.get(ORDERS, config)
      .then((response) => {
        setOrderData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getOrderData();
  }, []);

  const startDateObject = new Date(startDate);
  const endDateObject = new Date(endDate);

  const handleSingleSearch = (value)=>{
    setSearchQuery(value)
  }

  const handleFilter = () => {
    if (startDate !== "" && endDate !== "") {
      const filteredItems = orderData.filter(
        (item) =>
          item.order_no?.toLowerCase().includes(searchQuery.toLowerCase()) &&
          item.tracking_id?.toLowerCase().includes(searchQuery.toLowerCase()) &&
          new Date(item.date) >= startDateObject && new Date(item.date) <= endDateObject
      );
      return filteredItems;
    } else {
      const filteredItems = orderData.filter(
        (item) =>
          item.order_no?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.tracking_id?.toLowerCase().includes(searchQuery.toLowerCase())
      );
      return filteredItems;
    }
  };

  const cancleSearch = () => {
    setStartDate("");
    setEndDate("");
    setSearchQuery("")
  };

  const tableHeader = () =>{
    if(toggleFilter == "SEARCH"){
      return(
        <div className="pb-2 pt-5">
        <div className='d-flex mb-5'>
            <div className='mx-2 search-filter-wrap'>
              <input
                type='text'
                className='form-control'
                placeholder='Search here...'
                value={searchQuery}
                onChange={(e) => handleSingleSearch(e.target.value)}
              />
            </div>

            <div className='mx-2'>
            <button
              onClick={() => cancleSearch()}
              className='btn btn-info'
            >
              <i className="fa-solid fa-xmark fs-3"></i>
            </button>
          </div>
        </div>
        </div>
  
      )
    }
    if(toggleFilter == "DATE"){
      return(
        <div className="pb-2 pt-5">
        <div className='d-flex mb-5'>
        <div className='mx-2  calendar-filter-wrap'>
             <label className='form-label position-absolute text-gray-600' style={{ marginTop: "-18px" }}>Start Date</label>
            <input
              type='date'
              className='form-control'
              placeholder='DD-MM-YYYY'
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className='mx-2 calendar-filter-wrap'>
             <label className='form-label position-absolute text-gray-600' style={{ marginTop: "-18px" }}>End Date</label>
            <input
              type='date'
              className='form-control'
              placeholder='DD-MM-YYYY'
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            
          </div>
          <div className='mx-2'>
            <button
              onClick={() => cancleSearch()}
              className='btn btn-infor'
              disabled={startDate === "" && endDate === ""}
            >
              <i className="fa-solid fa-xmark fs-3"></i>
            </button>
          </div>
        </div>
        </div>
      )
    }
    if(toggleFilter == "NONE"){
      return false;
    }
  }

  const filterButton = ()=>{
    return(
      <div className="floating-button-container">
        {isOpen && (
          <div className={`menu-buttons ${isOpen ? 'show' : ''}`}>
            {/* <button className="sub-button" onClick={()=> setToggleFilter("EXPORT")}><i className="fas fa-download"></i></button> */}
            <button className="sub-button" onClick={()=> setToggleFilter("DATE")}><i className="fas fa-calendar-alt"></i></button>
            <button className="sub-button" onClick={()=> setToggleFilter("SEARCH")}><i className="fas fa-search"></i></button>
          </div>
        )}
        <button
          className="main-button"
          onClick={toggleMenu}
        >
          {isOpen ?
          <i className="fas fa-close"></i>
          :
          <i className="fas fa-sliders-h"></i>
          }
          
          
        </button>
      </div>
    )
  }

  const statusBody = (item)=>{
    return(
      <>
      {item.status == 0 && (
        <span className='badge badge-light-success fs-8 fw-bold success_active'>Received</span>
      )}
      {item.status == 1 && (
        <span className='badge badge-light-warning fs-8 fw-bold warning_active'>Pending</span>
      )}
      {item.status == 2 && (
        <span className='badge badge-light-success fs-8 fw-bold success_active'>Active</span>
      )}
      {item.status == 3 && (
        <span className='badge badge-light-danger fs-8 danger_active'>Expired</span>
      )}
      {item.status == 4 && (
        <span className='badge badge-light-danger fs-8 fw-bold danger_active'>Not Good</span>
      )}
      {item.status == 5 && (
        <span className='badge badge-light-warning fs-8 fw-bold warning_active'>Return Pending</span>
      )}
      {item.status == 6 && (
        <span className='badge badge-info fs-8 p-3 fw-bold'>Return Active</span>
      )}
      {item.status == 7 && (
        <span className='badge badge-light-success fs-8 fw-bold success_active'>Return Completed</span>
      )}
      </>
    )
  }

  const srnoBody = (item) =>{
    return (
      <span>{orderData.indexOf(item) + 1}</span>
    );
  }

  const commissionBody = (item) => {
    return(
      <span className='text-dark text-hover-primary fs-6'>
        <CurrencyFormat
          decimalScale={2}
          value={parseFloat(item.commission).toFixed(2)}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
        />
      </span>
    )
  };
  
  const priceBody = (item) => {
    return(
      <span className='text-dark text-hover-primary fs-6'>
        <CurrencyFormat
          decimalScale={2}
          value={parseFloat(item.price).toFixed(2)}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
        />
      </span>
    )
  };

  const totalBody = (item) => {
    // const total = item.price * item.qty;
    const total = item.total;
    return(
      <span className='text-dark text-hover-primary fs-6'>
        <CurrencyFormat
          decimalScale={2}
          value={parseFloat(total).toFixed(2)}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
        />
      </span>
    )
  };

  const columnStyle = {
    paddingLeft:10,
    paddingRight:10,
    paddingTop:10,
    paddingBottom:10,
    marginLeft:10,
    marginRight:10,
    width:'200px'
  }

  return (
    <div>
      <h1>My Orders</h1>
      <div className='card shadow'>
        <div className='card-body p-0'>
        <div className="prime-table">
        <DataTable value={handleFilter()} removableSort size="large"
        paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ minWidth: '60rem' ,alignItems:'center',marginLeft:'auto', fontSize:15}}
                scrollable scrollHeight="90vh" style={{ minWidth: '50rem' }}
                header={isOpen ? tableHeader : ''}
                >

          
          <Column body={srnoBody} header="#" style={columnStyle}></Column>      
          <Column field="order_no" header="Order ID" sortable style={columnStyle}></Column>
          <Column field="tracking_id" header="Tracking No." sortable style={columnStyle}></Column> 
          <Column field="qty" header="Total Qty" style={columnStyle}></Column>
          <Column body={priceBody} header="Total Price" style={columnStyle}></Column>
          <Column body={commissionBody} header="Total Comm" style={columnStyle}></Column>
          <Column body={totalBody} header="Grand Total" style={columnStyle}></Column>  
          <Column body={statusBody} header="Status" style={columnStyle}></Column>         
          <Column field="date" header="Created Date" sortable style={columnStyle}></Column> 
          <Column field="departure_date" header="Modified Date" sortable style={columnStyle}></Column> 

        </DataTable>
        </div>
        </div>
      </div>
      <div style={{zIndex:999}}>
      {filterButton()}
      </div>
    </div>
  );
}

export default MyOrders;
