/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Loader from "react-js-loader";

const Overview = (props) => {

  const datas = props.profileData;
  const loader = props.loading;

  return (
    <div className="shadow card p-5 m-5">
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
        </div>
      </div>
      {loader ? (
        <div className="item">
          <Loader type="spinner-cub" bgColor={"#EA2467"} size={50} />
        </div>
      ) : (

        <div className='card-body pb-0'>

          {/* Full Name */}
          <div className='row mb-7'>
            <label className='col-3 col-md-3 col-sm-6 col-xs-6 fw-bold text-muted'>
              Full Name
            </label>
            <div className='col-lg-9 col-md-6 col-sm-6 col-xs-6'>
              <input
                type="text"
                className="form-control"
                placeholder="Account Name"
                value={datas ? `${datas.first_name} ${datas.last_name}` : "---"}
                disabled
              />
            </div>
          </div>

          {/* Contact Phone */}
          <div className='row mb-7'>
            <label className='col-3 col-md-3 col-sm-6 col-xs-6 fw-bold text-muted'>
              Contact Phone
            </label>
            <div className='col-lg-9 col-md-6 col-sm-6 col-xs-6'>
              <input
                type="text"
                className="form-control"
                placeholder="Account Name"
                value={datas ? `${datas.phone}` : "---"}
                disabled
              />
            </div>
          </div>

          {/* Email */}
          <div className='row mb-7'>
            <label className='col-3 col-md-3 col-sm-6 col-xs-6 fw-bold text-muted'>
              Email
            </label>
            <div className='col-lg-9 col-md-6 col-sm-6'>
              <input
                type="text"
                className="form-control"
                placeholder="Account Name"
                value={datas ? `${datas.email}` : "---"}
                disabled
              />
            </div>
          </div>

          {/* DOB */}
          <div className='row mb-7'>
            <label className='col-3 col-md-3 col-sm-6 col-xs-6 fw-bold text-muted'>
              Date of Birth
            </label>
            <div className='col-lg-9 col-md-6 col-sm-6'>
              <input
                type="text"
                className="form-control"
                placeholder="Account Name"
                value={datas ? `${datas.dob}` : "---"}
                disabled
              />
            </div>
          </div>

          {/* Company */}
          {datas.account_type == 2 && (
            <div className='row mb-7'>
              <label className='col-3 col-md-3 col-sm-6 col-xs-6 fw-bold text-muted'>Company</label>
              <div className='col-lg-9 col-md-6 col-sm-6'>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Account Name"
                  value={datas ? `${datas.company}` : "---"}
                  disabled
                />
              </div>
            </div>
          )}

          {/* EIN Number */}
          {datas.account_type == 2 && (
            <div className='row mb-7'>
              <label className='col-3 col-md-3 col-sm-6 col-xs-6 fw-bold text-muted'>EIN Number</label>
              <div className='col-lg-9 col-md-6 col-sm-6'>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Account Name"
                  value={datas ? `${datas.ein_number}` : "---"}
                  disabled
                />
              </div>
            </div>
          )}

          {/* Country */}
          <div className='row mb-7'>
            <label className='col-3 col-md-3 col-sm-6 col-xs-6 fw-bold text-muted'>
              Country
            </label>
            <div className='col-lg-9 col-md-6 col-sm-6'>
              <input
                type="text"
                className="form-control"
                placeholder="Account Name"
                value={datas.billingDetails ? (datas.billingDetails.country ? datas.billingDetails.country : "---") : "---"}
                disabled
              />
            </div>
          </div>

          {/* State */}
          <div className='row mb-7'>
            <label className='col-3 col-md-3 col-sm-6 col-xs-6 fw-bold text-muted'>
              State
            </label>
            <div className='col-lg-9 col-md-6 col-sm-6'>
              <input
                type="text"
                className="form-control"
                placeholder="Account Name"
                value={datas.billingDetails ? (datas.billingDetails.state ? datas.billingDetails.state : "---") : "---"}
                disabled
              />
            </div>
          </div>

        </div>
      )}
    </div>
  );
}

export { Overview };
