import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const showToastMessageSuccess = (message) => {
    toast.success(message, {
        position: toast.POSITION.TOP_CENTER
    });
};

export const showToastMessageError = (message) => {
    toast.error(message, {
        position: toast.POSITION.TOP_CENTER
    });
};

export const showToastMessageWarning = (message) => {
    toast.warning(message, {
        position: toast.POSITION.TOP_CENTER
    });
};