import React from 'react'
import CurrencyFormat from "react-currency-format";

const TableRow = ({val,index,OpenViewStatementModal,OpenStatementReceiptModal}) => {
  return (
    <tr key={index}>
        {/* <td>{val.date}</td> */}
        <td className='ps-9'>{val.statement_no}</td>
        <td className='ps-9'>{val.tracking_no}</td>
        <td className='ps-9'>{val.description}</td>
        <td className='ps-9'>{val.source_type == 1 ? (
          <button onClick={() => OpenViewStatementModal(val.items)} className='btn btn-sm btn-light'>Details</button>
        ) : (
          <span>---</span>
        )}</td>
        {val.transaction_type == 'Credit' ? (
        <td className='text-success'>
            <CurrencyFormat
            decimalScale={2}
            value={parseFloat(val.total).toFixed(2)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            />
            {val.payment_status == 1 ? (
            <span className='badge badge-primary fs-9'>PAID</span>
            ) : (
            <></>
            )}
        </td>
        ) : (
        <td className='text-danger'>
            <CurrencyFormat
            decimalScale={2}
            value={parseFloat(val.total).toFixed(2)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            />
        </td>
        )}
        <td>{val.due_date}</td>
        {/* <td>{checkFileExists(val)}</td> */}
        <td><button
          type='button'
          onClick={() => OpenStatementReceiptModal(val.statement_no,val.id,val.user_no)}
          class='btn btn-light btn-sm'
        >
          INVOICE
        </button></td>
    </tr>
  )
}

export default TableRow