import React from 'react';

const RequestLabels = () => {
    return (
        <div className='card shadow p-5'>
            <div>
                <h2>Overview</h2>
            </div>
            <div>
                <ul>
                    <li>
                        <p>Make sure to specify the details correctly in your shipping label request.</p>
                    </li>
                    <li>
                        <p>Please note as always that our free labels are only able to be issued within the contiguous States. Apologies to our Hawaiian and Alaskan customers.</p>
                    </li>
                    <li>
                        <p>Before requesting a shipping label, please check by the deals, if item does the come with a free shipping label. If it does not we will void the request.</p>
                    </li>
                    <li>
                        <p>Our packages are insured.</p>
                    </li>
                </ul>
            </div>
            <div className='mt-10'>
                <h2>Request a Shipping label</h2>
            </div>
            <div className="row">
                <div className="col-sm-4">
                    <div className='mt-5'>
                        <label className="">Box Length(inches)*</label>
                        <input
                            className='form-control'
                            type='text'
                            placeholder='Enter Box Length(inches)'
                        />
                    </div>
                    <div className='mt-5'>
                        <label className="">Box Height(inches)*</label>
                        <input
                            className='form-control'
                            type='text'
                            placeholder='Enter Box Height(inches)'
                        />
                    </div>
                    <div className='mt-5'>
                        <label className="">Number of Item(s) (per box)*</label>
                        <input
                            className='form-control'
                            type='text'
                            placeholder='Enter Number of Item(s) (per box)'
                        />
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className='mt-5'>
                        <label className="">Box Width(inches)*</label>
                        <input
                            className='form-control'
                            type='text'
                            placeholder='Enter Box Width(inches)'
                        />
                    </div>
                    <div className='mt-5'>
                        <label className="">Box Weight(lbs)*</label>
                        <input
                            className='form-control'
                            type='text'
                            placeholder='Enter Box Weight(lbs)'
                        />
                    </div>
                    <div className='mt-5'>
                        <label className="">Number of boxes(s) (boxes must be identical)*</label>
                        <input
                            className='form-control'
                            type='text'
                            placeholder='Enter Number of boxes(s)'
                        />
                    </div>
                </div>
                <div className="col-sm-4">
                    <h1>Hello</h1>
                </div>
            </div>
            <div className="third_section mt-10">
                <div className="row">
                    <div className="col-sm-3">
                        <div>
                            <h2>Items to Ship Per-Box</h2>
                        </div>
                        <div style={{ minWidth: '100%', minHeight: '150px', border: '1px solid #A1A5B7' }}>

                        </div>
                    </div>
                    <div className="col-sm-9">
                        <div className='d-flex justify-content-between'>
                            <div>
                                <h2>Accepted Items</h2>
                            </div>
                            <div className='d-flex'>
                                <input
                                    className='form-control'
                                    type='text'
                                    placeholder='Search Itetm by UTC or item ID'
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <h3>Items 1</h3>
                            </div>
                            <div className="col-sm-4">
                                <h3>Items 2</h3>
                            </div>
                            <div className="col-sm-4">
                                <h3>Items 3</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fourth_section mt-10">
                <div className=''>
                    <h2>Insurance</h2>
                </div>
                <div>
                    <ul>
                        <li>
                            <p>We offer shipping insurance to every package shipped to us.</p>
                        </li>
                        <li>
                            <p>On the rare occasion a package is lost, we will reimburse the full value we promised to you for the items shipped.</p>
                        </li>
                        <li>
                            <p>Please calculate the value of box based on our reimbursement value not purchasing cost.</p>
                        </li>
                        <li>
                            <p>The specified cost is for each box shipped, not the total cost.</p>
                        </li>
                    </ul>
                </div>
                <div>
                    <label className="">Amount To Insure*</label>
                </div>
            </div>
            <div className="fifth_section mt-10">
                <div className=''>
                    <h2>Ship Form</h2>
                </div>
                <div className="row">
                    <div className="col-sm-3">
                        <div className='mt-5'>
                            <label className="">First Name*</label>
                            <input
                                className='form-control'
                                type='text'
                                placeholder='Enter First Name'
                            />
                        </div>
                        <div className='mt-5'>
                            <label className="">Address*</label>
                            <input
                                className='form-control'
                                type='text'
                                placeholder='Enter Address'
                            />
                        </div>
                        <div className='mt-5'>
                            <label className="">State*</label>
                            <select className='form-select'>
                                <option>Texas</option>
                                <option>California</option>
                                <option>Florida</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className='mt-5'>
                            <label className="">Last Name*</label>
                            <input
                                className='form-control'
                                type='text'
                                placeholder='Enter Last Name'
                            />
                        </div>
                        <div className='mt-5'>
                            <label className="">Address 2</label>
                            <input
                                className='form-control'
                                type='text'
                                placeholder='Enter Address 2'
                            />
                        </div>
                        <div className='mt-5'>
                            <label className="">City*</label>
                            <input
                                className='form-control'
                                type='text'
                                placeholder='Enter City'
                            />
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className='mt-5'>
                            <label className="">Email*</label>
                            <input
                                className='form-control'
                                type='text'
                                placeholder='Enter Last Name'
                            />
                        </div>
                        <div className='mt-5'>
                            <label className="">Country*</label>
                            <select className='form-select' disabled>
                                <option>United States</option>
                            </select>
                        </div>
                        <div className='mt-5'>
                            <label className="">Apt</label>
                            <input
                                className='form-control'
                                type='text'
                                placeholder='Enter APT'
                            />
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className='mt-5'>
                            <label className="">Cell Phone</label>
                            <input
                                className='form-control'
                                type='text'
                                placeholder='Enter Cell Phone'
                            />
                        </div>
                        <div className='mt-5'>
                            <label className="">Work Phone</label>
                            <input
                                className='form-control'
                                type='text'
                                placeholder='Enter Work Phone'
                            />
                        </div>
                        <div className='mt-5'>
                            <label className="">Zipcode*</label>
                            <input
                                className='form-control'
                                type='text'
                                placeholder='Enter Zipcode'
                            />
                        </div>
                    </div>
                </div>

                <div className='footer_btn2 mt-10'>
                    <button><i className="fa-solid fa-floppy-disk"></i>&nbsp; Save</button>
                </div>
            </div>
        </div>
    );
}

export default RequestLabels;
