import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userProfile: null,
}

export const webData = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    UserProfileShow: (state, action) => {
      state.userProfile = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { UserProfileShow } = webData.actions

export default webData.reducer