/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import axios from 'axios';
import Loader from "react-js-loader";
import { toAbsoluteUrl } from '../../../../../../_metronic_front/helpers';
import { AddCard } from './AddCard';
import { EditCard } from './EditCard';
import { showToastMessageSuccess, showToastMessageError } from '../../../../../modules/Toast-Message/TosatMessage';

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
    const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    return JSON.parse(data);
};

const NewPaymentCards = () =>{

    const APP_URL = process.env.REACT_APP_URL;

    const [showAddCardModal, setShowAddCardModal] = useState(false);
    const [EditCardId,setEditCardId] = useState();

    const [paymentCardsData, setPaymentCardsData] = useState([]);
    const [loading, setLoading] = useState(false);


    const apiToken = getLocalData().api_token;

    const API_URL = process.env.REACT_APP_API_URL;
    const PAYMENT_CARDS = `${API_URL}/user/payment-cards`;

    const getUserCards = async () => {
        setLoading(true);
        const config = {
            headers: { Authorization: `Bearer ${apiToken}` }
        };
        await axios.get(PAYMENT_CARDS, config)
            .then((response) => {
                setPaymentCardsData(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };

          

    useEffect(() => {
        getUserCards();
    }, []);


    return(
        <>
        <h5>Payment Cards Page</h5>
        </>
    )
}

const PaymentCards = () => {

    const APP_URL = process.env.REACT_APP_URL;

    const [showAddCardModal, setShowAddCardModal] = useState(false);
    const [showEditCardModal, setShowEditCardModal] = useState(false);
    const [editCardData,setEditCardData] = useState([
        {
            item_id:'',
            name_on_card : '',
            card_number:'',
            expiry_month : '',
            expiry_year : '',
            cvv : '',
            primary_status : '',
            status:''
        }
    ]);
    const [editMyCard,setEditMyCard] = useState(false);

    const [paymentCardsData, setPaymentCardsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refresh,setRefresh] = useState(false);

    const [deleteCardId,setDeleteCardId] = useState();


    const apiToken = getLocalData().api_token;

    const API_URL = process.env.REACT_APP_API_URL;
    const PAYMENT_CARDS = `${API_URL}/user/payment-cards`;
    const PAYMENT_CARD_DELETE = `${API_URL}/user/payment-card/delete`;

    const getUserCards = async () => {
        setLoading(true);
        const config = {
            headers: { Authorization: `Bearer ${apiToken}` }
        };
        await axios.get(PAYMENT_CARDS, config)
            .then((response) => {
                setPaymentCardsData(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };

    const DeleteUserCard = async (myid) => {
        setLoading(true);
        const config = {
            headers: { Authorization: `Bearer ${apiToken}` }
        };
        await axios.post(PAYMENT_CARD_DELETE, {card_id:myid} , config)
            .then((response) => {
                showToastMessageSuccess(response.data.message);
                setRefresh(true)
            })
            .catch((error) => {
                console.error(error);
                setRefresh(true)
            });
    };

          

    useEffect(() => {
            getUserCards();
            setRefresh(false)
    }, [refresh]);

    const CardNameTruncate = (item) =>{
        if(item.card_provider == 1){
            return (
            <h5 className='fs-6'>Visa****{item.card_number.substr(-4)}</h5>
            )
        }
        if(item.card_provider == 2){
            return (
            <h5 className='fs-6'>Mastercard****{item.card_number.substr(-4)}</h5>
            )
        }
        if(item.card_provider == 3){
            return (
            <h5 className='fs-6'>Amex****{item.card_number.substr(-4)}</h5>
            )
        }
        if(item.card_provider == 4){
            return (
            <h5 className='fs-6'>Discover****{item.card_number.substr(-4)}</h5>
            )
        }
    }

    const getCardProvider = (item) =>{
        if(item.card_provider == 1){
            return (
                <img src={toAbsoluteUrl('/media/cards/visa.png')} alt="" className='' width="85" />
            )
        }
        if(item.card_provider == 2){
            return (
                <img src={toAbsoluteUrl('/media/cards/mastercard.png')} alt="" className='' width="75" />
            )
        }
        if(item.card_provider == 3){
            return (
                <img src={toAbsoluteUrl('/media/cards/amex.png')} alt="" className='' width="85" />
            )
        }
        if(item.card_provider == 4){
            return (
                <img src={toAbsoluteUrl('/media/cards/discover.png')} alt="" className='' width="85" />
            )
        }
    }


    const setEditCardItems = (item)=>{
        setEditCardData({
                item_id:item.id,
                card_number: item.card_number,
                name_on_card:item.name_on_card,
                expiry_month:item.expiry_month,
                expiry_year:item.expiry_year,
                cvv:item.cvv,
                primary_status:item.primary_status,
                status:item.status,
        })
        setShowEditCardModal(true)
    }

    const SetCardDelete = (id) =>{
        // setDeleteCardId(id);
        DeleteUserCard(id);
    }

    return (
        <div>
        <AddCard
        show={showAddCardModal}
        handleClose={() => {
          setShowAddCardModal(false);
        }}
        SuccessRefresh={()=>{
            setRefresh(true);
        }}
      />
        <EditCard
        show={showEditCardModal}
        handleClose={() => {
          setShowEditCardModal(false);
        }}
        SuccessRefresh={()=>{
            setRefresh(true);
        }}
        EditCardData={editCardData.item_id != '' ? editCardData : ''}
        Edit={editMyCard}
      />
        
        <div className='cursor-pointer'>
            <div className='m-5 mb-0'>
                <div className="d-flex flex-column flex-column-fluid">
                    <div id="kt_app_content" className="app-content flex-column-fluid">
                        <div className="card shadow mb-5">
                            <div className="card-body py-10">
                                <h2 className="mb-9">My Cards</h2>
                                <div className="row mb-10">
                                    {/* USER CARDS */}
                                {paymentCardsData.map((item,index)=>(

                                    
                                <div className="col-lg-6 col-md-12 mb-3" key={index}>
                                    <div className="card shadow px-1">
                                        <div className="card-body ps-2">
                                            <div className="d-flex mb-3">
                                                <span className='fs-6 fw-bold'>{item.name_on_card}</span>
                                                {item.primary_status == 1 && (
                                                    <span className='badge badge-success ms-2'>primary</span>
                                                )}
                                                
                                            </div>
                                            <div className="row">
                                                <div className="col-7 d-flex">
                                                    <div className="img-wrap ms-2 me-3">
                                                        {getCardProvider(item)}
                                                    </div>
                                                    <div className="detail-wrap">
                                                        {CardNameTruncate(item)}
                                                        <h6 className="text-gray-500 fs-8">Card Expires at {item.expiry_month}/{item.expiry_year.substr(-2)}</h6>
                                                    </div>
                                                </div>
                                                <div className="col-5">
                                                    <button onClick={()=> setEditCardItems(item)} className='btn btn-light btn-sm me-2'>Edit</button>
                                                    <button onClick={()=> SetCardDelete(item.id)} className='btn btn-light btn-sm'>Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                ))
                            }
                                {/* NEW CARD */}
                                <div className="col-lg-6 col-md-12">
                                    <div className="card shadow px-1 py-4 " style={{backgroundColor:'#B1E1FB'}}>
                                        <div className="card-body px-3">
                                            <div className="row">
                                                <div className="col-9 d-flex">
                                                    <div className="detail-wrap">
                                                        <h5 className='fs-4'>Add new payment cards</h5>
                                                        <h6 className="text-gray-800 fs-6">Add your card to make transactions easier..</h6>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <button  onClick={()=> setShowAddCardModal(true)} className='btn btn-primary btn-sm me-2'>Add Card</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    
                                </div>

                            </div>
                        </div>
                        {/* List of Transctions , view backup */}
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export { PaymentCards };
