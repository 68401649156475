/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'react-bootstrap';
import { KTIcon } from '../../../../../../_metronic/helpers';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToastMessageSuccess, showToastMessageError } from '../../../../../modules/Toast-Message/TosatMessage';

const modalsRoot = document.getElementById('root-modals') || document.body;

const StatementDetail = ({ show, handleClose, items }) => {
    
    // const [withdrawStatements,setWithdrawStatements] = useState([]);
    const [loading,setLoading] = useState(true);


    const setModalClose = ()=>{
        handleClose();
        // setWithdrawStatements([]);
        setLoading(true);
    }

    const getDueDate = (date) =>{
        const mydate = new Date(date);
        const dueDate = addDays(mydate, 15);
        return formatDate(dueDate);
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString); // Convert the ISO string to a Date object
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
      };

    const addDays = (date, days) => {
        const result = new Date(date); 
        result.setDate(result.getDate() + days);
        return result;
      };
 
    // useEffect(() => {
    //     if (show && withdrawId) {
    //         getWithdrawDetail();
    //     }
    //     setErrorMsg("");
    // }, [show, withdrawId]);

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered modal-lg'
            show={show}
            onHide={setModalClose}
            backdrop={true}
        >
            <ToastContainer />
            <div className='modal-header pb-0'>
                <h2>Statements Items</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={setModalClose}>
                    <KTIcon className='fs-1' iconName='cross' />
                </div>
            </div>

            <div className='card'>
                <div className='card-body pt-0'>
                    {/* {!loading ? (
                        <> */}
                        {items?.map((val,index)=>(
                            <>
                            <div className="item">
                                <div className="row">
                                    <div className="col-3">
                                    <img src={'https://erp.makemoneyormiles.com/storage/deals/'+val.deal_image} height="100" alt="" />
                                    </div>
                                    <div className="col-9">
                                    <b>{val.deal_name}</b><br/>
                                    Deal No: {val.deal_no} | Model: {val.model} | UPC: {val.upc}<br/>
                                    Price: ${val.price} | Comm: ${val.commission} | Qty: {val.qty} | Total: ${val.total}
                                    </div>
                                </div>
                            </div>
                            <div className="border-bottom my-3"></div>
                            </>
                        ))
                        }
                        {/* </>
                    ) : (
                        <div className='text-center'>
                            <span>Loading....</span>
                        </div>
                    )} */}
                        
                    
                </div>
            </div>
        </Modal>,
        modalsRoot
    );
}

export { StatementDetail };
