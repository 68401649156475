import { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToastMessageSuccess, showToastMessageError } from '../../../../../modules/Toast-Message/TosatMessage';
import Loader from "react-js-loader";

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
    const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    return JSON.parse(data);
}

const Settings = ({ profileData, getProfileDetails }) => {

    const datas = profileData;

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [cellPhone, setCellPhone] = useState("");
    const [dob, setDob] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [einNumber, seteinNumber] = useState("");
    const [address, setAddress] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [countryId, setCountryId] = useState(0);
    const [stateId, setStateId] = useState(0);
    const [countryData, setCountryData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [isSubmitable, setIsSubmitable] = useState(true);
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [errorMsgs, setErrorMsgs] = useState({
        firstNameErr: '',
        lastNameErr: '',
        emailErr: '',
        cellPhoneErr: '',
        dobErr: '',
        companyNameErr: '',
        einNumberErr: '',
        addressErr: '',
        cityErr: '',
        zipcodeErr: '',
        countryIdErr: '',
        stateIdErr: '',
    });

    const apiToken = getLocalData().api_token;
    const userId = getLocalData().id;
    const API_URL = process.env.REACT_APP_API_URL;
    const countryURL = `${API_URL}/country`;
    const stateURL = `${API_URL}/state`;
    const PROFILE_UPDATE = `${API_URL}/user/profile/update`;

    const getCountryList = async () => {
        const config = {
            headers: { Authorization: `Bearer ${apiToken}` }
        };
        await axios.get(countryURL, config)
            .then((response) => {
                setCountryData(response.data.data);
                setCountryId(response.data.data[0].id);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getStateList = async () => {
        await axios.get(stateURL, {
            params: {
                country_id: countryId
            }
        })
            .then((response) => {
                setStateData(response.data.data);
                // setStateId(response.data.data[0].id);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const updateProfile = async () => {
        setErrorMsgs((prev) => ({
            ...prev,
            firstNameErr: "",
            lastNameErr: "",
            emailErr: "",
            cellPhoneErr: "",
            companyNameErr: "",
            einNumberErr: "",
            dobErr: "",
            addressErr: "",
            cityErr: "",
            zipcodeErr: "",
            countryIdErr: "",
            stateIdErr: "",
        }));
        const data = {
            user_id: userId,
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone: cellPhone,
            company: companyName,
            ein_number: einNumber,
            dob: dob,
            address1: address,
            address2: address2,
            city: city,
            zipcode: zipcode,
            country_id: countryId,
            state_id: stateId,
        }
        if (!firstName) {
            setErrorMsgs((prev) => ({
                ...prev,
                firstNameErr: "enter first name"
            }));
        } else if (!lastName) {
            setErrorMsgs((prev) => ({
                ...prev,
                lastNameErr: "enter last name",
            }));
        } else if (!email) {
            setErrorMsgs((prev) => ({
                ...prev,
                emailErr: "enter email"
            }));
        } else if (!cellPhone) {
            setErrorMsgs((prev) => ({
                ...prev,
                cellPhoneErr: "enter phone number"
            }));
        } else if (cellPhone.length !== 10) {
            setErrorMsgs((prev) => ({
                ...prev,
                cellPhoneErr: "10 digits required"
            }));
        } else if (datas.account_type == 2 && !companyName) {
            setErrorMsgs((prev) => ({
                ...prev,
                companyNameErr: "enter company name",
            }));
        } else if (datas.account_type == 2 && !einNumber) {
            setErrorMsgs((prev) => ({
                ...prev,
                einNumberErr: "enter ein-number",
            }));
        } else if (datas.account_type == 2 && einNumber.length !== 9) {
            setErrorMsgs((prev) => ({
                ...prev,
                einNumberErr: "9 digits required",
            }));
        } else if (!dob) {
            setErrorMsgs((prev) => ({
                ...prev,
                dobErr: "enter date  of birth",
            }));
        } else if (!isSubmitable) {
            setErrorMsgs((prev) => ({
                ...prev,
                dobErr: "age must be 21 years or older",
            }));
        } else if (!address) {
            setErrorMsgs((prev) => ({
                ...prev,
                addressErr: "enter street address",
            }));
        } else if (!city) {
            setErrorMsgs((prev) => ({
                ...prev,
                cityErr: "enter city name",
            }));
        } else if (!zipcode) {
            setErrorMsgs((prev) => ({
                ...prev,
                zipcodeErr: "enter zipcode",
            }));
        } else if (zipcode.toString().length !== 5) {
            setErrorMsgs((prev) => ({
                ...prev,
                zipcodeErr: "enter zipcode with 5 letters",
            }));
        } else if (countryId == 0) {
            setErrorMsgs((prev) => ({
                ...prev,
                countryIdErr: "select country",
            }));
        } else if (stateId == 0) {
            setErrorMsgs((prev) => ({
                ...prev,
                stateIdErr: "select state",
            }));
        } else {
            setLoading(true);
            setBtnLoading(true);
            await axios.post(PROFILE_UPDATE, data)
                .then((response) => {
                    showToastMessageSuccess(response.data.message);
                    getProfileDetails();
                    setLoading(false);
                    setBtnLoading(false);
                    setEditMode(false);
                })
                .catch((error) => {
                    console.error(error);
                    showToastMessageError(error?.response?.data?.message);
                    setLoading(false);
                    setBtnLoading(false);
                });
        }
    };

    const calculateAge = (dob) => {
        const today = new Date();
        const birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    const handleDateChange = (e) => {
        const selectedDate = e.target.value;
        const dateObject = new Date(selectedDate);

        if (isNaN(dateObject.getTime())) {
            setIsSubmitable(false);
            return;
        }

        const age = calculateAge(dateObject);

        if (age < 21) {
            setIsSubmitable(false);
        } else {
            setIsSubmitable(true);
        }

        setDob(selectedDate);
    };

    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 21);
    const minDate = currentDate.toISOString().split("T")[0];

    useEffect(() => {
        setFirstName(datas.first_name);
        setLastName(datas.last_name);
        setEmail(datas.email);
        setCellPhone(datas.phone);
        setCompanyName(datas.company);
        seteinNumber(datas.ein_number);
        setDob(datas.dob);
    }, [datas]);

    useEffect(() => {
        if (datas.billingDetails) {
            setAddress(datas.billingDetails.address1);
            setAddress2(datas.billingDetails.address2);
            setCity(datas.billingDetails.city);
            setZipcode(datas.billingDetails.zipcode);
            setCountryId(datas.billingDetails.country_id);
            setStateId(datas.billingDetails.state_id);
        }
    }, [datas.billingDetails]);

    useEffect(() => {
        getStateList();
    }, [countryId]);

    useEffect(() => {
        getCountryList();
    }, []);

    useEffect(() => {
        if (!editMode) {
            setErrorMsgs((prev) => ({
                ...prev,
                firstNameErr: '',
                lastNameErr: '',
                emailErr: '',
                cellPhoneErr: '',
                dobErr: '',
                companyNameErr: '',
                einNumberErr: '',
                addressErr: '',
                cityErr: '',
                zipcodeErr: '',
                countryIdErr: '',
                stateIdErr: '',
            }));
        }
    }, [editMode]);

    return (
        <div className="shadow card p-5 m-5">
            <ToastContainer />
            <div
                className='card-header cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_profile_details_view'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Update Details</h3>
                </div>
            </div>

            <div id='kt_profile_details_view'>
                <div className='card-body pb-0'>
                    {loading ? (
                        <div className="item">
                            <Loader type="spinner-cub" bgColor={"#EA2467"} size={50} />
                        </div>
                    ) : (
                        <div className="row">

                            <div className="col-12 col-md-6 col-lg-12 col-xl-6">

                                {/* First Name */}
                                <div className='row mb-7'>
                                    <label className='col-lg-2 fw-bold text-muted required'>First Name</label>
                                    <div className='col-lg-7'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            placeholder='Enter First Name'
                                            disabled={!editMode}
                                        />
                                        {errorMsgs.firstNameErr && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-2px" }}>{errorMsgs.firstNameErr}</p>}
                                    </div>
                                </div>

                                {/* Last Name */}
                                <div className='row mb-7'>
                                    <label className='col-lg-2 fw-bold text-muted required'>Last Name</label>
                                    <div className='col-lg-7'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            placeholder='Enter Last Name'
                                            disabled={!editMode}
                                        />
                                        {errorMsgs.lastNameErr && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-2px" }}>{errorMsgs.lastNameErr}</p>}
                                    </div>
                                </div>

                                {/* Email */}
                                <div className='row mb-7'>
                                    <label className='col-lg-2 fw-bold text-muted required'>Email</label>
                                    <div className='col-lg-7'>
                                        <input
                                            type='email'
                                            className='form-control'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder='Enter Email'
                                            disabled={!editMode}
                                        />
                                        {errorMsgs.emailErr && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-2px" }}>{errorMsgs.emailErr}</p>}
                                    </div>
                                </div>

                                {/* Phone */}
                                <div className='row mb-7'>
                                    <label className='col-lg-2 fw-bold text-muted required'>Phone</label>
                                    <div className='col-lg-7'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            value={cellPhone}
                                            onChange={(e) => setCellPhone(e.target.value.replace(/[^0-9]/g, ''))}
                                            placeholder='Enter Phone number'
                                            disabled={!editMode}
                                        />
                                        {errorMsgs.cellPhoneErr && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-2px" }}>{errorMsgs.cellPhoneErr}</p>}
                                    </div>
                                </div>

                                {/* Company */}
                                {datas.account_type == 2 && (
                                    <div className='row mb-7'>
                                        <label className='col-lg-2 fw-bold text-muted required'>Company</label>
                                        <div className='col-lg-7'>
                                            <input
                                                type='text'
                                                className='form-control'
                                                value={companyName}
                                                onChange={(e) => setCompanyName(e.target.value)}
                                                placeholder='Enter Company name'
                                                disabled={!editMode}
                                            />
                                            {errorMsgs.companyNameErr && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-2px" }}>{errorMsgs.companyNameErr}</p>}
                                        </div>
                                    </div>
                                )}

                                {/* EIN Number */}
                                {datas.account_type == 2 && (
                                    <div className='row mb-7'>
                                        <label className='col-lg-2 fw-bold text-muted required'>EIN Number</label>
                                        <div className='col-lg-7'>
                                            <input
                                                type='text'
                                                className='form-control'
                                                value={einNumber}
                                                onChange={(e) => seteinNumber(e.target.value)}
                                                placeholder='Enter EIN Number'
                                                disabled={!editMode}
                                            />
                                            {errorMsgs.einNumberErr && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-2px" }}>{errorMsgs.einNumberErr}</p>}
                                        </div>
                                    </div>
                                )}

                                {/* DOB */}
                                <div className='row mb-7'>
                                    <label className='col-lg-2 fw-bold text-muted required'>Date of Birth</label>
                                    <div className='col-lg-7'>
                                        <input
                                            type='date'
                                            className='form-control'
                                            value={dob}
                                            onChange={handleDateChange}
                                            max={minDate}
                                            disabled={!editMode}
                                        />
                                        {errorMsgs.dobErr && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-2px" }}>{errorMsgs.dobErr}</p>}
                                    </div>
                                </div>

                            </div>
                            <div className="col-12 col-md-6 col-lg-12 col-xl-6">

                                {/* Street Address */}
                                <div className='row mb-7'>
                                    <label className='col-lg-2 fw-bold text-muted required'>Street Address</label>
                                    <div className='col-lg-7'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                            placeholder='Enter Street Address'
                                            disabled={!editMode}
                                        />
                                        {errorMsgs.addressErr && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-2px" }}>{errorMsgs.addressErr}</p>}
                                    </div>
                                </div>

                                {/* Floor or Building */}
                                <div className='row mb-7'>
                                    <label className='col-lg-2 fw-bold text-muted'>Floor or Building</label>
                                    <div className='col-lg-7'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            value={address2}
                                            onChange={(e) => setAddress2(e.target.value)}
                                            placeholder='Enter Floor or Building'
                                            disabled={!editMode}
                                        />
                                    </div>
                                </div>

                                {/* City */}
                                <div className='row mb-7'>
                                    <label className='col-lg-2 fw-bold text-muted required'>City</label>
                                    <div className='col-lg-7'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            value={city}
                                            onChange={(e) => setCity(e.target.value)}
                                            placeholder='Enter City'
                                            disabled={!editMode}
                                        />
                                        {errorMsgs.cityErr && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-2px" }}>{errorMsgs.cityErr}</p>}
                                    </div>
                                </div>

                                {/* Zipcode */}
                                <div className='row mb-7'>
                                    <label className='col-lg-2 fw-bold text-muted required'>Zipcode</label>
                                    <div className='col-lg-7'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            value={zipcode}
                                            onChange={(e) => setZipcode(e.target.value.replace(/[^0-9]/g, ''))}
                                            placeholder='Enter Zipcode'
                                            disabled={!editMode}
                                        />
                                        {errorMsgs.zipcodeErr && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-2px" }}>{errorMsgs.zipcodeErr}</p>}
                                    </div>
                                </div>

                                {/* Country */}
                                <div className='row mb-7'>
                                    <label className='col-lg-2 fw-bold text-muted required'>Country</label>
                                    <div className='col-lg-7'>
                                        <select
                                            className='form-select'
                                            value={countryId}
                                            onChange={(e) => setCountryId(e.target.value)}
                                            disabled={!editMode}
                                        >
                                            <option value={0}>-- Select --</option>
                                            {countryData.map((val, index) => {
                                                return (
                                                    <option key={index} value={val.id}>{val.name}</option>
                                                )
                                            })}
                                        </select>
                                        {errorMsgs.countryIdErr && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-2px" }}>{errorMsgs.countryIdErr}</p>}
                                    </div>
                                </div>

                                {/* State */}
                                <div className='row mb-7'>
                                    <label className='col-lg-2 fw-bold text-muted required'>State</label>
                                    <div className='col-lg-7'>
                                        <select
                                            className='form-select'
                                            value={stateId}
                                            disabled={countryId == 0 || !editMode}
                                            onChange={(e) => setStateId(e.target.value)}
                                        >
                                            <option value={0}>-- Select --</option>
                                            {stateData.map((val, index) => {
                                                return (
                                                    <option key={index} value={val.id}>{val.name} ({val.short_name})</option>
                                                )
                                            })}
                                        </select>
                                        {errorMsgs.stateIdErr && <p className='position-absolute text-danger fw-semibold' style={{ marginTop: "-2px" }}>{errorMsgs.stateIdErr}</p>}
                                    </div>
                                </div>

                            </div>
                        </div>
                    )}
                </div>
                <div className='footer_btn2'>
                    {!editMode && <button onClick={() => setEditMode(true)}><i className="fa-solid fa-pen"></i>&nbsp; Edit</button>}
                    {btnLoading && <button>Wait...&nbsp; <span className='spinner-border spinner-border-sm align-middle ms-2'></span></button>}
                    {(editMode && !btnLoading) && <button onClick={updateProfile}><i className="fa-solid fa-floppy-disk"></i>&nbsp; Save</button>}&nbsp;&nbsp;&nbsp;
                    {(editMode && !btnLoading) && <button onClick={() => setEditMode(false)}><i className="fa-solid fa-xmark"></i>&nbsp; Cancle</button>}
                </div>
            </div>
        </div>
    );
}

export default Settings;
