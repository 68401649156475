/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'react-bootstrap';
import { KTIcon } from '../../../_metronic/helpers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToastMessageSuccess, showToastMessageError , showToastMessageWarning } from '../../modules/Toast-Message/TosatMessage';
import axios from 'axios';

const modalsRoot = document.getElementById('root-modals') || document.body;

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
    const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    return JSON.parse(data);
}

const ImportTracking = ({ show, handleClose, getTrackingCommit ,refreshCommitments }) => {



    const apiToken = getLocalData().api_token;
    const [commitData, setCommitData] = useState([]);
    const [selectedCommit, setSelectedCommit] = useState([]);
    const [selectedDetailData, setSelectedDetailData] = useState([]);
    const [totalCommitQty, setTotalCommitQty] = useState([]);
    const [selectedCommitQty, setSelectedCommitQty] = useState([]);
    const [trackingNumbers, setTrackingNumbers] = useState([]);
    const [trackingData, setTrackingData] = useState([
        {
            trackingNumber: '',
            otp: '',
            commitData: [
                {
                    commit_id: '',
                    commit_qty: '',
                }
            ]
        }
    ]);
    const [trackingErr, setTrackingErr] = useState([
        {
            trackingNumberErr: '',
            otpErr: '',
            commitDataErr: [
                {
                    commit_idErr: '',
                    commit_qtyErr: '',
                }
            ]
        }
    ]);

    const API_URL = process.env.REACT_APP_API_URL;
    const GET_COMMIT_URL = `${API_URL}/my-commitments`;
    const IMPORT_COMMIT = `${API_URL}/import-commit`;

    const getCommitments = async () => {
        const config = {
            headers: { Authorization: `Bearer ${apiToken}` }
        };
        await axios.get(GET_COMMIT_URL, {
            params: {
                status: 1
            }
        }, config)
            .then((response) => {
                const filteredCommitments = response.data.data.filter(item => item.status !== 'DEAL_EXPIRED');
                setCommitData(filteredCommitments);
                filteredCommitments.map((val, index) => {
                    setTotalCommitQty((prev) => {
                        const newData = [...prev];
                        newData[index] = {
                            ...newData[index],
                            commit_id: val.commit_code,
                            commit_qty: val.commited_qty,
                        }
                        return newData;
                    });
                    setSelectedCommitQty((prev) => {
                        const newData = [...prev];
                        newData[index] = {
                            ...newData[index],
                            commit_id: val.commit_code,
                            commit_qty: '',
                        }
                        return newData;
                    });
                });
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const addTrackingFields = () => {
        setTrackingData([...trackingData, {
            trackingNumber: '',
            otp: '',
            commitData: [
                {
                    commit_id: '',
                    commit_qty: '',
                }
            ]
        }]);
        setTrackingErr([...trackingErr, {
            trackingNumberErr: '',
            otpErr: '',
            commitDataErr: [
                {
                    commit_idErr: '',
                    commit_qtyErr: '',
                }
            ]
        }]);
    }

    const removeTrackingFields = (ind) => {
        setTrackingData((prevTrackingData) => {
            const updatedTrackingData = prevTrackingData.filter((_, index) => index !== ind);
            return updatedTrackingData;
        });
        setSelectedCommit((prev) => {
            prev.splice(ind, 1);
            return prev;
        });
        setTrackingErr((prevData) => {
            const updateTrackingErr = prevData.filter((_, index) => index !== ind);
            return updateTrackingErr;
        });
    };

    const addCommitFields = (index) => {
        setTrackingData((prevTrackingData) => {
            const updatedTrackingData = [...prevTrackingData];
            const newCommit = {
                commit_id: '',
                commit_qty: '',
            };
            updatedTrackingData[index].commitData.push(newCommit);
            return updatedTrackingData;
        });
        setTrackingErr((prevData) => {
            const updateTrackingErr = [...prevData];
            const newCommitErr = {
                commit_idErr: '',
                commit_qtyErr: '',
            };
            updateTrackingErr[index].commitDataErr.push(newCommitErr);
            return updateTrackingErr;
        })
    };

    const removeCommitFields = (trackingIndex, commitIndex2) => {
        setTrackingData((prevTrackingData) => {
            const updatedTrackingData = prevTrackingData.map((tracking, index) => {
                if (index === trackingIndex) {
                    const updatedCommitData = tracking.commitData.filter((_, commitIndex) => commitIndex !== commitIndex2);
                    return { ...tracking, commitData: updatedCommitData };
                }
                return tracking;
            });
            return updatedTrackingData;
        });
        setSelectedCommit((prev) => {
            const updateSelected = prev.map((item, index) => {
                if (index === trackingIndex) {
                    item.splice(commitIndex2, 1);
                    return item
                }
                return item;
            });
            return updateSelected;
        });
        setTrackingErr((prevTrackingData) => {
            const updateTrackingErr = prevTrackingData.map((tracking, index) => {
                if (index === trackingIndex) {
                    const updateCommitErr = tracking.commitDataErr.filter((_, commitIndex) => commitIndex !== commitIndex2);
                    return { ...tracking, commitDataErr: updateCommitErr };
                }
                return tracking;
            });
            return updateTrackingErr;
        })
    };

    const handleChangeTrackingNumber = (e, index) => {
        const { value } = e.target;
        setTrackingData((prevTrackingData) => {
            const updatedTrackingData = [...prevTrackingData];
            updatedTrackingData[index] = {
                ...updatedTrackingData[index],
                trackingNumber: value,
            };
            setTrackingNumbers([...trackingNumbers,value]);
            return updatedTrackingData;
        });
        setTrackingErr((prevTrackingErr) => {
            const updatedTrackingErr = [...prevTrackingErr];
            updatedTrackingErr[index].trackingNumberErr = value ? '' : 'enter tracking number';
            // updatedTrackingErr[index].trackingNumberErr = /^[A-Za-z]{3}/.test(value) ? '' : 'enter correct tracking number';
            updatedTrackingErr[index].trackingNumberErr = value.toString().length < 4 ? 'enter tracking number with length 4 or more' : '';
            const duplicateExist = trackingNumbers.includes(value);
            updatedTrackingErr[index].trackingNumberErr = duplicateExist ? 'Tracking Already Exist' : '';
            return updatedTrackingErr;
        });
    };

    const handleChangeOtp = (e, index) => {
        const { value } = e.target;
        setTrackingData((prevOtp) => {
            const updatedOtp = [...prevOtp];
            updatedOtp[index] = {
                ...updatedOtp[index],
                otp: value.replace(/[^0-9]/g, ''),
            };
            return updatedOtp;
        });
    };

    const handleChangeCommitID = (e, index, ind2) => {
        const { value } = e.target;
        console.log(e.target.value)
        setTrackingData((prevTrackingData) => {
            const updatedTrackingData = [...prevTrackingData];
            updatedTrackingData[index].commitData[ind2] = {
                ...updatedTrackingData[index].commitData[ind2],
                commit_id: value,
            };
            return updatedTrackingData;
        });
        setSelectedCommit((prevSelectedCommit) => {
            const updatedSelectedCommit = [...prevSelectedCommit];
            if (!updatedSelectedCommit[index]) {
                updatedSelectedCommit[index] = [];
            }
            updatedSelectedCommit[index][ind2] = parseInt(value);
            return updatedSelectedCommit;
        });
        
        // setSelectedCommitQty((prev) => {
        //     const updatedData = [...prev];
        //     updatedData[ind2] = {
        //         ...updatedData[ind2],
        //         commit_id: parseInt(value),
        //     }
        //     return updatedData;

        // prev.map((val, ind) => {
        //     if (val)
        // })
        // });
        setTrackingErr((prevTrackingErr) => {
            const updatedTrackingErr = [...prevTrackingErr];
            updatedTrackingErr[index].commitDataErr[ind2].commit_idErr = value ? '' : 'select commit id';
            return updatedTrackingErr;
        });
    };

    const handleChangeCommitQty = (e, index, ind2, commitID) => {
        const { value } = e.target;
        const newQuantity = value ? parseInt(value.replace(/[^0-9]/g, '')) : 0;
        setTrackingData((prevTrackingData) => {
            const updatedTrackingData = [...prevTrackingData];
            updatedTrackingData[index].commitData[ind2] = {
                ...updatedTrackingData[index].commitData[ind2],
                commit_qty: parseInt(value.replace(/[^0-9]/g, '')),
            };
            return updatedTrackingData;
        });
       
        setTrackingErr((prevTrackingErr) => {
            const updatedTrackingErr = [...prevTrackingErr];
            updatedTrackingErr[index].commitDataErr[ind2].commit_qtyErr = value ? '' : 'enter commit quantity';
            return updatedTrackingErr;
        });
    };


    const getSelectedDetailData = () =>{
        for(let index = 0; index < selectedCommit.length; index++){
        const mySelectedData = commitData.filter(item => selectedCommit[index]?.includes(item.commit_code));
        if(mySelectedData.length > 0){
            setSelectedDetailData(mySelectedData);
        }else{
            setSelectedDetailData(["No Data"]);
        }
        }
        // console.log("selected filter",selectedDetailData)
        // console.log("Commiments Data :",commitData);
        // console.log("selected Commits :",selectedCommit);
    }

    useEffect(()=>{
            getSelectedDetailData();
    },[selectedCommit]);
    

    const importTracking2 = () => {
        let validateValue = true;
        let currentCommit = 0;
        trackingData.map((val) => {
            if (val.trackingNumber === '') {
                validateValue = false;
            }
            return (
                val.commitData.map((vall) => {
                    if (vall.commit_id === '' || vall.commit_qty === '') {
                        validateValue = false;
                    }
                    currentCommit = currentCommit + Number(vall.commit_qty);
                })
            );
        });
    };


    const importTracking = async () => {
        let isValidate = true;
        // setTrackingNumbers([]);
        trackingData.map((val, ind1) => {
            const newTrackingNumber = val.trackingNumber;

            if (val.trackingNumber === '') {
                isValidate = false;
                setTrackingErr((prevTrackingErr) => {
                    const updatedTrackingErr = [...prevTrackingErr];
                    updatedTrackingErr[ind1].trackingNumberErr = 'enter tracking number';
                    return updatedTrackingErr;
                });
            };
            if (val.otp && val.otp.toString().length < 4) {
                isValidate = false;
                setTrackingErr((prevTrackingErr) => {
                    const updatedTrackingErr = [...prevTrackingErr];
                    updatedTrackingErr[ind1].otpErr = 'minimum 4 digits';
                    return updatedTrackingErr;
                });
            };
            if (val.otp && val.otp.toString().length > 6) {
                isValidate = false;
                setTrackingErr((prevTrackingErr) => {
                    const updatedTrackingErr = [...prevTrackingErr];
                    updatedTrackingErr[ind1].otpErr = 'maximum 6 digits';
                    return updatedTrackingErr;
                });
            };
            console.log(trackingNumbers);
            
            return (
                val.commitData.map((vall, ind2) => {
                    if (vall.commit_id === '') {
                        isValidate = false;
                        setTrackingErr((prevTrackingErr) => {
                            const updatedTrackingErr = [...prevTrackingErr];
                            updatedTrackingErr[ind1].commitDataErr[ind2].commit_idErr = 'select commit id';
                            return updatedTrackingErr;
                        });
                    };
                    if (vall.commit_qty === '') {
                        isValidate = false;
                        setTrackingErr((prevTrackingErr) => {
                            const updatedTrackingErr = [...prevTrackingErr];
                            updatedTrackingErr[ind1].commitDataErr[ind2].commit_qtyErr = 'enter commit quantity';
                            return updatedTrackingErr;
                        });
                    };
                    
                })
            );
        });
        trackingErr.map((val1) => {
            if (val1.trackingNumberErr !== "") {
                isValidate = false;
            };
            return (
                val1.commitDataErr.map((val2) => {
                    if (val2.commit_idErr !== "") {
                        isValidate = false;
                    }
                    if (val2.commit_qtyErr !== "") {
                        isValidate = false;
                    }
                })
            );
        });


        if (isValidate) {
            setTrackingErr([{ trackingNumberErr: '', otpErr: '', commitDataErr: [{ commit_idErr: '', commit_qtyErr: '' }] }]);
            axios.post(IMPORT_COMMIT, {
                tracking_data: trackingData
            }).then((response) => {
                // console.log(response);
                    if(response.data.data.status == true)
                    {
                        showToastMessageSuccess(response.data.message);
                    }else{
                        showToastMessageWarning(response.data.message);
                    }
                    
                    getTrackingCommit();
                    setTrackingData([{ trackingNumber: '', otp: '', commitData: [{ commit_id: '', commit_qty: '' }] }]);
                    setTrackingNumbers([]);
                    handleClose();
                    setSelectedCommit([]);
                    setSelectedDetailData([]);
                    getCommitments();
                })
                .catch((error) => {
                    console.error(error);
                    showToastMessageError(error.message);
                });
        };
    };

    useEffect(() => {
        getCommitments();
    }, []);

    useEffect(() => {
        if(refreshCommitments == true){
        getCommitments();
    }
    }, [refreshCommitments]);


    // useEffect(() => {
    //     console.log("selectedCommit", selectedCommit);
    // }, [selectedCommit]);

    // useEffect(() => {
    //     console.log("totalCommitQty", totalCommitQty);
    // }, [totalCommitQty]);

    // useEffect(() => {
    //     console.log("selectedCommitQty", selectedCommitQty);
    // }, [selectedCommitQty]);

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={show}
            onHide={() => {
                handleClose();
                setTrackingNumbers([]);
                setSelectedCommit([]);
                setSelectedDetailData([]);
                setTrackingData([{ trackingNumber: '', otp: '', commitData: [{ commit_id: '', commit_qty: '' }] }]);
                setTrackingErr([{ trackingNumberErr: '', otpErr: '', commitDataErr: [{ commit_idErr: '', commit_qtyErr: '' }] }]);
            }}
            backdrop={true}
        >
            <ToastContainer />
            <div className='modal-header'>
                <h2>Bulk Tracking Import</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => {
                    handleClose();
                    setTrackingNumbers([]);
                    setSelectedCommit([]);
                    setSelectedDetailData([]);
                    setTrackingData([{ trackingNumber: '', otp: '', commitData: [{ commit_id: '', commit_qty: '' }] }]);
                    setTrackingErr([{ trackingNumberErr: '', otpErr: '', commitDataErr: [{ commit_idErr: '', commit_qtyErr: '' }] }]);
                }}>
                    <KTIcon className='fs-1' iconName='cross' />
                </div>
            </div>
            <ToastContainer />

            <div className='p-10 pt-0 pb-5'>
            
            <div className=' my-2'>
            <h4 className=''>Selected Commits</h4>
            {selectedDetailData?.map((item)=>(
                <>
            <div className="card mb-2" style={{boxShadow: '0px 0px 10px 2px rgba(0, 0, 0, 0.1)'}}>
            <div className="card-body py-1 mt-1 mb-1">
                <div className="row">
                    <div className="col-2">
                        <img src={item.deal_image} className="img-fluid" alt="" />
                    </div>
                    <div className="col-10">
                        <h5>{item.deal_name}</h5>
                        <span><span className='fw-bold text-gray-700'>Commitment ID : </span>{item.commitment_no} | <span className='fw-bold text-gray-700'>Commited Qty : </span>{item.commited_qty}</span><br/>
                        <span><span className='fw-bold text-gray-700'>On Tracking -</span> Accepted : {item.on_track_accepted} | Pending : {item.on_track_pending} | Rejected : {item.on_track_rejected}</span><br />
                        {/* <span>On Tracking : {(item.on_track_accepted > 0) ? `Accepted - ${item.on_track_accepted}` : '' } {(item.on_track_pending > 0) ? `Pending - ${item.on_track_pending}` : '' }  , {(item.on_track_rejected > 0) ? `Rejected - ${item.on_track_rejected}` : '' }</span> */}
                        <span><span className='fw-bold text-gray-700'>Available Space : </span>{item.available_space}</span>
                    </div>
                </div>
            </div>
            </div>
                </>
            ))}
            </div>
                <div className='tracking_import'>
                    <button onClick={addTrackingFields} className='btn btn-primary'><i className="fa-solid fa-plus"></i> Add</button>
                    {
                        trackingData.map((val, index) => {
                            return (
                                <div key={index}>
                                    <div className="form-group title_field">
                                        <div className="row">
                                            <div className="col-6">
                                                <label className="form-label required">Tracking Number</label>
                                                <input
                                                    value={trackingData[index].trackingNumber}
                                                    onChange={(e) => handleChangeTrackingNumber(e, index)}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder='Tracking Number'
                                                />
                                                {trackingErr[index] && <p className='text-danger position-absolute' style={{ marginTop: "-3px" }}>{trackingErr[index].trackingNumberErr}</p>}
                                            </div>
                                            <div className="col-2">
                                                <label className="form-label">OTP</label>
                                                <input
                                                    value={trackingData[index].otp}
                                                    onChange={(e) => handleChangeOtp(e, index)}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder='OTP'
                                                />
                                                {trackingErr[index] && <p className='text-danger position-absolute' style={{ marginTop: "-3px" }}>{trackingErr[index].otpErr}</p>}
                                            </div>
                                            {index > 0 &&
                                                <div className="col-4 mt-8">
                                                    <button onClick={() => removeTrackingFields(index)} className='btn btn-danger pe-2 ps-2'><i className="fa-solid fa-xmark"></i>Remove</button>
                                                </div>
                                            }
                                            
                                        </div>

                                        {
                                            val.commitData.map((val2, ind2) => {
                                                return (
                                                    <div className="row mt-5" key={ind2}>
                                                    {/* {selectedCommit? ( */}
                                                        {/* {commitData?.filter(item => !selectedCommit[index]?.includes(item)).map((myvalue) => (
                                                            <div>
                                                                <span>{myvalue[index]?.commitment_no}</span>
                                                            </div>
                                                        ))} */}
                                                        {/* )} */}
                                                        <div className="col-4">
                                                            <label className="form-label required">Commit ID</label>
                                                            <select
                                                                value={trackingData[index].commitData[ind2].commit_id}
                                                                onChange={(e) => handleChangeCommitID(e, index, ind2)}
                                                                className="form-select"
                                                                placeholder='Commit ID'
                                                            >
                                                                <option value="">Select</option>
                                                                {commitData
                                                                    // .filter(item => !selectedCommit[index]?.includes(item.commit_code))
                                                                    .map((vall, innerIndex) => (
                                                                        <option key={innerIndex} value={vall.commit_code} style={{ color: selectedCommit[index]?.includes(vall.commit_code) ? "black" : "black" }}>
                                                                            {vall.commitment_no} {vall.sku && `(${vall.sku})`} - {vall.deal_name}
                                                                        </option>
                                                                        // <option disabled={selectedCommit[index]?.includes(vall.commit_code)} key={innerIndex} value={vall.commit_code} style={{ color: selectedCommit[index]?.includes(vall.commit_code) ? "gray" : "black" }}>
                                                                        //     {vall.commitment_no} {vall.sku && `(${vall.sku})`} - {vall.deal_name}
                                                                        // </option>
                                                                    ))}
                                                            </select>
                                                            {trackingErr[index] && trackingErr[index].commitDataErr[ind2] && <p className='text-danger position-absolute' style={{ marginTop: "-3px" }}>{trackingErr[index].commitDataErr[ind2].commit_idErr}</p>}
                                                        </div>
                                                        {/* <div className="col-6">
                                                            maaz
                                                        </div> */}
                                                        <div className="col-4">
                                                            <label className="form-label required">Commit Qty</label>
                                                            <input
                                                                value={trackingData[index].commitData[ind2].commit_qty}
                                                                onChange={(e) => handleChangeCommitQty(e, index, ind2, trackingData[index].commitData[ind2].commit_id)}
                                                                type="text"
                                                                className="form-control"
                                                                placeholder='Commit Qty'
                                                            />
                                                            {trackingErr[index] && trackingErr[index].commitDataErr[ind2] && <p className='text-danger position-absolute' style={{ marginTop: "-3px" }}>{trackingErr[index].commitDataErr[ind2].commit_qtyErr}</p>}
                                                        </div>
                                                        {ind2 === 0 && <div className="col-2 mt-8">
                                                            <button onClick={() => addCommitFields(index)} className='btn btn-primary px-4'><i className="fa-solid fa-plus"></i>Add</button>
                                                        </div>}
                                                        {ind2 !== 0 && <div className="col-4 mt-8">
                                                            <button onClick={() => removeCommitFields(index, ind2)} className='btn btn-danger pe-2 ps-2'><i className="fa-solid fa-minus"></i>Remove</button>
                                                        </div>}

                                                        
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                    <br />
                                </div>
                            );
                        })
                    }
                </div>

                <div className='d-flex justify-content-end'>



                    <div className='footer_btn2'>
                        <button class="d-none" onClick={() => {
                            handleClose();
                            setTrackingNumbers([]);
                            setSelectedCommit([]);
                            setTrackingData([{ trackingNumber: '', otp: '', commitData: [{ commit_id: '', commit_qty: '' }] }]);
                            setTrackingErr([{ trackingNumberErr: '', otpErr: '', commitDataErr: [{ commit_idErr: '', commit_qtyErr: '' }] }]);
                        }}>Hide Import Wizard</button>

                        <button onClick={importTracking}><i className="fa-solid fa-circle-plus"></i>&nbsp; Submit</button>
                    </div>
                </div>
            </div>

        </Modal>,
        modalsRoot
    );
}

export { ImportTracking };
