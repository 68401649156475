/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { getUserByToken, login } from '../core/_requests';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { useAuth } from '../core/Auth';

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
})

const initialValues = {
    email: 'admin@demo.com',
    password: 'demo',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Thanking() {
    const [loading, setLoading] = useState(false);
    const { saveAuth, setCurrentUser } = useAuth();

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            try {
                const { data: auth } = await login(values.email, values.password)
                saveAuth(auth)
                const { data: user } = await getUserByToken(auth.api_token)
                setCurrentUser(user)
            } catch (error) {
                console.error(error)
                saveAuth(undefined)
                setStatus('The login details are incorrect')
                setSubmitting(false)
                setLoading(false)
            }
        },
    })

    return (
        <div>
            <div
                className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
                style={{backgroundImage: "url(/media/illustrations/sketchy-1/14.png)"}}
            >
                <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                    <a href="#" className="mb-12">
                        <img alt="Logo" src="/media/logos/logo.png" className="h-100px" />
                    </a>
                    <div className="w-lg-900px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                        <form className="form w-100" novalidate="" id="kt_login_signin_form">
                            <div className="text-center mb-11">
                                <h1 className="text-dark fw-bolder mb-3">Thank You for Registration </h1>
                            </div>
                            <div className="mb-10 bg-light-info p-8 rounded">
                                <div className="text-info text-center fs-4">
                                    Please Be Patience, Admin Will <strong>Approve</strong> Your Request.
                                </div>
                            </div>
                            <div className="d-grid mb-10 btn btn-primary">
                                <Link to="/auth" className="indicator-label" style={{ color:"#fff" }}>Login</Link>
                            </div>
                            <div className="text-gray-500 text-center fw-semibold fs-6">
                                Not a Member yet? <Link className="link-primary" to="/auth/registration">Sign up</Link>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="d-flex flex-center flex-column-auto p-10 d-none">
                    <div className="d-flex align-items-center fw-bold fs-6">
                        <a href="#" className="text-muted text-hover-primary px-2">About</a>
                        <a href="#" className="text-muted text-hover-primary px-2">Contact</a>
                        <a href="#" className="text-muted text-hover-primary px-2">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
    );
}
