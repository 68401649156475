/* eslint-disable jsx-a11y/anchor-is-valid */
 
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers';
import Loader from "react-js-loader";
import React, { useEffect, useState ,useRef } from 'react';
import axios from 'axios';
import CurrencyFormat from 'react-currency-format';
import { ToastContainer , toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToastMessageError, showToastMessageSuccess } from '../../../modules/Toast-Message/TosatMessage';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfileShow } from '../../../../redux/reducers/webData';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
    const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    return JSON.parse(data);
};
const AccountHeader = (props) => {

  const copyAddressRef = useRef(null);
  const [copyAddress,setCopyAddress] = useState();
  const [dummyProfile,setDummyProfile] = useState("https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png");
  const [copiedAddress, setCopiedAddress] = useState(false);
  const [copiedEmail, setCopiedEmail] = useState(false);


  const apiToken = getLocalData().api_token;
  

  const API_URL = process.env.REACT_APP_API_URL;
  const GET_STATEMENTS_URL = `${API_URL}/user/Statement`;
  const GET_WITHDRAW_URL = `${API_URL}/user/withdraws`;
  const GET_TOTAL_BALANCE = `${API_URL}/user/balance`;
  const PROFILE_UPDATE = `${API_URL}/profileUpdate`;

  const datas = props.profileData;
  const loader = props.loading;
  const [totalBalance, setTotalBalance] = useState({});
  const userData = localStorage.getItem('kt-auth-react-v');
  const dispatch = useDispatch()
  let user = null;

  const userProfile = useSelector(state => state.stores.userProfile)
  
  if (userData) {
    user = JSON.parse(userData);
  } else {
    console.error("User data not found in localStorage");
  }
  const [imageSrc, setImageSrc] = useState(datas.avatar || dummyProfile);
  // const [badge,setBadge] = useState(datas.badge);

  const handleImageChange = async (event) => {
    try {
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader();
        reader.onloadend = async () => {
          const config = {
            headers: { Authorization: `Bearer ${apiToken}` }
          };
          const data = {
            user_id: datas.id,
            avatar: reader.result,
          };
          await axios.post(PROFILE_UPDATE, data, config)
              .then((response) => {
                setImageSrc(reader.result);
                user.avatar = reader.result;
                dispatch(UserProfileShow(reader.result))
                localStorage.setItem('kt-auth-react-v', JSON.stringify(user));
                showToastMessageSuccess(response.data.message);
              })
              .catch((error) => {
                  showToastMessageError("1 MB image upload");
              });
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      showToastMessageError(error.message);
    }
  };

  // const handleImageClick = () => {
  //   document.getElementById('imageInput').click();
  // };

  const getTotalBalance = async () => {
    const config = {
        headers: { Authorization: `Bearer ${apiToken}` }
    };
    await axios.get(GET_TOTAL_BALANCE, config)
        .then((response) => {
            setTotalBalance(response.data.data);
        })
        .catch((error) => {
            console.error(error);
        });
  };
 
useEffect(() => { 
  getTotalBalance();
}, []);


// const handleCopySuccess = () =>{
//   toast.success("Copied to Clipboard");
// }

const CopyAddressSuccess = () => {
  setCopiedAddress(true);
  setTimeout(() => {
    setCopiedAddress(false);
  }, 2000);
};
const CopyEmailSuccess = () => {
  setCopiedEmail(true);
  setTimeout(() => {
    setCopiedEmail(false);
  }, 2000);
};


const CopyAddressBtn = () =>{

  if (copyAddressRef.current) {
    setCopyAddress(copyAddressRef.current.innerText);  
  }

  return(
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id="tooltip-copied">{copiedAddress ? 'Copied!' : 'Copy to Clipboard'}</Tooltip>}
    >
       <CopyToClipboard text={copyAddress} onCopy={CopyAddressSuccess}>
          <button className='btn btn-light btn-sm d-flex justify-content-center align-items-center' style={{border:'2px solid deepskyblue',width:15,marginLeft:5}} ><i className="far fa-copy fa-2x text-dark"></i></button>
    </CopyToClipboard>
    </OverlayTrigger>
   
    
  )
}

const getBadgeImage = (badge)=>{
  if(badge == 1){
    var imagename = 'bronze.png';
  }
  if(badge == 2){
    var imagename = 'silver.png';
  }
  if(badge == 3){
    var imagename = 'gold.png';
  }
  if(badge == 4){
    var imagename = 'diamond.png';
  }
  var src = toAbsoluteUrl('/media/badges/'+imagename);
  return src;
}


  return (
    <div className='card mb-5 mb-xl-10'>
      <ToastContainer />
      <div className='card-body pt-9 pb-0'>

      <div className="d-flex justify-content-between">
      <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <div>
                <label htmlFor='imageInput' style={{cursor: 'pointer'}}>
                <i class="ki-duotone ki-pencil" style={{ position: 'relative', left: '117px', top: '26px', fontSize:'18px' }}><span class="path1"></span><span class="path2"></span></i>
                  {datas.avatar ? (
                    <div>
                      <img src={userProfile || datas.avatar} alt='Preview' className="rounded-circle" style={{width: '150px', height: '150px'}} />
                    </div>
                  ) : (
                    <div>
                      <img src={imageSrc} alt='Preview' className="rounded-circle" style={{width: '150px', height: '150px'}} />
                    </div>
                  )}
                </label>

                <input
                  type='file'
                  id='imageInput'
                  accept='image/*'
                  onChange={handleImageChange}
                  style={{display: 'none'}}
                />
              </div>
              {/* <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='Metronic'  className="d-none"/> */}
              <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success d-none rounded-circle border border-4 border-white h-20px w-20px'></div>
            </div>
          </div>
          {loader ? (
            <div className='item'>
              <Loader type='spinner-cub' bgColor={'#EA2467'} size={50} />
            </div>
          ) : (
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {datas && datas?.first_name} {datas && datas?.last_name}
                    </a>
                    <a href='#'>
                      <KTIcon iconName='verify' className='fs-1 text-primary' />
                    </a>
                  </div>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <div className='d-flex align-items-center me-5'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                    >
                      <KTIcon iconName='geolocation' className='fs-4 me-1' />
                      {datas?.billingDetails && (
                       
                          <span ref={copyAddressRef}>
                            {datas?.billingDetails?.address1}
                            {datas?.billingDetails?.address1 && ','} {datas?.billingDetails?.city}{' '}
                            {datas?.billingDetails?.city && '-'} {datas?.billingDetails?.country}
                          </span>
                        
                      )}
                    </a>
                    <CopyAddressBtn />
                    </div>
                    <div className='d-flex align-items-center'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                    >
                      <KTIcon iconName='sms' className='fs-4 me-1' />
                      {datas && datas?.email}
                    </a>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-copied">{copiedEmail ? 'Copied!' : 'Copy to Clipboard'}</Tooltip>}
                    >
                      <CopyToClipboard text={datas && datas?.email} onCopy={CopyEmailSuccess}>
                          <button className='btn btn-light btn-sm d-flex justify-content-center align-items-center' style={{border:'2px solid deepskyblue',width:15,marginLeft:5}} ><i className="far fa-copy fa-2x text-dark"></i></button>
                    </CopyToClipboard>
                    </OverlayTrigger>
                    
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                        <div className='fs-2 fw-bolder'>
                          {totalBalance?.total_credit ? (
                            <CurrencyFormat
                              decimalScale={2}
                              value={parseFloat(totalBalance?.total_credit).toFixed(2)}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'$'}
                            />
                          ) : (
                            '$0'
                          )}
                        </div>
                      </div>
                      <div className='fw-bold fs-6 text-gray-400'>Earnings</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          <div className="card-body shadow rounded-2 p-1">
            {datas.badge && <img src={getBadgeImage(datas.badge)} width={100} height={100} alt="" />}
          </div>
        </div>
      </div>
      
        
      </div>
    </div>
  )
}

export { AccountHeader };
