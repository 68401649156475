import React from 'react';
import Views from './orderDispute/Views';
import CreateNew from './orderDispute/CreateNew';
import '../Style.css';

const OrderPriceDispute = () => {
  return (
    <div>
      <h2>Order Price Dispute</h2>
      <div className="tab-pane fade show active" role="tabpanel">
        <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
          <li className="nav-item">
            <a className="nav-link active" data-bs-toggle="tab" href="#kt_tab_pane_1">View</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_2">Create New</a>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="kt_tab_pane_1" role="tabpanel">
            <Views />
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
            <CreateNew />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderPriceDispute;
