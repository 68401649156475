/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import axios from 'axios'
import CurrencyFormat from 'react-currency-format'
import {ReqWithdrows} from './ReqWithdrows'
import {CSVLink} from 'react-csv'
import Loader from 'react-js-loader'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {Link} from 'react-router-dom'
import {WithdrawDetail} from './WithdrawDetail'
import {StatementReceipt} from './StatementReceipt'
import {Page, Text, View, Document, StyleSheet, PDFDownloadLink} from '@react-pdf/renderer'
import {Dropdown} from 'react-bootstrap'
import TableRow from './TableRow'
import { StatementDetail } from './StatementDetail'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'

const getLocalData = () => {
  const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  return JSON.parse(data)
}

const Statements = () => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false)
  const [statementData, setStatementData] = useState([])
  const [loading, setLoading] = useState(false)
  const [WithdrawDatas, setWithdrawDatas] = useState([])
  const [totalBalance, setTotalBalance] = useState({})
  const [totalPendding, setTotalPendding] = useState({})

  const [statementDataMonth, setStatementDataMonth] = useState([])
  const [statementDataYear, setStatementDataYear] = useState([])
  const [serialStatementData, setSerialStatementData] = useState([])

  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange

  const [statementExportOption,setStatementExportOption] = useState("ALL_STMT");

  const apiToken = getLocalData().api_token

  const [showStatementDetailModal, setShowStatementDetailModal] = useState(false)
  const [statementItems, setStatementItems] = useState()
  
  const [showWithdrawDetailModal, setShowWithdrawDetailModal] = useState(false)
  const [withdrawId, setWithdrawId] = useState()

  const [showStatementReceiptModal, setShowStatementReceiptModal] = useState(false)
  const [statementId, setStatementId] = useState()
  const [statementUserNo, setStatementUserNo] = useState()
  const [statementCode, setStatementCode] = useState()

  // const handleWithdrawDetailShow = () => setShowWithdrawDetailModal(true);
  // const handleWithdrawDetailClose = () => setShowWithdrawDetailModal(false);

  const API_URL = process.env.REACT_APP_API_URL
  const GET_STATEMENTS_URL = `${API_URL}/user/Statement`
  const GET_WITHDRAW_URL = `${API_URL}/user/withdraws`
  const GET_TOTAL_BALANCE = `${API_URL}/user/balance`
  const GET_SERIAL_STATEMENTS = `${API_URL}/user/serial-statements`

  const getWithdraws = async () => {
    const config = {
      headers: {Authorization: `Bearer ${apiToken}`},
    }
    await axios
      .get(GET_WITHDRAW_URL, config)
      .then((response) => {
        setWithdrawDatas(response.data.data)
        getTotalBalance()
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const getStatementsMonth = async () => {
    const currentDate = new Date()
    // const currentDay = currentDate.getDay();
    // const currentMonth = currentDate.getMonth() + 1;
    // const currentYear = currentDate.getFullYear();
    const pastDate = new Date()
    pastDate.setDate(currentDate.getDate() - 30)

    const last30DaysData = statementData.filter((item) => {
      const itemDate = new Date(item.created_at)
      return itemDate >= pastDate && itemDate <= currentDate
    })
    setStatementDataMonth(last30DaysData)
  }

  const getStatementsYear = async () => {
    const currentDate = new Date()
    const currentMonth = currentDate.getMonth() + 1
    const currentYear = currentDate.getFullYear()

    const thisYearData = statementData.filter((item) => {
      const itemDate = new Date(item.created_at)
      return itemDate.getFullYear() === currentYear
    })
    setStatementDataYear(thisYearData)
  }

  const getStatements = async () => {
    setLoading(true)
    const config = {
      headers: {Authorization: `Bearer ${apiToken}`},
    }
    await axios
      .get(GET_STATEMENTS_URL, config)
      .then((response) => {
        setStatementData(response.data.data)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  const getSerialStatements = async () => {
    const config = {
      headers: {Authorization: `Bearer ${apiToken}`},
    }
    await axios
      .get(GET_SERIAL_STATEMENTS, config)
      .then((response) => {
        setSerialStatementData(response.data.data);
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const getTotalBalance = async () => {
    const config = {
      headers: {Authorization: `Bearer ${apiToken}`},
    }
    await axios
      .get(GET_TOTAL_BALANCE, config)
      .then((response) => {
        setTotalBalance(response.data.data)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const netEarning = () => {
    let netEarn = 0
    statementData.map((val) => {
      if (val.credit) {
        netEarn = netEarn + val.credit
      }
      if (val.debit) {
        netEarn = netEarn - val.debit
      }
    })
    return netEarn
  }

  useEffect(() => {
    getStatements()
    getWithdraws()
    getTotalBalance()
    getSerialStatements()
  }, [])

  useEffect(() => {
    getStatementsYear()
    getStatementsMonth()
  }, [statementData])


  const Pdfstyles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      padding: 20,
    },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableCol: {
      width: '25%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableCell: {
      margin: 'auto',
      marginTop: 5,
      marginBottom: 5,
      fontSize: 10,
    },
    header: {
      fontSize: 18,
      marginBottom: 10,
      textAlign: 'center',
    },
  })

  const statementsCsvHeaders = [
    {label: 'Statement No', key: 'statement_no'},
    {label: 'User', key: 'user'},
    {label: 'Tracking Number', key: 'tracking'},
    {label: 'Model', key: 'model'},
    {label: 'UPC', key: 'upc'},
    {label: 'Description', key: 'description'},
    {label: 'Amount', key: 'amount'},
    {label: 'Payment', key: 'payment_status'},
    {label: 'Date', key: 'date'},
  ]

  const statementsCsvData = statementData.map((item) => ({
    statement_no: item.statement_no,
    user: item.user_name,
    tracking: item.tracking_no,
    model: item.model,
    upc: item.upc,
    description: item.description,
    amount: '$' + item.price,
    payment_status: item.payment_status == 1 ? 'PAID' : 'PENDING',
    date: new Date(item.created_at).toLocaleDateString(), // Assuming created_at is a date string, convert it to a readable format
  }))

  const StatementPdfDocument = ({data}) => (
    <Document>
      <Page size='A4' orientation='landscape' style={Pdfstyles.page}>
        {/* <Text style={Pdfstyles.header}>Statement Data</Text> */}
        <View style={Pdfstyles.table}>
          {/* Table Header */}
          <View style={Pdfstyles.tableRow}>
            {statementsCsvHeaders.map((header, index) => (
              <View style={[Pdfstyles.tableCol, {backgroundColor: 'skyblue'}]} key={index}>
                <Text style={Pdfstyles.tableCell}>{header.label}</Text>
              </View>
            ))}
          </View>
          {/* Table Content */}
          {data.map((item, index) => (
            <View style={Pdfstyles.tableRow} key={index}>
              {statementsCsvHeaders.map((header, headerIndex) => (
                <View style={Pdfstyles.tableCol} key={headerIndex}>
                  <Text style={Pdfstyles.tableCell}>{item[header.key]}</Text>
                </View>
              ))}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  )

  const serialStatementsCsvHeaders = [
    {label: 'Statement No', key: 'statement_no'},
    {label: 'User', key: 'user'},
    {label: 'Tracking Number', key: 'tracking'},
    {label: 'Serial Number', key: 'serial'},
    {label: 'Model', key: 'model'},
    {label: 'UPC', key: 'upc'},
    {label: 'Description', key: 'description'},
    {label: 'Amount', key: 'amount'},
    {label: 'Payment', key: 'payment_status'},
    {label: 'Date', key: 'date'},
  ]

  const serialStatementsCsvData = serialStatementData.map((item) => ({
    statement_no: item.statement_no,
    user: item.first_name+' '+item.last_name,
    tracking: item.tracking_no,
    serial: item.serial_no,
    model: item.model,
    upc: item.upc,
    description: item.name,
    amount: '$' + item.price,
    payment_status: item.payment_status == 1 ? 'PAID' : 'PENDING',
    date: new Date(item.created_at).toLocaleDateString(), // Assuming created_at is a date string, convert it to a readable format
  }))

  const SerialStatementPdfDocument = ({data}) => (
    <Document>
      <Page size='A4' orientation='landscape' style={Pdfstyles.page}>
        {/* <Text style={Pdfstyles.header}>Statement Data</Text> */}
        <View style={Pdfstyles.table}>
          {/* Table Header */}
          <View style={Pdfstyles.tableRow}>
            {serialStatementsCsvHeaders.map((header, index) => (
              <View style={[Pdfstyles.tableCol, {backgroundColor: 'skyblue'}]} key={index}>
                <Text style={Pdfstyles.tableCell}>{header.label}</Text>
              </View>
            ))}
          </View>
          {/* Table Content */}
          {data.map((item, index) => (
            <View style={Pdfstyles.tableRow} key={index}>
              {serialStatementsCsvHeaders.map((header, headerIndex) => (
                <View style={Pdfstyles.tableCol} key={headerIndex}>
                  <Text style={Pdfstyles.tableCell}>{item[header.key]}</Text>
                </View>
              ))}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  )

  const statusCondition = (val) => {
    var statusText = ''
    if (val == 0) {
      statusText = 'Approved'
    } else if (val == 1) {
      statusText = 'Pending'
    } else if (val == 2) {
      statusText = 'Rejected'
    }
    return statusText
  }

  const withdrawCsvHeaders = [
    {label: 'Payment Date', key: 'date'},
    {label: 'User', key: 'user'},
    {label: 'Amount', key: 'amount'},
    {label: 'Payment', key: 'payment_status'},

  ]

  const withdrawCsvData = WithdrawDatas.map((item) => ({
    date: new Date(item.date).toLocaleDateString(),
    user: item.user_name,
    amount: '$' + item.payment,
    payment_status: statusCondition(item.status),
  }))
  
  const WithdrawPdfDocument = ({data}) => (
    <Document>
      <Page size='A4' orientation='portrait' style={Pdfstyles.page}>
        {/* <Text style={Pdfstyles.header}>Statement Data</Text> */}
        <View style={Pdfstyles.table}>
          {/* Table Header */}
          <View style={Pdfstyles.tableRow}>
            {withdrawCsvHeaders.map((header, index) => (
              <View style={[Pdfstyles.tableCol, {backgroundColor: 'skyblue'}]} key={index}>
                <Text style={Pdfstyles.tableCell}>{header.label}</Text>
              </View>
            ))}
          </View>
          {/* Table Content */}
          {data.map((item, index) => (
            <View style={Pdfstyles.tableRow} key={index}>
              {withdrawCsvHeaders.map((header, headerIndex) => (
                <View style={Pdfstyles.tableCol} key={headerIndex}>
                  <Text style={Pdfstyles.tableCell}>{item[header.key]}</Text>
                </View>
              ))}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  )

  const handleExportStatementOption = (e)=>{
    const value = e.target.value;
    setStatementExportOption(value);
  }

  const currentDate = new Date()
  const formattedDate = currentDate.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })

  function checkFileExists(val) {
    // const path = `http://localhost:8000/storage/invoices/statement_invoice_${val.statement_no}.pdf`;
    const path = `https://erp.makemoneyormiles.com/storage/invoices/statement_invoice_${val.statement_no}.pdf`
    if (val.invoice == 1) {
      return (
        <button
          type='button'
          onClick={() => OpenStatementReceiptModal(val.statement_no)}
          class='btn btn-light btn-sm'
        >
          INVOICE
        </button>
      )
    } else {
      if(val.invoice == 2){
        return <span>----</span>
      }
      else{
        return <span>PENDING</span>
      }
    }
  }

  const getstmtDescription = (val) => {
    let text = ''
    if (val.data_source == 6) {
      let text = 'Opening Balance'
    }
    if (val.data_source == 1) {
      let text = val.name
    }
    return text
  }

  const getstmtCommissionAndTotal = (val) => {
    const totalPrice = val.price * val.qty

    var commission = val.commit_commission
    var totalCommission = commission * val.qty

    const total = totalPrice + totalCommission
    return (
      <>
        <td className='text-danger'>
          <CurrencyFormat
            decimalScale={2}
            value={parseFloat(commission).toFixed(2)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        </td>
        <td className='text-danger'>
          <CurrencyFormat
            decimalScale={2}
            value={parseFloat(total).toFixed(2)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        </td>
      </>
    )
  }

  const OpenViewDetailModal = (withdraw_code) => {
    setWithdrawId(withdraw_code)
    setShowWithdrawDetailModal(true)
  }

  const OpenViewStatementModal = (items) => {
    setStatementItems(items)
    setShowStatementDetailModal(true)
  }

  const OpenStatementReceiptModal = (stmtId,stmtcode,userNo) => {
    setStatementId(stmtId)
    setStatementUserNo(userNo)
    setStatementCode(stmtcode)
    setShowStatementReceiptModal(true)
  }

  return (
    <div className='m-5'>
      <ReqWithdrows
        show={showCreateAppModal}
        handleClose={() => {
          setShowCreateAppModal(false)
        }}
        getWithdrows={getWithdraws}
        totalBalance={totalBalance}
      />
      <StatementDetail
        show={showStatementDetailModal}
        handleClose={() => setShowStatementDetailModal(false)}
        items={statementItems}
      />
      <WithdrawDetail
        show={showWithdrawDetailModal}
        handleClose={() => setShowWithdrawDetailModal(false)}
        withdrawId={withdrawId}
      />
      <StatementReceipt
        show={showStatementReceiptModal}
        handleClose={() => setShowStatementReceiptModal(false)}
        statementId={statementId}
        userno={statementUserNo}
        statementCode={statementCode}
      />
      <div className='card shadow card-xxl-stretch mb-5'>
        <div className='card-header'>
          <div className='card-title'>
            <h3>Earnings</h3>
          </div>
        </div>
        <div className='card-body pb-0'>
          <span className='d-none fs-5 fw-semibold text-gray-600 pb-5 d-block'>
            Last 30 day earnings calculated. Apart from arranging the order of topics.
          </span>
          <div className='d-flex flex-wrap justify-content-between pb-6'>
            <div className='d-flex flex-wrap'>
              <div className='border border-dashed border-gray-300 w-200px rounded my-3 p-4 me-6'>
                <span className='fs-2x fw-bold text-gray-800 lh-1'>
                  <span
                    data-kt-countup='true'
                    data-kt-countup-value='6,840'
                    data-kt-countup-prefix='$'
                  >
                    {totalBalance.total_credit ? (
                      <CurrencyFormat
                        decimalScale={2}
                        value={parseFloat(totalBalance?.total_credit).toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    ) : (
                      '$0'
                    )}
                  </span>
                </span>
                <span className='fs-6 fw-semibold text-info d-block lh-1 pt-2'>Net Earnings</span>
              </div>
              <div className='border border-dashed border-gray-300 w-200px rounded my-3 p-4 me-6'>
                <span className='fs-2x fw-bold text-gray-800 lh-1'>
                  {totalBalance.total_credit ? (
                    <CurrencyFormat
                      decimalScale={2}
                      value={parseFloat(totalBalance?.total_debit).toFixed(2)}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  ) : (
                    '$0'
                  )}
                </span>
                <span className='fs-6 fw-semibold text-danger d-block lh-1 pt-2'>
                  Total Withdrawn
                </span>
              </div>
              <div className='border border-dashed border-gray-300 w-200px rounded my-3 p-4 me-6'>
                <span className='fs-2x fw-bold text-gray-800 lh-1'>
                  <span
                    data-kt-countup='true'
                    data-kt-countup-value='1,240'
                    data-kt-countup-prefix='$'
                  >
                    {totalBalance.total_credit ? (
                      <CurrencyFormat
                        decimalScale={2}
                        value={parseFloat(totalBalance?.total_available_balance).toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    ) : (
                      '$0'
                    )}
                  </span>
                </span>
                <span className='fs-6 fw-semibold text-success d-block lh-1 pt-2'>Balance</span>
              </div>
              <div className='border border-dashed border-gray-300 w-200px rounded my-3 p-4 me-6'>
                <span className='fs-2x fw-bold text-gray-800 lh-1'>
                  <span
                    data-kt-countup='true'
                    data-kt-countup-value='6,840'
                    data-kt-countup-prefix='$'
                  >
                    {totalBalance.total_pendding ? (
                      <CurrencyFormat
                        decimalScale={2}
                        value={parseFloat(totalBalance?.total_pendding).toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    ) : (
                      '$0'
                    )}
                  </span>
                </span>
                <span className='fs-6 fw-semibold text-warning d-block lh-1 pt-2'>
                  Total Pending
                </span>
              </div>
              <div className='border border-dashed border-gray-300 w-200px rounded my-3 p-4 me-6'>
                <span className='fs-2x fw-bold text-gray-800 lh-1'>
                  <span
                    data-kt-countup='true'
                    data-kt-countup-value='1,240'
                    data-kt-countup-prefix='$'
                  >
                    {totalBalance.total_credit ? (
                      <CurrencyFormat
                        decimalScale={2}
                        value={parseFloat(totalBalance?.due_balance).toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    ) : (
                      '$0'
                    )}
                  </span>
                </span>
                <span className='fs-6 fw-semibold text-info d-block lh-1 pt-2'>Due Balance</span>
              </div>
            </div>
            <div className='d-flex'>
              {/* <button
                className='btn btn-primary px-6 flex-shrink-0 align-self-center'
                onClick={() => {
                  setShowCreateAppModal(true)
                }}
              >
                Withdraw Money
              </button> */}
            </div>
          </div>
        </div>
      </div>
      <div className='card p-8 pb-0 shadow' role='tabpanel'>
        <div className='tab-content' id='myTabContent'>
          <div className='tab-pane fade show active' role='tabpanel'>
            <ul className='nav nav-tabs profile-secondary-tabs mb-5 fs-6'>
              <li className='nav-item'>
                <a className='nav-link active statement-tab' data-bs-toggle='tab' href='#kt_tab_Withdraw_1'>
                  Statement
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link withdraw-tab' data-bs-toggle='tab' href='#kt_tab_Withdraw_2'>
                  Withdraw
                </a>
              </li>
            </ul>
          </div>
          <div className='tab-content' id='myTabContent'>
            <div className='tab-pane fade show active' id='kt_tab_Withdraw_1' role='tabpanel'>
              <div className='card-title d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center'>
                  <h3 className='m-0 text-gray-800'>Statement</h3>
                </div>

                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center justify-content-end mb-3'>
                    {/* <button className='btn btn-sm btn-primary text-white ml-3'> */}

                    {/* </button> */}
                    <Dropdown>
                      <Dropdown.Toggle variant='success' id='dropdown-basic' className='btn btn-primary btn-sm'>
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu style={{width:'225px'}}>
                        <Dropdown.Item onClick={(e)=>{
                          e.preventDefault();
                          e.stopPropagation();
                        }}>
                          <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                              setDateRange(update)
                            }}
                            isClearable={true}
                            className='form-control form-control-sm'
                            placeholderText='Select Date Range'
                          />
                        </Dropdown.Item>
                        <Dropdown.Item as="div" onClick={(e)=>{
                          e.preventDefault();
                          e.stopPropagation();
                        }}>
                          <div className="form-group">
                            <select value={statementExportOption} onChange={handleExportStatementOption} className="form-select form-control-sm">
                              <option value="ALL_STMT">Statements</option>
                              <option value="SERIAL_STMT">Serial Statements</option>
                            </select>
                          </div>
                        </Dropdown.Item>
                        
                        <Dropdown.Item as="div" >
                        {statementExportOption == "ALL_STMT" && (
                          <div className="d-flex justify-content-between align-items-center">
                          <button className='btn btn-sm btn-primary text-dark'>
                            <CSVLink
                              className='downloadbtn'
                              filename={`statement_${formattedDate}.csv`}
                              headers={statementsCsvHeaders}
                              data={statementsCsvData}
                              style={{color: '#fff'}}
                            >
                              Export CSV
                            </CSVLink>
                          </button>
                          <PDFDownloadLink
                            document={<StatementPdfDocument data={statementsCsvData} />}
                            fileName={`statement_${formattedDate}.pdf`}
                            className='btn btn-sm btn-danger ms-2'
                          >
                            {({blob, url, loading, error}) =>
                              loading ? 'Export PDF' : 'Export PDF'
                            }
                          </PDFDownloadLink>
                          </div>
                        )}
                        {statementExportOption == "SERIAL_STMT" && (
                          <div className="d-flex justify-content-between align-items-center">
                          <button className='btn btn-sm btn-primary text-dark'>
                            <CSVLink
                              className='downloadbtn'
                              filename={`serial_statement_${formattedDate}.csv`}
                              headers={serialStatementsCsvHeaders}
                              data={serialStatementsCsvData}
                              style={{color: '#fff'}}
                            >
                              Export CSV
                            </CSVLink>
                          </button>
                          <PDFDownloadLink
                            document={<SerialStatementPdfDocument data={serialStatementsCsvData} />}
                            fileName={`serial_statement_${formattedDate}.pdf`}
                            className='btn btn-sm btn-danger ms-2'
                          >
                            {({blob, url, loading, error}) =>
                              loading ? 'Export PDF' : 'Export PDF'
                            }
                          </PDFDownloadLink>
                          </div>
                        )}
                        </Dropdown.Item>

                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
                    <li className='nav-item'>
                      <a className='nav-link active' data-bs-toggle='tab' href='#st-month-tab'>
                        Month
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href='#st-year-tab'>
                        Year
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href='#st-all-tab'>
                        All Time
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class='tab-content' id='myTabContent'>
                <div
                  class='tab-pane fade show active'
                  id='st-month-tab'
                  role='tabpanel'
                  aria-labelledby='home-tab'
                >
                  <div className='table-responsive'>
                    <table className='table align-middle table-row-bordered table-row-solid gy-4 gs-9'>
                      <thead className='border-gray-200 fs-5 fw-semibold bg-lighten'>
                        <tr className='fw-bold text-muted'>
                        <th class='ps-9'>Stmt No</th>
                          <th class='ps-9'>Tracking</th>
                          <th class='ps-9'>Description</th>
                          <th class='ps-9'>Items</th>
                          <th class=''>Amount</th>
                          <th class=''>Due Date</th>
                          <th class='min-w-125px '>Invoice</th>
                        </tr>
                      </thead>
                      {loading ? (
                        <div className='item'>
                          <Loader type='spinner-cub' bgColor={'#EA2467'} size={50} />
                        </div>
                      ) : (
                        <tbody className='fs-6 fw-semibold text-gray-600'>
                          {statementDataMonth.length === 0 && (
                            <tr>
                              <td className='text-center' colSpan={5}>
                                You Do Not have any Statements.
                              </td>
                            </tr>
                          )}
                          {statementDataMonth.length !== 0 &&
                            statementDataMonth.map((val, index) => {
                              return (
                                <TableRow key={index} val={val} OpenStatementReceiptModal={OpenStatementReceiptModal} OpenViewStatementModal={OpenViewStatementModal} />
                              )
                            })}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
                <div
                  class='tab-pane fade'
                  id='st-year-tab'
                  role='tabpanel'
                  aria-labelledby='profile-tab'
                >
                  <div className='table-responsive'>
                    <table className='table align-middle table-row-bordered table-row-solid gy-4 gs-9'>
                      <thead className='border-gray-200 fs-5 fw-semibold bg-lighten'>
                        <tr className='fw-bold text-muted'>
                        <th class='ps-9'>Stmt No</th>
                          <th class='ps-9'>Tracking</th>
                          <th class='ps-9'>Description</th>
                          <th class='ps-9'>Items</th>
                          <th class=''>Amount</th>
                          <th class=''>Due Date</th>
                          <th class='min-w-125px '>Invoice</th>
                        </tr>
                      </thead>
                      {loading ? (
                        <div className='item'>
                          <Loader type='spinner-cub' bgColor={'#EA2467'} size={50} />
                        </div>
                      ) : (
                        <tbody className='fs-6 fw-semibold text-gray-600'>
                          {statementDataYear.length === 0 && (
                            <tr>
                              <td className='text-center' colSpan={5}>
                                You Do Not have any Statements.
                              </td>
                            </tr>
                          )}
                          {statementDataYear.length !== 0 &&
                            statementDataYear.map((val, index) => {
                              return (
                                <TableRow key={index} val={val} OpenStatementReceiptModal={OpenStatementReceiptModal} OpenViewStatementModal={OpenViewStatementModal}/>
                              )
                            })}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
                <div
                  class='tab-pane fade'
                  id='st-all-tab'
                  role='tabpanel'
                  aria-labelledby='contact-tab'
                >
                  <div className='table-responsive'>
                    <table className='table align-middle table-row-bordered table-row-solid gy-4 gs-9'>
                      <thead className='border-gray-200 fs-5 fw-semibold bg-lighten'>
                        <tr className='fw-bold text-muted'>
                        <th class='ps-9'>Stmt No</th>
                          <th class='ps-9'>Tracking</th>
                          <th class='ps-9'>Description</th>
                          <th class='ps-9'>Items</th>
                          <th class=''>Amount</th>
                          <th class=''>Due Date</th>
                          <th class='min-w-125px '>Invoice</th>
                        </tr>
                      </thead>
                      {loading ? (
                        <div className='item'>
                          <Loader type='spinner-cub' bgColor={'#EA2467'} size={50} />
                        </div>
                      ) : (
                        <tbody className='fs-6 fw-semibold text-gray-600'>
                          {statementData.length === 0 && (
                            <tr>
                              <td className='text-center' colSpan={5}>
                                You Do Not have any Statements.
                              </td>
                            </tr>
                          )}
                          {statementData.length !== 0 &&
                            statementData.map((val, index) => {
                              return (
                                <TableRow key={index} val={val} OpenStatementReceiptModal={OpenStatementReceiptModal} OpenViewStatementModal={OpenViewStatementModal} />
                              )
                            })}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className='tab-pane fade' id='kt_tab_Withdraw_2' role='tabpanel'>
              <div className='card-title d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center'>
                  <h3 className='m-0 text-gray-800'>Withdraws</h3>
                </div>

                <div className='d-flex align-items-center'>
                  
                    <Dropdown>
                      <Dropdown.Toggle variant='success' id='dropdown-basic' className='btn btn-primary btn-sm'>
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu style={{width:'225px'}}>
                        <Dropdown.Item onClick={(e)=>{
                          e.preventDefault();
                          e.stopPropagation();
                        }}>
                          <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                              setDateRange(update)
                            }}
                            isClearable={true}
                            className='form-control form-control-sm'
                            placeholderText='Select Date Range'
                          />
                        </Dropdown.Item>
                        <Dropdown.Item as="div" >
                          <div className="d-flex justify-content-between align-items-center">
                          <button className='btn btn-sm btn-primary text-dark'>
                            <CSVLink
                              className='downloadbtn'
                              filename={`withdraws_${formattedDate}.csv`}
                              headers={withdrawCsvHeaders}
                              data={withdrawCsvData}
                              style={{color: '#fff'}}
                            >
                              Export CSV
                            </CSVLink>
                          </button>
                          <PDFDownloadLink
                            document={<WithdrawPdfDocument data={withdrawCsvData} />}
                            fileName={`withdraws_${formattedDate}.pdf`}
                            className='btn btn-sm btn-danger ms-2'
                          >
                            {({blob, url, loading, error}) =>
                              loading ? 'Export PDF' : 'Export PDF'
                            }
                          </PDFDownloadLink>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                </div>
              </div>

              <div className='table-responsive'>
                <table className='table align-middle table-row-bordered table-row-solid gy-4 gs-9'>
                  <thead className='border-gray-200 fs-5 fw-semibold bg-lighten'>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-150px ps-9'>Date</th>
                      <th className='min-w-125px'>Amount</th>
                      <th className='min-w-125px'>Status</th>
                      <th className='min-w-125px'>Action</th>
                    </tr>
                  </thead>
                  <tbody className='fs-6 fw-semibold text-gray-600'>
                    {WithdrawDatas.length === 0 && (
                      <tr>
                        <td className='text-center' colSpan={3}>
                          You Do Not have any Withdrawal.
                        </td>
                      </tr>
                    )}
                    {WithdrawDatas.length !== 0 &&
                      WithdrawDatas.map((val, index) => {
                        return (
                          <tr key={index}>
                            <td className='ps-9'>{val.date}</td>
                            <td className='ps-0'>
                              <CurrencyFormat
                                decimalScale={2}
                                value={parseFloat(val.payment).toFixed(2)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                              />
                            </td>
                            <td>
                              {val.status == 0 && (
                                <span className='badge badge-light-success fs-8 fw-bold success_active'>
                                  Approved
                                </span>
                              )}
                              {val.status == 1 && (
                                <span className='badge badge-light-warning fs-8 fw-bold warning_active'>
                                  Pending
                                </span>
                              )}
                              {val.status == 2 && (
                                <span className='badge badge-light-danger fs-8 fw-bold danger_active'>
                                  Rejected
                                </span>
                              )}
                            </td>
                            <td>
                              <a href='#' onClick={() => OpenViewDetailModal(val.withdraw_code)}>
                                View Detail
                              </a>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Statements}
