import React from 'react';

const Transaction = () => {
    return (
        <div className='card shadow'>
            <div className='card-body py-3'>
                <div className='table-responsive'>
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                        <thead>
                            <tr className='fw-bold text-muted'>
                                <th className='min-w-120px'>ID</th>
                                <th className='min-w-120px'>Void</th>
                                <th className='min-w-120px'>Status</th>
                                <th className='min-w-120px'>Type</th>
                                <th className='min-w-120px'>Reason</th>
                                <th className='min-w-120px'>Amount</th>
                                <th className='min-w-120px'>User</th>
                                <th className='min-w-120px'>Check ID</th>
                                <th className='min-w-120px'>Pay To</th>
                                <th className='min-w-120px'>Memo</th>
                                <th className='min-w-120px'>ACH Number</th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Transaction;
