/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'react-bootstrap';
import { KTIcon } from '../../../_metronic/helpers';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToastMessageSuccess, showToastMessageError } from '../../modules/Toast-Message/TosatMessage';

const modalsRoot = document.getElementById('root-modals') || document.body

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
    const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    return JSON.parse(data);
}

const AddReturns = ({ show, handleClose, getReturnsData }) => {

    const [formValue, setFormValue] = useState({
        trackingNo: '',
        commitNo: '',
        reason: '',
        image: null,
    });
    const [errorMessage, setErrorMessage] = useState({
        trackingNoErr: '',
        commitNoErr: '',
        reasonErr: '',
        imageErr: '',
    });
    const [trackingData, setTrackingData] = useState([]);
    const [commitData, setCommitData] = useState([]);

    const API_URL = process.env.REACT_APP_API_URL;
    const GET_COMMIT_URL = `${API_URL}/received-commitments`;
    const GET_TRACKING_NO_URL = `${API_URL}/user/TrackingNumbers`;
    const POST_DATA_URL = `${API_URL}/refund-request-create`;

    const apiToken = getLocalData().api_token;

    // const getTrackingNo = async () => {
    //     const config = {
    //         headers: { Authorization: `Bearer ${apiToken}` }
    //     };
    //     await axios.get(GET_TRACKING_NO_URL, config)
    //         .then((response) => {
    //             setTrackingData(response.data.data);
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // };

    // const getCommitNo = async () => {
    //     const config = {
    //         headers: { Authorization: `Bearer ${apiToken}` }
    //     };
    //     await axios.get(GET_COMMIT_URL, config)
    //         .then((response) => {
    //             setCommitData(response.data.data);
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // };

    const submitData = async () => {
        const config = {
            headers: { Authorization: `Bearer ${apiToken}` }
        };
        const formData = new FormData();
        formData.append('tracking_no', formValue.trackingNo);
        formData.append('commit_no', formValue.commitNo);
        formData.append('message', formValue.reason);
        formData.append('image', formValue.image);
        if (!formValue.trackingNo) {
            setErrorMessage((prev) => ({
                ...prev,
                trackingNoErr: 'please select tracking no'
            }));
        } else if (!formValue.commitNo) {
            setErrorMessage((prev) => ({
                ...prev,
                trackingNoErr: '',
                commitNoErr: 'please select commitment no'
            }));
        } else if (!formValue.reason) {
            setErrorMessage((prev) => ({
                ...prev,
                trackingNoErr: '',
                commitNoErr: '',
                reasonErr: 'please write valid reason'
            }));
        } else if (!formValue.image) {
            setErrorMessage((prev) => ({
                ...prev,
                trackingNoErr: '',
                commitNoErr: '',
                reasonErr: '',
                imageErr: 'please import image'
            }));
        } else {
            try {
                const response = await axios.post(POST_DATA_URL, formData, config);
                console.log(response);
                getReturnsData();
                handleClose();
                showToastMessageSuccess(response.data.message);
                setFormValue({
                    trackingNo: '',
                    commitNo: '',
                    reason: '',
                    image: null,
                });
                setErrorMessage((prev) => ({
                    ...prev,
                    trackingNoErr: '',
                    commitNoErr: '',
                    reasonErr: '',
                    imageErr: ''
                }));
            } catch (error) {
                console.error(error);
                showToastMessageError(error.message);
            }
        };
    };

    useEffect(() => {
        // getTrackingNo();
        // getCommitNo();
    }, []);

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={show}
            onHide={() => {
                handleClose();
                setFormValue((prev) => ({
                    ...prev,
                    image: '',
                }));
            }}
            backdrop={true}
        >
            <ToastContainer />
            <div className='modal-header pb-0'>
                <h2>Add Returns</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => {
                    handleClose();
                    setFormValue((prev) => ({
                        ...prev,
                        image: '',
                    }));
                }}>
                    <KTIcon className='fs-1' iconName='cross' />
                </div>
            </div>

            <div className='card'>
                <div className='card-body'>
                    <div className="row">
                        <div className="col-6 form-group">
                            <label className='form-label required'>Tracking No</label>
                            <select
                                className='form-select'
                                value={formValue.trackingNo}
                                onChange={(e) => setFormValue((prev) => ({
                                    ...prev,
                                    trackingNo: e.target.value
                                }))}
                                style={{ marginTop: "-9px" }}
                            >
                                <option value="">Select</option>
                                {
                                    trackingData.map((val, index) => {
                                        return (
                                            <option key={index} value={val.tracking_code}>{val.tracking_id}</option>
                                        )
                                    })
                                }
                            </select>
                            <p className='text-danger position-absolute' style={{ marginTop: "-4px" }}>{errorMessage.trackingNoErr}</p>
                        </div>
                        <div className="col-6">
                            <label className='form-label required'>Commitment No</label>
                            <select
                                className='form-select'
                                value={formValue.commitNo}
                                onChange={(e) => setFormValue((prev) => ({
                                    ...prev,
                                    commitNo: e.target.value
                                }))}
                                style={{ marginTop: "-9px" }}
                            >
                                <option value="">Select</option>
                                {
                                    commitData.map((val, index) => {
                                        return (
                                            <option key={index} value={val.commitment_no}>{val.commitment_no}</option>
                                        )
                                    })
                                }
                            </select>
                            <p className='text-danger position-absolute' style={{ marginTop: "-4px" }}>{errorMessage.commitNoErr}</p>
                        </div>
                        <div className="col-12 mt-5">
                            <label className='form-label required'>Reason</label>
                            <textarea
                                className='form-control support_import'
                                value={formValue.reason}
                                onChange={(e) => setFormValue((prev) => ({
                                    ...prev,
                                    reason: e.target.value
                                }))}
                                placeholder='Write reason...'
                                rows={5}
                                style={{ marginTop: "-9px" }}
                            />
                            <p className='text-danger position-absolute' style={{ marginTop: "-4px" }}>{errorMessage.reasonErr}</p>
                        </div>
                        <div className="d-flex justify-content-between mt-3 p-3">
                            <div>
                                <label className='form-label required'>Image</label>
                                <input
                                    className='form-control'
                                    onChange={(e) => setFormValue((prev) => ({
                                        ...prev,
                                        image: e.target.files[0],
                                    }))}
                                    type='file'
                                    style={{ marginTop: "-9px" }}
                                />
                                <p className='text-danger position-absolute' style={{ marginTop: "-4px" }}>{errorMessage.imageErr}</p>
                                <p className='mt-2 text-danger position-absolute' style={{ marginTop: "-4px" }}>Allow Only JPG,PNG File.</p>
                            </div>
                            <div className='d-flex justify-content-end mt-9'>
                                <div className='footer_btn4 px-3'>
                                    <button onClick={() => {
                                        handleClose();
                                        setFormValue((prev) => ({
                                            ...prev,
                                            image: '',
                                        }));
                                    }}><i className="text-dark fa-sharp fa-solid fa-circle-xmark"></i> Cancle</button>
                                </div>

                                <div className='footer_btn2'>
                                    <button onClick={submitData}><i className="fa-solid fa-circle-plus"></i>&nbsp; Add</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>,
        modalsRoot
    );
}

export { AddReturns };
