import React from 'react';

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
  const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  return data !== null ? JSON.parse(data) : null;
};

export function MenuInner() {

  const localData = getLocalData();

  const getTodayDate = () => {

    // const getGreeting = () => {
    //   const date = new Date();
    //   const options = { timeZone: 'America/Los_Angeles' };
    //   const formattedDate = date.toLocaleString('en-US', options);
    //   const hours = new Date(formattedDate).getHours();
    //   let greeting = "";

    //   if (hours >= 0 && hours < 12) {
    //     greeting = "GOOD MORNING TODAY –";
    //   } else if (hours >= 12 && hours < 17) {
    //     greeting = "GOOD AFTERNOON TODAY –";
    //   } else {
    //     greeting = "GOOD EVENING TODAY –";
    //   }

    //   return greeting;
    // };

    const monthArray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const date = new Date();
    const todayDate = date.getDate();
    var daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var dayOfWeek = daysOfWeek[date.getDay()];
    return (
      <span>
        {/* {getGreeting()} */}
        <span style={{ color: "#EA2467", marginLeft: "5px" }}>
          {dayOfWeek},&nbsp;
          {monthArray[date.getMonth()]}&nbsp;
          {todayDate.toString().length > 1 ? todayDate : "0" + todayDate}
          <sup>{todayDate === 1 && "st"}{todayDate === 2 && "nd"}{todayDate === 3 && "rd"}{todayDate > 3 && "th"}</sup>&nbsp;
          {date.getFullYear()}
        </span>
      </span>
    );
  };

  return (
    <>
      <h2 className='text-gray-700' style={{ marginTop: "20px" }}>Welcome, {localData?.first_name} {localData?.last_name}<br /><span className='fs-6 text-gray-700' style={{fontWeight:'400'}}>{localData?.user_no}</span></h2>&nbsp;&nbsp;&nbsp;
      <h3 className='pb-5 text-gray-700' style={{ marginTop: "21px" }}><span>{getTodayDate()}</span></h3>
      {/* 
      <MenuItem title={intl.formatMessage({id: 'DASHBOARD'})} to='/dashboard' />
      <MenuItem title='Layout Builder' to='/builder' /> */}
      {/* <MenuInnerWithSub
        title='Crafted'
        to='/crafted'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuInnerWithSub
          title='Pages'
          to='/crafted/pages'
          fontIcon='bi-archive'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuInnerWithSub
            title='Profile'
            to='/crafted/pages/profile'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
            <MenuItem
              to='/crafted/pages/profile/connections'
              title='Connections'
              hasBullet={true}
            />
          </MenuInnerWithSub>
          <MenuInnerWithSub
            title='Wizards'
            to='/crafted/pages/wizards'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/wizards/horizontal' title='Horizontal' hasBullet={true} />
            <MenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
          </MenuInnerWithSub>
        </MenuInnerWithSub>

        <MenuInnerWithSub
          title='Accounts'
          to='/crafted/accounts'
          fontIcon='bi-person'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
          <MenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
        </MenuInnerWithSub>

        <MenuInnerWithSub
          title='Errors'
          to='/error'
          fontIcon='bi-sticky'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/error/404' title='Error 404' hasBullet={true} />
          <MenuItem to='/error/500' title='Error 500' hasBullet={true} />
        </MenuInnerWithSub>

        <MenuInnerWithSub
          title='Widgets'
          to='/crafted/widgets'
          fontIcon='bi-layers'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
          <MenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
          <MenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
          <MenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
          <MenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
          <MenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
        </MenuInnerWithSub>
      </MenuInnerWithSub> */}

      {/* <MenuInnerWithSub title='Apps' to='/apps' menuPlacement='bottom-start' menuTrigger='click'>
        <MenuInnerWithSub
          title='Chat'
          to='/apps/chat'
          icon='message-text-2'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
          <MenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
          <MenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
        </MenuInnerWithSub>
        <MenuItem icon='abstract-28' to='/apps/user-management/users' title='User management' />
      </MenuInnerWithSub> */}

      {/* <MenuInnerWithSub
        isMega={true}
        title='Mega menu'
        to='/mega-menu'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MegaMenu />
      </MenuInnerWithSub> */}
    </>
  );
};
