import React from 'react';
import '../Style.css';
import { useNavigate } from 'react-router-dom';

const RequestPayments = () => {

  const navigate = useNavigate();

  return (
    <div className='card shadow p-5'>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className='payment_box'>
            <div className="head">
              <div className="d-flex">
                <div>
                  <i class="fa-sharp fa-solid fa-building-columns bank_icon"></i>
                </div>
                <div className='ach_account'>
                  <p>ACH Accounts</p>
                </div>
              </div>
            </div>
            <div className="">
              <p>You are not able to request ach payments because you don't have any registered or approved accounts. Go to ACH Payments page and setup your account!</p>
            </div>
            <div className="payment_btn">
              <button onClick={() => navigate("/payments/achpayments")}>Setup Account</button>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6"></div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className="left_side">
            <div className="heading">
              <p>Overview</p>
            </div>
            <div className="heading2">
              <p className='balance'>Your Balance</p>
              <p className='amount'>$0.00</p>
            </div>
            <div className="content">
              <div className="orders">
                <p>Orders</p>
                <p>$0.00</p>
              </div>
              <div className="orders">
                <p>Tracking Rewards</p>
                <p>$0.00</p>
              </div>
              <div className="orders">
                <p>Lifetime Balance</p>
                <p>$0.00</p>
              </div>
              <div className="orders">
                <p>Payments</p>
                <p>$0.00</p>
              </div>
              <div className="orders">
                <p>Total</p>
                <p>$0.00</p>
              </div>
            </div>
            <div className="payment_foot">
              <p>Request one or more checks</p>
              <p>No check can acced your balance.</p>
              <p>The minimum per check is $25.00</p>
              <p>The minimum per ach is $200.00</p>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="right_side">
            <div className="heading">
              <p>Payment Type</p>
            </div>
            <div className="pay_types">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="payment_types" id="ACH_payment" />
                <label class="form-check-label" for="ACH_payment">
                  ACH
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="payment_types" id="CHECK_payment" />
                <label class="form-check-label" for="CHECK_payment">
                  E-CHECK
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="payment_types" id="TRANSFER_payment" />
                <label class="form-check-label" for="TRANSFER_payment">
                  WIRE TRANSFER
                </label>
              </div>
            </div>
            <div className='payment_para'>
              <p>An electronic check will be created and emailed to ambervirani@outlook.com.</p>
              <p>Your profile address, email and cell phone must be complete before you order a payment.</p>
              <p>Your profile email is the only email we will be sending the check to.</p>
            </div>
            <div className="heading">
              <p>Payment Details</p>
            </div>
            <div>
              <div class="col-sm-12 col-md-8">
                <p className='amount2'>Amount *</p>
                <div class="input-group mb-2">
                  <input type="text" class="form-control" id="inlineFormInputGroup" placeholder="0.00" />
                  <div class="input-group-prepend">
                    <div class="input-group-text">$</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-8">
                <p className='amount2'>Pay To *</p>
                <div class="input-group mb-2">
                  <input type="text" class="form-control" id="inlineFormInputGroup" placeholder="" />
                </div>
              </div>
              <div class="col-sm-12 col-md-8">
                <p className='amount2'>Memo</p>
                <div class="input-group mb-2">
                  <input type="text" class="form-control" id="inlineFormInputGroup" placeholder="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className='footer_btn'>
        <button>Request Payment</button>
      </div>
    </div>
  );
}

export default RequestPayments;
