import {useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {ImportTracking} from './ImportTracking'
import axios from 'axios'
import Loader from 'react-js-loader'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  showToastMessageSuccess,
  showToastMessageError,
  showToastMessageWarning,
} from '../../modules/Toast-Message/TosatMessage'
import {useLocation} from 'react-router-dom'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import {CSVLink} from 'react-csv'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'

const getLocalData = () => {
  const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  return JSON.parse(data)
}

const TrackingManagement = () => {
  const location = useLocation()
  const direct_open_state = location.state

  const [showCreateAppModal, setShowCreateAppModal] = useState(false)
  const [trackingData, setTrackingData] = useState([])
  const [loading, setLoading] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [checkBox, setCheckBox] = useState([])
  const [editId, setEditId] = useState(null)
  const [updatedOtp, setUpdatedOtp] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [showDeleteBtn, setShowDeleteBtn] = useState(false)

  const [refreshComm, setRefreshComm] = useState(false)

  const [toggleFilter, setToggleFilter] = useState('NONE')
  const [isOpen, setIsOpen] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [searchCommitID, setSearchCommitID] = useState('')
  const [searchDealID, setSearchDealID] = useState('')
  const [searchItemName, setSearchItemName] = useState('')
  const [searchTrackingNumber, setSearchTrackingNumber] = useState('')
  const [searchQuery, setSearchQuery] = useState('')

  const handleSingleSearch = (value) => {
    setSearchQuery(value)
    setSearchCommitID(value)
    setSearchDealID(value)
    // setSearchItemName(value)
    setSearchTrackingNumber(value)
  }

  const cancleSearch = () => {
    setSearchCommitID('')
    setSearchDealID('')
    // setSearchItemName('')
    setStartDate('')
    setEndDate('')
    setSearchTrackingNumber('')
    setSearchQuery('')
  }

  const startDateObject = new Date(startDate)
  const endDateObject = new Date(endDate)

  const handleFilter = () => {
    if (startDate !== '' && endDate !== '') {
      const filteredItems = trackingData.filter(
        (item) =>
          item.commitment_no.toLowerCase().includes(searchCommitID.toLowerCase()) &&
          item.deal_no.toLowerCase().includes(searchDealID.toLowerCase()) &&
          // item.deal_name.toLowerCase().includes(searchItemName.toLowerCase()) &&
          item.tracking_id.toLowerCase().includes(searchTrackingNumber.toLowerCase()) &&
          new Date(item.created_date) >= startDateObject &&
          new Date(item.created_date) <= endDateObject
      )
      return filteredItems
    } else {
      const filteredItems = trackingData.filter(
        (item) =>
          item.commitment_no?.toLowerCase().includes(searchCommitID.toLowerCase()) ||
          item.deal_no?.toLowerCase().includes(searchDealID.toLowerCase()) ||
          // item.deal_name?.toLowerCase().includes(searchItemName.toLowerCase()) ||
          item.tracking_id?.toLowerCase().includes(searchTrackingNumber.toLowerCase())
      )
      return filteredItems
    }
  }

  useEffect(() => {
    if (direct_open_state == 'OPEN_IMPORT_MODAL') {
      setShowCreateAppModal(true)
    }
  }, [direct_open_state])

  const apiToken = getLocalData().api_token
  const API_URL = process.env.REACT_APP_API_URL
  const GET_TRACK_COMMIT = `${API_URL}/trackCommit`
  const DELETE_TRACK_COMMIT = `${API_URL}/user/trackingOrder/delete`
  const UPDATE_OTP_URL = `${API_URL}/user/update-OTP`

  const getTrackingCommit = async () => {
    setLoading(true)
    const config = {
      headers: {Authorization: `Bearer ${apiToken}`},
    }
    await axios
      .get(GET_TRACK_COMMIT, config)
      .then((response) => {
        setTrackingData(response.data.data)
        // setCheckBox(response.data.data);
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  const handleCheckChange = (e, index, val) => {
    const {checked} = e.target
    setCheckBox((prevCheckBox) => {
      const updatedCheckBox = [...prevCheckBox]
      updatedCheckBox[index] = {
        ...updatedCheckBox[index],
        check: checked,
        value: val,
      }
      return updatedCheckBox
    })
  }

  const deleteTrack = async () => {
    const getCheck = checkBox.filter((vall) => {
      return vall.check === true
    })
    const deletedIds = getCheck.map((val) => {
      return val.value.tracking_item_code
    })
    if (deletedIds.length === 0) {
      showToastMessageWarning('Please select a Track to delete')
    } else {
      setDeleteLoading(true)
      await axios
        .post(DELETE_TRACK_COMMIT, {
          id: deletedIds,
        })
        .then((response) => {
          showToastMessageSuccess(response.data.message)
          getTrackingCommit()
          setRefreshComm(true)
          setDeleteLoading(false)
        })
        .catch((error) => {
          console.error(error)
          showToastMessageError(error.message)
          setDeleteLoading(false)
        })
    }
  }

  const updateOTP = async () => {
    const data = {
      tracking_item_code: editId,
      otp: updatedOtp,
    }
    if (editId) {
      if (!updatedOtp) {
        setErrorMsg('enter otp*')
      } else if (updatedOtp.toString().length < 4) {
        setErrorMsg('minimum 4 digits*')
      } else if (updatedOtp.toString().length > 6) {
        setErrorMsg('maximum 6 digits*')
      } else {
        setBtnLoading(true)
        setErrorMsg('')
        await axios
          .post(UPDATE_OTP_URL, {
            otp: data,
          })
          .then((response) => {
            showToastMessageSuccess(response.data.message)
            getTrackingCommit()
            setBtnLoading(false)
          })
          .catch((error) => {
            console.error(error)
            showToastMessageError(error.message)
            setBtnLoading(false)
          })
        setEditId(null)
      }
    }
  }

  useEffect(() => {
    const initialCheckBoxState = trackingData.map((val) => ({check: false, value: val}))
    setCheckBox(initialCheckBoxState)
  }, [trackingData])

  useEffect(() => {
    getTrackingCommit()
  }, [])

  useEffect(() => {
    const hasChecked = checkBox.some((check) => check.check === true)
    setShowDeleteBtn(hasChecked)
  }, [checkBox])

  // useEffect(() => {
  //   if(refresh == true){
  //   getTrackingCommit();
  //   setRefresh(false);
  //   }
  // }, [refresh]);

  // let rowIndex = 0;

  const toggleMenu = () => {
    setIsOpen(!isOpen)
    if (isOpen) {
      setToggleFilter('NONE')
    }
  }

  const statusCondition = (val) => {
    var statusText = "";
    if (val == 0) {
      statusText = "Accepted";
    } else if (val == 1) {
      statusText = "Pending";
    } else if (val == 2) {
      statusText = "Rejected";
    } else if (val == 3) {
      statusText = "Package Lost";
    } else if (val == 11) {
      statusText = "Damaged";
    } else if (val == 12) {
      statusText = "Open Box";
    } else if (val == 13) {
      statusText = "Empty Box";
    } else if (val == 14) {
      statusText = "Empty Item";
    } else if (val == 15) {
      statusText = "Missing Item";
    }
    return statusText;
  };

  const csvData = [
    ["Tracking ID", "Deal ID", "Item Description", "Commitment ID", "Commited Qty", "Total Price", "Total Commission", "Grand Total","Status", "Scanned Date", "Tracking Date"],
    ...handleFilter().map(({ tracking_id, deal_no, deal_name, commitment_no, commited_qty, total_price, total_commission, grand_total, status,  updated_date , created_date  }) => [
      tracking_id, 
      deal_no,
      deal_name,
      commitment_no,
      commited_qty,
      total_price,
      total_commission,
      grand_total,
      statusCondition(status),
      updated_date,
      created_date
    ]),
  ];



  const srnoBody = (item) => {
    return <span>{trackingData.indexOf(item) + 1}</span>
  }

  const checkboxBody = (item) => {
    var index = trackingData.indexOf(item)
    if (item.status == 1 || item.status == 2) {
      return (
        <>
          <div className='form-check form-check-sm form-check-custom form-check-solid text-center'>
            <input
              className='form-check-input widget-9-check'
              type='checkbox'
              // value={checkBox[index] ? checkBox[index].check : false}
              checked={checkBox[index] ? checkBox[index].check : false}
              onChange={(e) => {
                handleCheckChange(e, index, item)
              }}
            />
          </div>
        </>
      )
    }
    if (item.status == 0) {
      return (
        <>
          <div className=''>
            <i className='fa-solid fa-check-to-slot' style={{fontSize: '1.5rem'}}></i>
          </div>
        </>
      )
    }
  }

  const carrierBody = (item) => {
    return (
      <span className='text-dark fw-bold text-hover-primary fs-6'>
        <img src={item.store_logo} alt='' style={{width: '80px', height: '50px'}} />
      </span>
    )
  }

  const imageBody = (item) => {
    return (
      <>
        <img src={item.deal_image} alt='' style={{width: '60px'}} />
      </>
    )
  }

  const otpBody = (item) => {
    return (
      <>
        {editId !== item.tracking_item_code && (
          <span className='text-dark fw-bold d-block mb-1 fs-6'>
            {item.otp ? item.otp : '---'}&nbsp;&nbsp;&nbsp;
            {item.status == 1 && (
              <span className='btn btn-icon btn-bg-light text-hover-primary btn-active-color-primary btn-sm me-1'>
                <button
                  onClick={() => {
                    setEditId(item.tracking_item_code)
                    setUpdatedOtp(item.otp)
                  }}
                  className='btn'
                >
                  <KTIcon iconName='pencil' className='fs-3' />
                </button>
              </span>
            )}
          </span>
        )}

        {editId == item.tracking_item_code && (
          <div className='w-200px'>
            <input
              type='text'
              className='form-control'
              value={updatedOtp}
              onChange={(e) => setUpdatedOtp(e.target.value.replace(/[^0-9]/g, ''))}
              placeholder='Enter OTP'
            />

            {errorMsg && (
              <p className='position-absolute text-danger fw-semibold' style={{marginTop: '-4px'}}>
                {errorMsg}
              </p>
            )}

            {btnLoading ? (
              <button className='btn btn-success btn-sm mt-5'>
                Wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </button>
            ) : (
              <button
                onClick={updateOTP}
                className='btn btn-success btn-sm ml-10 mt-5'
                style={{marginRight: '15px'}}
              >
                Update
              </button>
            )}

            {!btnLoading && (
              <button
                className='btn btn-danger btn-sm mt-5'
                onClick={() => {
                  setEditId(null)
                  setUpdatedOtp(null)
                  setErrorMsg('')
                }}
              >
                Cancel
              </button>
            )}
          </div>
        )}
      </>
    )
  }

  const statusBody = (item) => {
    return (
      <>
        {item.status == 0 && <span className='badge badge-success fs-6 fw-bold'>Received</span>}
        {item.status == 1 && <span className='badge badge-warning fs-6 fw-bold'>Pending</span>}
        {item.status == 2 && <span className='badge badge-danger fs-6 fw-bold'>Rejected</span>}
        {item.status == 3 && <span className='badge badge-danger fs-6 fw-bold'>Package Lost</span>}
        {item.status == 11 && <span className='badge badge-danger fs-6 fw-bold'>Damaged</span>}
        {item.status == 12 && <span className='badge badge-danger fs-6 fw-bold'>Open Box</span>}
        {item.status == 13 && <span className='badge badge-danger fs-6 fw-bold'>Empty Box</span>}
        {item.status == 14 && <span className='badge badge-danger fs-6 fw-bold'>Empty Item</span>}
        {item.status == 15 && <span className='badge badge-danger fs-6 fw-bold'>Item Missing</span>}
      </>
    )
  }


  const tableHeader = () => {
    return (
      <div className=''>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='searchZone'>
            {toggleFilter == 'SEARCH' && (
              <div className='pb-2 pt-3'>
                <div className='d-flex'>
                  <div className='mx-2 search-filter-wrap'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Search here...'
                      value={searchQuery}
                      onChange={(e) => handleSingleSearch(e.target.value)}
                    />
                  </div>
                  <div className='mx-2'>
                    <button
                      onClick={() => cancleSearch()}
                      className='btn btn-info'
                      disabled={
                        searchCommitID === '' &&
                        searchDealID === '' &&
                        searchItemName === '' &&
                        searchTrackingNumber === '' &&
                        startDate === '' &&
                        endDate === ''
                      }
                    >
                      <i className='fa-solid fa-xmark fs-3'></i>
                    </button>
                  </div>
                </div>
              </div>
            )}

            {toggleFilter == 'DATE' && (
              <div className='pb-2 pt-3'>
                <div className='d-flex'>
                  <div className='mx-2  calendar-filter-wrap'>
                    <label
                      className='form-label position-absolute text-gray-600'
                      style={{marginTop: '-18px'}}
                    >
                      Start Date
                    </label>
                    <input
                      type='date'
                      className='form-control'
                      placeholder='DD-MM-YYYY'
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div className='mx-2 calendar-filter-wrap'>
                    <label
                      className='form-label position-absolute text-gray-600'
                      style={{marginTop: '-18px'}}
                    >
                      End Date
                    </label>
                    <input
                      type='date'
                      className='form-control'
                      placeholder='DD-MM-YYYY'
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                  <div className='mx-2'>
                    <button
                      onClick={() => cancleSearch()}
                      className='btn btn-info'
                      disabled={
                        searchCommitID === '' &&
                        searchDealID === '' &&
                        searchItemName === '' &&
                        startDate === '' &&
                        endDate === ''
                      }
                    >
                      <i className='fa-solid fa-xmark fs-3'></i>
                    </button>
                  </div>
                </div>
              </div>
            )}
            {toggleFilter == 'EXPORT' && (
              <div className='pb-2 pt-3'>
                <div className='d-flex align-items-center'>
                  <h5>Export CSV for Trackings</h5>
                  <div className='mx-2'>
                    <button className='btn btn-success text-dark'>
                      <CSVLink
                        className='downloadbtn'
                        filename='my-trackings.csv'
                        data={csvData}
                        style={{color: '#fff'}}
                      >
                        Export
                      </CSVLink>
                    </button>
                  </div>
                </div>
              </div>
            )}
            {toggleFilter == 'NONE' && <></>}
          </div>
          <div className='footer_btn3'>
            {deleteLoading ? (
              <button className='btn btn-danger' style={{marginRight: '15px'}}>
                Deleting...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2 me-3'></span>
              </button>
            ) : (
              <>
                {showDeleteBtn && (
                  <button onClick={deleteTrack} className='btn btn-danger btn-lg me-3'>
                    <i className='fa-solid fa-trash'></i>&nbsp; Delete Tracking
                  </button>
                )}
              </>
            )}
            <button className='btn btn-lg' onClick={() => setShowCreateAppModal(true)}>
              <i className='fa-solid fa-location-dot'></i>&nbsp; Tracking Import
            </button>
          </div>
        </div>
      </div>
    )
  }

  const filterButton = () => {
    return (
      <div className='floating-button-container'>
        {isOpen && (
          <div className={`menu-buttons ${isOpen ? 'show' : ''}`}>
            <button className='sub-button' onClick={() => setToggleFilter('EXPORT')}>
              <i className='fas fa-download'></i>
            </button>
            <button className='sub-button' onClick={() => setToggleFilter('DATE')}>
              <i className='fas fa-calendar-alt'></i>
            </button>
            <button className='sub-button' onClick={() => setToggleFilter('SEARCH')}>
              <i className='fas fa-search'></i>
            </button>
          </div>
        )}
        <button className='main-button' onClick={toggleMenu}>
          {isOpen ? <i className='fas fa-close'></i> : <i className='fas fa-sliders-h'></i>}
        </button>
      </div>
    )
  }

  const columnStyle = {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    width: '200px',
  }

  return (
    <div>
      <ImportTracking
        show={showCreateAppModal}
        handleClose={() => {
          setShowCreateAppModal(false)
        }}
        getTrackingCommit={getTrackingCommit}
        refreshCommitments={refreshComm == true ? true : false}
      />
      <ToastContainer />
      <h1>Tracking Management</h1>
      <div className='card shadow p-0'>
        <div className='card-body p-0'>
          <div className='prime-table'>
            <DataTable
              value={handleFilter()}
              removableSort
              size='large'
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25, 50]}
              header={tableHeader}
              tableStyle={{
                minWidth: '60rem',
                alignItems: 'center',
                marginLeft: 'auto',
                fontSize: 15,
              }}
              scrollable
              scrollHeight='90vh'
              style={{minWidth: '50rem'}}
            >
              <Column body={checkboxBody} header='Action' style={columnStyle}></Column>
              <Column body={srnoBody} header='#' style={columnStyle}></Column>
              <Column body={carrierBody} header='Carrier' style={columnStyle}></Column>
              <Column field='deal_no' header='Deal ID' style={columnStyle}></Column>
              <Column body={imageBody} header='Image' style={columnStyle}></Column>
              <Column
                field='tracking_id'
                header='Tracking No.'
                sortable
                style={columnStyle}
              ></Column>
              <Column body={otpBody} header='OTP' style={columnStyle}></Column>
              <Column field='qty' header='Qty' style={columnStyle}></Column>
              <Column field='commitment_no' header='Commit ID' style={columnStyle}></Column>
              <Column field='commited_qty' header='Commit Qty' style={columnStyle}></Column>
              <Column body={statusBody} header='Status' style={columnStyle}></Column>
              <Column
                field='created_date'
                header='Created Date'
                sortable
                style={columnStyle}
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
      <div style={{zIndex: 999}}>{filterButton()}</div>
    </div>
  )
}

export default TrackingManagement
