import React from 'react';
import OrderPayments from './Paymentdetail/OrderPayments';
import Transaction from './Paymentdetail/Transaction';

const PaymentDetails = () => {
  return (
    <div>
      <h1>Payment Details</h1>
      <div className="tab-pane fade show active" role="tabpanel">
        <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
          <li className="nav-item">
            <a className="nav-link active" data-bs-toggle="tab" href="#kt_tab_pane_1">Transaction</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_2">Order Payments</a>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="kt_tab_pane_1" role="tabpanel">
            <Transaction />
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
            <OrderPayments />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentDetails;
