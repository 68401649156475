import {useState} from 'react'
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { KTIcon, toAbsoluteUrl } from '../../../helpers';
import { HeaderUserMenu, ThemeModeSwitcher } from '../../../partials';
import { useLayout } from '../../core';
import { MenuItem } from './header-menus/MenuItem';
import { useSelector } from 'react-redux';

const itemClass = 'ms-1 ms-lg-3'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'fs-1'

const Navbar = () => {
  const { config } = useLayout()
  const intl = useIntl()
  const userData = localStorage.getItem('kt-auth-react-v');
  let user = null;

  const [dummyProfile,setDummyProfile] = useState("https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png");
  
  if (userData) {
    user = JSON.parse(userData);
  } else {
    console.error("User data not found in localStorage");
  }

  const userProfile = useSelector((state:any) => state.stores.userProfile)

  // const getTodayDate = () => {

  //   const monthArray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  //   const date = new Date();
  //   const todayDate = date.getDate();
  //   return (
  //     <span>
  //       <span style={{ color: "#EA2467", marginLeft: "5px" }}>
  //         {monthArray[date.getMonth()]}&nbsp;
  //         {todayDate.toString().length > 1 ? todayDate : "0" + todayDate}
  //         <sup>{todayDate === 1 && "st"}{todayDate === 2 && "nd"}{todayDate === 3 && "rd"}{todayDate > 3 && "th"}</sup>&nbsp;
  //         {date.getFullYear()}
  //       </span>
  //     </span>
  //   );
  // };
  
  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <h3 className='text-gray-700' style={{ marginTop: "21px" }}>{getTodayDate()}</h3> */}
      <MenuItem title={intl.formatMessage({ id: 'DASHBOARD' })} to='/dashboard' />

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={userProfile || user?.avatar || dummyProfile} alt='' />
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export { Navbar }
