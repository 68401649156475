import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import axios from 'axios';
import Loader from "react-js-loader";

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
  const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  return data !== null ? JSON.parse(data) : null;
};

const Referrals = () => {

  const [refferaleData, setRefferaleData] = useState([]);
  const [apiMessage, setApiMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const localData = getLocalData();

  const API_URL = process.env.REACT_APP_API_URL;
  const REFFERAL_URL = `${API_URL}/my-refrals`;

  const getRefferalDetails = async () => {
    setLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${localData.api_token}` }
    };
    await axios.get(REFFERAL_URL, config)
      .then((response) => {
        setRefferaleData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getRefferalDetails();
  }, []);

  return (
    <div>
      <h1>Referrals</h1>
      <div className='card shadow p-5'>
        <div className='card-body py-0'>
          <div className='table-responsive'>
          {console.log(refferaleData)}
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-30px'>Refere Id</th>
                  <th className='min-w-170px'>Referee Name</th>
                  <th className='min-w-170px'>Referee Email</th>
                  <th className='min-w-170px'>Registered Date</th>
                </tr>
              </thead>
              {loading ? (
                <div className="item">
                  <Loader type="spinner-cub" bgColor={"#EA2467"} size={50} />
                </div>
              ) : (
                <tbody className="fs-6 fw-semibold text-gray-800">
                  {refferaleData.length === 0 &&
                    <tr>
                      <td className='text-center' colSpan={5}>You Do Not have any Referrals.</td>
                    </tr>
                  }
                  {refferaleData.length !== 0 &&
                    refferaleData.map((val, index) => {
                      return (
                        <tr key={index}>
                          <td className="ps-9">{val.user_no}</td>
                          <td className="ps-0">{val.fullName}</td>
                          <td>{val.email}</td>
                          <td>{val.created_at}</td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Referrals;
